import React from 'react'
import styled from 'styled-components/macro'
import ButtonMainLink from '../ButtonMainLink'
import theme from '../../theme'
import { RESULTATS_ID } from '../../config-pages'
import PageContainerFooter from '../PageContainerFooter'

const Container = styled.div`
    display: none;
    background-color: ${theme.colors.Purple150};
    text-align: center;
    padding: 2em 0;
    ${ButtonMainLink} {
        padding-right: 4em;
        padding-left: 4em;
    }

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: block;
        border-radius: 0;
        ${ButtonMainLink} {
            display: inline-block;
            width: 90%;
            max-width: 30em;    
        }
    }
`

const Footer = () => {
    return (
        <PageContainerFooter>
            <Container>
                <ButtonMainLink pageId={RESULTATS_ID} />
            </Container>
        </PageContainerFooter>
    )
}

export default Footer