import React, { useState } from 'react'
import styled from 'styled-components/macro'
import * as d3Shape from 'd3-shape'
import theme from '../../../theme'
import { EvolutionSvg } from '../shared'

type Orientation = 'left' | 'right'
type Arcs = Array<string | null>
type Pie = Array<d3Shape.PieArcDatum<Value>>

export interface Value {
    value: number
    evolution: number | null
}

const VIEWPORT_WIDTH = 500
export const HEIGHT_RATIO = 2
const SVG_PADDING_TOP = 0
const SVG_PADDING_BOTTOM = 20
const PAD_ANGLE = Math.PI / 400

const OUTER_RADIUS1 = VIEWPORT_WIDTH
const INNER_RADIUS1 = VIEWPORT_WIDTH * 2.18 / 3
const OUTER_RADIUS2 = INNER_RADIUS1 - 25
const INNER_RADIUS2 = OUTER_RADIUS2 - 50

const LABEL_OFFSET = 180
const THRESHOLD_LABEL_HIDE = 10

const EVOLUTION_Y_OFFSET = 50

const RECT_PERCENTAGE_SIZE = 130

interface ContainerProps {
    orientation: Orientation
}

export const LabelGroup = styled.g<{isVisible: boolean}>`
    pointer-events: none;
    ${props => !props.isVisible ? `
        display: none;
    `: ``}
`

export const CoverPath = styled.path`
    fill: white;
`

const Container = styled.div<ContainerProps>`
    text-align: ${props => props.orientation};
`

interface GraphTitleProps {
    orientation: Orientation
}

export const GraphTitle = styled.h2<GraphTitleProps>`
    color: ${theme.colors.Grey150};
    font-family: ${theme.fonts.subtitle};
    text-align: ${props => props.orientation};
    margin-bottom: ${theme.spacings.vertical05};
`

const PercentText = styled.text`
    font-family: ${theme.fonts.title};
    font-size: 350%;
    text-anchor: middle;
`

export const ColorScheme = {
    '1': [
        [theme.graphColors.Petrol, theme.colors.White],
        [theme.graphColors.Turquoise, theme.colors.White],
        [theme.graphColors.Yellow, theme.colors.Black],
        [theme.graphColors.Orange, theme.colors.Black],
        [theme.graphColors.Grey100, theme.colors.Black],
    ],
    '2': [
        [theme.graphColors.DarkBlue, theme.colors.Black],
        [theme.graphColors.Red, theme.colors.Black],
    ]
}

type Group = keyof typeof ColorScheme
    

interface SegmentPathProps {
    fillColor: string
    arc: string | null
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

const SegmentPath: React.FunctionComponent<SegmentPathProps> = ({fillColor, arc, onMouseEnter, onMouseLeave}) => {
    return (
        <path 
            d={arc || undefined} 
            fill={fillColor}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    )
}

interface SegmentLabelProps {
    startAngle: number
    endAngle: number
    innerRadius: number
    outerRadius: number
    fillColor: string
    textColor: string
    data: Value
    isLabelHovered?: boolean
    orientation: Orientation
    drawRect?: boolean
}

const SegmentLabel: React.FunctionComponent<SegmentLabelProps> = ({ 
    startAngle, endAngle, fillColor, textColor, innerRadius, outerRadius,
    data, orientation, isLabelHovered = false, drawRect = false
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) / 2
    const teta = startAngle + (endAngle - startAngle) / 2 - Math.PI / 2
    let isVisible = true
    if (data.value < THRESHOLD_LABEL_HIDE) {
        isVisible = false
    }

    let x: number = radius * Math.cos(teta)
    if (orientation === 'left') {
        x = Math.max(x + 15, RECT_PERCENTAGE_SIZE / 2)
    } else {
        x = Math.min(x + 15, - RECT_PERCENTAGE_SIZE / 2)
    }
    const y = radius * Math.sin(teta)
    const transform = `translate(${x} ${y})`

    return (
        <LabelGroup transform={transform} isVisible={isVisible || isLabelHovered}>
            {drawRect ? 
                <rect 
                    fill={fillColor} 
                    width={RECT_PERCENTAGE_SIZE * 0.8} 
                    height={RECT_PERCENTAGE_SIZE / 2} 
                    x={-RECT_PERCENTAGE_SIZE / 2}
                    y={-RECT_PERCENTAGE_SIZE / 4 - 5}
                    rx={RECT_PERCENTAGE_SIZE * 0.2}
                />: null
            }
            <PercentText 
                fill={textColor}
                y={RECT_PERCENTAGE_SIZE / 8}
                x={-RECT_PERCENTAGE_SIZE / 8}
            >
                {data.value}%
            </PercentText>
            {data.evolution !== null ? 
                <g transform={`translate(0 ${EVOLUTION_Y_OFFSET})`}>
                    <EvolutionSvg value={data.evolution} />
                </g>: null
            }
        </LabelGroup>
    )
}

const generateArcsAndPie = (
    values: Array<Value>, 
    innerRadius: number, 
    outerRadius: number,
    orientation: Orientation
) => {
    const padAngle = PAD_ANGLE * VIEWPORT_WIDTH / innerRadius
    const startAngle = orientation === 'left' ? (0 - padAngle) : (0 + padAngle)
    const endAngle = orientation === 'left' ? Math.PI + padAngle : (-Math.PI + padAngle)
    const pieGenerator = d3Shape.pie<Value>()
        .value(d => d.value)
        .startAngle(startAngle)
        .endAngle(endAngle)
        .padAngle(padAngle)
        .sort(null)
    const arcGenerator = d3Shape.arc()
        .padAngle(padAngle)
    const pie = pieGenerator(values)
    const arcs = pie
        .map(({startAngle, endAngle}) => arcGenerator({
            startAngle, endAngle,
            innerRadius,
            outerRadius,
        }))
    
    return [pie, arcs]
}

// This is the arc we will use for the animation
const generatePieCoverPath = (
    innerRadius: number, 
    outerRadius: number,
    orientation: Orientation
) => {
    const startAngle = 0
    const endAngle = orientation === 'left' ? Math.PI :-Math.PI
    const arcGenerator = d3Shape.arc()
    const arc = arcGenerator({
        innerRadius, outerRadius, startAngle, endAngle
    })
    return <CoverPath d={arc!} />
}

export interface Props {
    width: number
    title?: string
    group1Values: Array<Value>
    group2Values: Array<Value>
    orientation: Orientation
}

const Graph: React.FunctionComponent<Props> = ({ 
    width,
    title,
    group1Values,
    group2Values,
    orientation
}) => {
    const [pie1, arcs1] = generateArcsAndPie(
        group1Values,
        INNER_RADIUS1, OUTER_RADIUS1,
        orientation
    )
    const [labelHoveredIndex1, setLabelHoveredIndex1] = useState<number>(-1)
    const path1Cover = generatePieCoverPath(INNER_RADIUS1 - 5, OUTER_RADIUS1 + 5, orientation)
    const paths1: Array<JSX.Element> = []
    const labels1: Array<JSX.Element> = []
    const labels1OnTop: Array<JSX.Element> = []
    ;(pie1 as Pie).forEach(({data, startAngle, endAngle}, i) => {
        const [fillColor, textColor] = ColorScheme['1'][i]
        const _onMouseEnter = () => setLabelHoveredIndex1(i)
        const _onMouseLeave = () => {
            if (labelHoveredIndex1 === i) {
                setLabelHoveredIndex1(-1)
            }
        }
        paths1.push(<SegmentPath
            key={`path${i}`}
            fillColor={fillColor} 
            arc={(arcs1 as Arcs)[i]} 
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
        />)
        const label = (<SegmentLabel 
            key={`label${i}`}
            startAngle={startAngle} 
            endAngle={endAngle} 
            innerRadius={INNER_RADIUS1} 
            outerRadius={OUTER_RADIUS1} 
            fillColor={fillColor}
            textColor={textColor}
            isLabelHovered={labelHoveredIndex1 === i}
            data={data}
            orientation={orientation}
            drawRect={true}
        />)
        if (data.value > THRESHOLD_LABEL_HIDE) {
            labels1.push(label)
        } else {
            labels1OnTop.push(label)
        }
    })

    const [pie2, arcs2] = generateArcsAndPie(
        group2Values,
        INNER_RADIUS2, OUTER_RADIUS2,
        orientation
    )
    const [labelHoveredIndex2, setLabelHoveredIndex2] = useState<number>(-1)
    const path2Cover = generatePieCoverPath(INNER_RADIUS2 - 5, OUTER_RADIUS2 + 5, orientation)
    const paths2: Array<JSX.Element> = [] 
    const labels2: Array<JSX.Element> = []
    const labels2OnTop: Array<JSX.Element> = []
    ;(pie2 as Pie).forEach(({data, startAngle, endAngle}, i) => {
        const [fillColor, textColor] = ColorScheme['2'][i]
        const _onMouseEnter = () => setLabelHoveredIndex2(i)
        const _onMouseLeave = () => {
            if (labelHoveredIndex2 === i) {
                setLabelHoveredIndex2(-1)
            }
        }
        paths2.push(<SegmentPath
            key={`path${i}`}
            fillColor={fillColor} 
            arc={(arcs2 as Arcs)[i]}
            onMouseEnter={_onMouseEnter}
            onMouseLeave={_onMouseLeave}
        />)
        const label = (<SegmentLabel
            key={`label${i}`}
            startAngle={startAngle} 
            endAngle={endAngle} 
            innerRadius={INNER_RADIUS2 - LABEL_OFFSET} 
            outerRadius={INNER_RADIUS2} 
            fillColor={fillColor}
            textColor={textColor}
            isLabelHovered={labelHoveredIndex2 === i}
            data={data}
            orientation={orientation}
        />)
        if (data.value > THRESHOLD_LABEL_HIDE) {
            labels2.push(label)
        } else {
            labels2OnTop.push(label)
        }
    })

    const transform = `translate(${orientation === 'left' ? 0 : VIEWPORT_WIDTH} ${VIEWPORT_WIDTH})`
    const viewbox = (
        `0 ` + 
        `${-SVG_PADDING_TOP} ` +
        `${VIEWPORT_WIDTH} ` +
        `${VIEWPORT_WIDTH * HEIGHT_RATIO + SVG_PADDING_BOTTOM + SVG_PADDING_TOP}`)
    return (
        <Container orientation={orientation}>
            {title ? 
                <GraphTitle orientation={orientation}>{title}</GraphTitle>
                : null
            }
            <svg 
                width={width} 
                height={width * HEIGHT_RATIO}
                viewBox={viewbox}
            >
                <g transform={transform}>
                    {paths1}
                    {path1Cover}
                    {labels1}
                    {labels1OnTop}
                </g>
                <g transform={transform}>
                    {paths2}
                    {path2Cover}
                    {labels2}
                    {labels2OnTop}
                </g>
            </svg>
        </Container>
    )
}

export default React.memo(Graph)