import React from 'react'
import ReactSelect, { Props as ReactSelectProps } from 'react-select'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { Wave } from '../../models'
import { useDispatch } from 'react-redux'
import { DataFiltersTypes } from '../../store/data-filters'

interface Option {
    value: string,
    label: string,
}

export interface Props {
    waves: Array<Wave>
    variant: "white" | "purple"
    value: number | null
    height?: number
    actionCreator: (waveIndex: any) => DataFiltersTypes
}

const StyledReactSelect = styled(ReactSelect)<Props>`
    ${props => props.height ? `
        .ReactSelect__option {
            min-height: ${props.height}px;
        }

        .ReactSelect__control {
            height: ${props.height}px;
            min-height: ${props.height}px;

            .ReactSelect__single-value {
                height: ${props.height}px;
            }
        }
    `: ``}

    .ReactSelect__indicator-separator{
        display: none;
    }

    .ReactSelect__control {
        ${theme.mixins.clickable}
        border-radius: ${theme.dimensions.borderRadius1};
        border: none;
        box-shadow: none;
        :hover {
            border: none;
        }
    }

    .ReactSelect__control:hover {
        border: none;
        box-shadow: none;
    }

    .ReactSelect__option {
        ${theme.mixins.clickable}
        color: ${theme.colors.Black};
        background-color: ${theme.colors.White};
    }

    .ReactSelect__option.ReactSelect__option--is-focused {
        color: ${theme.colors.Black};
        background-color: ${theme.colors.Grey};
    }

    .ReactSelect__placeholder {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 90%;
        overflow: hidden;
    }
    
    ${props => props.variant === 'purple' ? `
        ${props.value ? `
            .ReactSelect__control {
                .ReactSelect__single-value {
                    color: ${theme.colors.White};
                }
                background-color: ${theme.colors.Purple150};
                border: solid 1px ${theme.colors.White};
                :hover {
                    border: solid 1px ${theme.colors.Purple50};
                    background-color: ${theme.colors.Purple50};
                }
            }
        
            .ReactSelect__indicators svg {
                path {
                    fill: ${theme.colors.White};
                }
            }

        `: `
            .ReactSelect__control {
                background-color: ${theme.colors.Purple100};
                :hover {
                    background-color: ${theme.colors.Purple50};
                }
            }
        
            .ReactSelect__indicators svg * {
                fill: ${theme.colors.Purple150};
            }

        `}

        .ReactSelect__option {
            padding: 2px 8px;
        }

        .ReactSelect__indicator {
            padding: 2px 8px;
        }
        
        .ReactSelect__placeholder {
            color: ${theme.colors.Purple150};
        }

        .ReactSelect__control.ReactSelect__control--is-disabled {
            background-color: ${theme.colors.Purple150};
            border: 1px solid ${theme.colors.Purple50};

            .ReactSelect__placeholder {
                color: ${theme.colors.Purple50};
            }
        }

    `: `
        .ReactSelect__control {
            :hover {
                .ReactSelect__single-value {
                    color: ${theme.colors.Purple100};
                }
            }
        }

        .ReactSelect__indicators svg * {
            fill: ${theme.colors.Black};
        }
    `}
`

const WaveSelect: React.FunctionComponent<Props & Omit<ReactSelectProps, keyof Props>> = ({ waves, actionCreator, variant, value, ...passThrough }) => {
    const dispatch = useDispatch()
    const valueStr = `${value}`
    const isDisabled = (waves.length === 0)
    const options = waves.map((wave, i) => ({value: `${i}`, label: `${wave.title} - ${wave.date}`}))
    const selectedOpt = options.filter(opt => opt.value === valueStr)[0]
    return (
        <StyledReactSelect
            classNamePrefix={'ReactSelect'}
            // That is a trick to force the ReactSelect to re-render if we programmatically change the value
            // ref : https://stackoverflow.com/questions/50412843/how-to-programmatically-clear-reset-react-select
            key={valueStr}
            options={options}
            variant={variant}
            isSearchable={false}
            value={selectedOpt}
            isDisabled={isDisabled}
            onChange={(option: Option | null) => {
                dispatch(actionCreator(option ? parseInt(option.value, 10) : null))
            }}
            {...passThrough}
        />
    )
}

export default WaveSelect