import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { EvolutionHtml } from '../shared'

const WIDTH_RATIO = 0.85
const BAR_HEIGHT = '1em'
const BAR_HEIGHT_MOBILE = '1.3em'
const EVOLUTION_SCALE = 0.7

export const BarColors = [
    theme.graphColors.Yellow,
    theme.graphColors.Orange,
]

interface BarProps {
    value: number
    valueMax: number
    hasOther: boolean
}

const Bar = styled.div<BarProps>`
    display: inline-block;
    border-radius: 0.25em;
    height: ${BAR_HEIGHT};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        height: ${BAR_HEIGHT_MOBILE};
    }
    margin-right: 0.2em;
    width: ${props => props.value * WIDTH_RATIO * 100 / props.valueMax}%;
`

export const Title = styled.div`
    margin-bottom: 0.2em;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 115%;
    }
`

const Bar1 = styled(Bar)`
    background-color: ${BarColors[0]};
    ${props => props.hasOther ? `
        border-bottom-left-radius: 0;
    `: ``}
    
`

const Bar2 = styled(Bar)`
    background-color: ${BarColors[1]};
    border-top-left-radius: 0;
`

export const Percentage = styled.span`
    font-family: ${theme.fonts.title};
    font-weight: bold;
    font-size: 95%;
    margin-right: 0.2em;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 125%;
    }
`

export const BarContainer = styled.div`
    height: ${BAR_HEIGHT};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        height: ${BAR_HEIGHT_MOBILE};
    }
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`

export interface Props {
    title: string
    value: number
    valueOther: number | null
    valueMax: number
    evolution: number | null
    evolutionOther: number | null
    className?: string
}

const Graph: React.FunctionComponent<Props> = ({ 
    className = '',
    title,
    value,
    valueOther,
    valueMax,
    evolution,
    evolutionOther,
}) => {
    return (
        <div className={className}>
            <Title>{title}</Title>
            <BarContainer>
                <Bar1 value={value} valueMax={valueMax} hasOther={valueOther !== null} />
                <Percentage>{value}%</Percentage>
                {evolution !== null ? 
                    <EvolutionHtml value={evolution} scale={EVOLUTION_SCALE} /> : 
                    null
                }
            </BarContainer>
            { valueOther !== null ?
                <BarContainer>
                    <Bar2 value={valueOther} valueMax={valueMax} hasOther={valueOther !== null} />
                    <Percentage>{valueOther}%</Percentage>
                    {evolutionOther !== null ? 
                        <EvolutionHtml value={evolutionOther} scale={EVOLUTION_SCALE} /> : 
                        null
                    }
                </BarContainer> :
                null
            }
        </div>
    )
}

export default styled(React.memo(Graph))`
    margin-bottom: ${theme.spacings.vertical05};
`