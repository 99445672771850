import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle } from '../shared'
import { CSSTransition } from 'react-transition-group'
import theme from '../../../theme'
import Legend from './Legend'
import Graph, { Title, Labels, Segments } from './Graph'

const GraphContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
`
const TransitionContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ContainerGraphsAndLegend = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-top: ${theme.spacings.vertical05};
`

const StyledLegend = styled(Legend)`
    width: 25%;
`

const Container = styled.div`
    height: 100%;

    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }

    ${Title} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.2, 0.2)}
    }

    ${Legend}, ${Labels} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.6, 0.2)}
    }

    ${Segments} {
        ${theme.transitions.buildPageTransition(['max-height'], 0.2, 0.5)}
    }

    .estimation-appear {
        ${VisualizationTitle}, ${Title}, ${Legend}, ${Labels} {
            opacity: 0;
        }

        ${Segments} {
            max-height: 0;
        }
    }

    .estimation-appear-active {
        ${VisualizationTitle}, ${Title}, ${Legend}, ${Labels} {
            opacity: 1;
        }

        ${Segments} {
            max-height: 380px;
        }
    }

    .estimation-appear-done {
        ${Segments} {
            max-height: 380px;
        }
    }
`

const VisualizationEstimation: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort, cohortOther
}) => {
    const values = answerValues.map(answerValue => answerValue.value)
    const evolutions = answerValues.map(answerValue => answerValue.evolution)
    const valuesOther = answerValues.map(answerValue => answerValue["value-other"]!)
    const evolutionsOther = answerValues.map(answerValue => answerValue["evolution-other"])
    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="estimation"
            >
                <TransitionContainer>
                    <VisualizationTitle>{question.text}</VisualizationTitle>
                    <ContainerGraphsAndLegend>
                        <StyledLegend answerValues={answerValues} />
                        <GraphContainer>
                            <Graph cohort={cohort} values={values} evolutions={evolutions} />
                            {cohortOther ? 
                            <Graph cohort={cohortOther} values={valuesOther} evolutions={evolutionsOther} />: null}
                        </GraphContainer>
                    </ContainerGraphsAndLegend>
                </TransitionContainer>
            </CSSTransition>
        </Container>
    )
}

export default VisualizationEstimation