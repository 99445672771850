import React from 'react'
import { components, MenuListComponentProps } from 'react-select'
import styled from 'styled-components/macro'
import isEqual from 'lodash.isequal'
import WaveSelect from '../WaveSelect'
import theme from '../../../theme'
import { selectWaves, selectDataFiltersWave, selectDataFiltersWaveCompare } from '../../../store/selectors'
import { useSelector } from 'react-redux'
import { setWave, setWaveCompare } from '../../../store/data-filters'

export const Container = styled.div`
    font-size: 108%;
    background-color: ${theme.colors.Purple150};
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
    align-items: center;
    /* Allow to position select menus */
    position: relative;
`

const MenuTitle = styled.div`
    text-align: center;
    font-style: italic;
    padding: ${theme.spacings.vertical05} 0;
`

const BaseStyledWaveSelect = styled(WaveSelect)`
    position: initial !important;

    .ReactSelect__menu-list {
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        box-shadow: 0px -1px 7px 3px rgba(0,0,0,0.34);
    }

    .ReactSelect__menu {
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;

        .ReactSelect__menu-list {
            transform: translateY(-100%);
            padding-bottom: 0;
        }

        .ReactSelect__option {
            padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
        }
    }
`

const StyledWaveSelect = styled(BaseStyledWaveSelect)`
    display: inline-block;
    width: 60%;
    .ReactSelect__menu-list {
        background-color: ${theme.colors.Grey};

        .ReactSelect__option.ReactSelect__option--is-focused:nth-child(2n), 
        .ReactSelect__option:nth-child(2n) {
            background-color: ${theme.colors.White};
        }

        .ReactSelect__option.ReactSelect__option--is-focused:nth-child(2n+1), 
        .ReactSelect__option:nth-child(2n+1) {
            background-color: ${theme.colors.Grey};
        }
    }
`

const StyledWaveCompareSelect = styled(BaseStyledWaveSelect)`
    display: inline-block;
    width: 37%;

    .ReactSelect__indicators {
        padding-right: 6px;
        .ReactSelect__indicator {
            padding: 2px 2px;
        }
    }

    .ReactSelect__menu-list {
        background-color: ${theme.colors.Purple100};
        ${MenuTitle} {
            color: ${theme.colors.White};
        }
        .ReactSelect__option {
            color: ${theme.colors.White};
        }

        .ReactSelect__option.ReactSelect__option--is-focused:nth-child(2n), 
        .ReactSelect__option:nth-child(2n) {
            background-color: ${theme.colors.Purple40};
        }

        .ReactSelect__option.ReactSelect__option--is-focused:nth-child(2n+1), 
        .ReactSelect__option:nth-child(2n+1) {
            background-color: ${theme.colors.Purple100};
        }
    }
`

interface MenuListProps {
    title: string
}

const MenuList: React.FunctionComponent<MenuListComponentProps<any> & MenuListProps> = ({title, ...props}) => {
    return (
        <components.MenuList {...props}>
            <MenuTitle>{title}</MenuTitle>
            {props.children}
        </components.MenuList>
    )
}

const FiltersMobile = () => {
    const waves = useSelector(selectWaves, isEqual)
    const waveIndex = useSelector(selectDataFiltersWave)
    const waveCompareIndex = useSelector(selectDataFiltersWaveCompare)
    const wavesCompare = waves
        // We can only compare to previous waves
        .filter((_, i) => i < waveIndex)
    const isCompareDisabled = wavesCompare.length === 0

    return (
        <Container>
            <StyledWaveSelect 
                variant="white" 
                waves={waves} 
                value={waveIndex}
                menuPlacement="top"
                actionCreator={setWave}
                components={{ 
                    MenuList: (props: MenuListComponentProps<any>) => <MenuList title="Afficher la vague" {...props} /> 
                }}
            />
            <StyledWaveCompareSelect 
                variant="purple" 
                waves={wavesCompare} 
                isClearable={true}
                value={waveCompareIndex}
                placeholder={isCompareDisabled ? '...' : 'Comparer...'}
                menuPlacement="top"
                actionCreator={setWaveCompare}
                components={{ 
                    MenuList: (props: MenuListComponentProps<any>) => <MenuList title="Évolution par rapport à" {...props} /> 
                }}
            />
        </Container>
    )
}

export default React.memo(FiltersMobile)