import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import configPages, { CHIFFRES_ID, RESULTATS_ID } from '../../config-pages'
import ChiffreCle from './ChiffreCle'

import theme from '../../theme'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../../store/page-transitions'
import PageContainerChildren from '../PageContainerChildren'
import { selectFilteredKeyNumbers } from '../../store/selectors'
import { scrollToPageTop } from '../PageContainer'

const CARD_GRID_GAP = '1.2rem'
const CARD_START_WIDTH = 50
const CARDS_ANIMATION_INITIAL_DELAY = 0.2
const CARDS_ANIMATION_DURATION = 0.15

const {enterOrAppearTransition, exitTransition} = theme.transitions.buildPageTransitionMaker('&.PageChiffresCles')

const StyledPageContainerChildren = styled(PageContainerChildren)`
    
    @media (min-width: ${theme.dimensions.thresholdMobile}px) {

        ${enterOrAppearTransition(
            null,
            [['opacity', '0', '1']], 
            0, 0.2)}

        ${() => {
            let output = ''
            for (let i = 0; i < 10; i++) {
                output += enterOrAppearTransition(
                    [`${ChiffreCle}:nth-child(${i + 1})`], 
                    [['width', `${CARD_START_WIDTH}px`, `${theme.dimensions.keyNumberCardWidth}px`]], 
                    CARDS_ANIMATION_INITIAL_DELAY + (i * CARDS_ANIMATION_DURATION / 2), CARDS_ANIMATION_DURATION)
                output += enterOrAppearTransition(
                    [`${ChiffreCle}:nth-child(${i + 1}) > *`], 
                    [['opacity', '0', '1']], 
                    CARDS_ANIMATION_INITIAL_DELAY + CARDS_ANIMATION_DURATION + (i * CARDS_ANIMATION_DURATION / 2), CARDS_ANIMATION_DURATION)
            }
            return output
        }}

        ${exitTransition(null, [['opacity', '1', '0']], 0, 1)}

    }

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        padding-left: 0;
        padding-right: 0;
    }
`

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${CARD_GRID_GAP};
    grid-auto-rows: minmax(100px, auto);
    width: calc(${CARD_GRID_GAP} * 2 + ${theme.dimensions.keyNumberCardWidth}px * 3);
    margin: auto;
    /* to leave space for footer */
    padding-bottom: 3em;

    @media (min-width: ${theme.dimensions.thresholdMobile}px) and (max-width: ${theme.dimensions.thresholdBigDesktop}px) {
        grid-template-columns: repeat(2, 1fr);
        width: calc(${CARD_GRID_GAP} * 1 + ${theme.dimensions.keyNumberCardWidth}px * 2);
    }

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        width: auto;
        display: block;
        padding-top: ${theme.spacings.vertical1};
        /* to leave space for footer */
        padding-bottom: 7em;
    } 

    ${ChiffreCle} {
        justify-self: center;
    }
`

interface Props {}

const PageChiffresCles: React.FunctionComponent<Props> = () => {
    const keyNumbers = useSelector(selectFilteredKeyNumbers)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(CHIFFRES_ID))
        // On mobile, since we don't have transitions we need to handle scrolling too
        scrollToPageTop()
    }, [dispatch])

    const chiffreClesElements = keyNumbers.map((keyNumber, i) => {
        return (
            <ChiffreCle 
                index={i}
                key={i}
                title={keyNumber.title}
                body={keyNumber.body}
                detailsUrl={keyNumber["link-question-id"] ? configPages[RESULTATS_ID].href(keyNumber["link-question-id"]) : null}
                shareId={keyNumber["link-share-id"]}
                picto={keyNumber.picto}
            />
        )
    })
    return (
        <StyledPageContainerChildren isScrollable={true}>
            <Container>
                {chiffreClesElements}
            </Container>
        </StyledPageContainerChildren>
    )
}

export default PageChiffresCles