import React from 'react'
import styled from 'styled-components/macro'
import { Cohort, CohortDisplay } from '../../../models'
import { BarColors } from './Graph'
import theme from '../../../theme'

const COLOR_SIZE = '1em'

const LegendItem = styled.div`
    display: inline-block;
    margin-right: ${theme.spacings.horizontal1};
`

interface LegendItemColorProps {
    color: string
}

const LegendItemColor = styled.span`
    display: inline-block;
    background-color: ${props => props.color};
    width: ${COLOR_SIZE};
    height: ${COLOR_SIZE};
    vertical-align: middle;
    margin-right: 0.3em;
`

const LegendItemText = styled.span`
    color: ${theme.colors.Grey150};
    vertical-align: middle;
`

export interface Props {
    cohort1: Cohort
    cohort2: Cohort
    className?: string
}

const Legend: React.FunctionComponent<Props> = ({ cohort1, cohort2, className }) => {
    return (
        <div className={className}>
            <LegendItem>
                <LegendItemColor color={BarColors[0]} />
                <LegendItemText>{CohortDisplay[cohort1]}</LegendItemText>
            </LegendItem>
            <LegendItem>
                <LegendItemColor color={BarColors[1]} />
                <LegendItemText>{CohortDisplay[cohort2]}</LegendItemText>
            </LegendItem>
        </div>
    )
}

export default styled(React.memo(Legend))`
    font-size: 90%;
    background-color: ${theme.colors.Grey};
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
    border-radius: 0.5em;
    margin-bottom: ${theme.spacings.vertical05};
`