import * as yup from 'yup'
import { fetchJSON } from "./requests"
import { DataRaw, DataInflated, DataValidated } from "./db-core"

export interface FetchRemoteGsheetConfig {
    spreadsheetId: string,
    sheetId: string,
    apiKey: string,
}

interface GSheetV4RawData {
    "values": Array<Array<string>>
}

const buildGsheetUrl = (spreadsheetId: string, sheetId: string, apiKey: string) =>
    `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetId}?key=${apiKey}`

export const fetchRemoteGsheet = async (config: FetchRemoteGsheetConfig): Promise<DataRaw> => {
    const url = buildGsheetUrl(config.spreadsheetId, config.sheetId, config.apiKey)
    return fetchJSON(url)
}

export const fetchInMemory = async (data: DataRaw) => Promise.resolve(data)

export const inflateGsheetFirstRow = async (dataRaw: GSheetV4RawData): Promise<DataInflated> => {
    const rows = dataRaw.values
    const fieldNames = rows[0]
    return Promise.resolve(rows.slice(1).map(row => {
        const datum: {[fieldName: string] : string} = {}
        fieldNames.forEach(
            (fieldName, columnIndex) => datum[fieldName] = row[columnIndex])
        return datum
    }))
}

export const validateYup = (validationSchema: yup.Schema<any>, dataInflated: DataInflated): Promise<DataValidated> => {
    return yup.array()
        .of(validationSchema)
        .validate(dataInflated, {abortEarly: false}) as Promise<DataValidated>
}