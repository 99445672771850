import React from 'react'
import styled from 'styled-components/macro'
import { EvolutionHtml } from '../shared'
import theme from '../../../theme'
import times from 'lodash.times'
import sample from 'lodash.sample'

import {ReactComponent as PeopleRed1} from '../../../images/charts/icons 100-perso/unit-ppl_1-pink.svg'
import {ReactComponent as PeopleRed2} from '../../../images/charts/icons 100-perso/unit-ppl_2-pink.svg'
import {ReactComponent as PeopleRed3} from '../../../images/charts/icons 100-perso/unit-ppl_3-pink.svg'
import {ReactComponent as PeopleRed4} from '../../../images/charts/icons 100-perso/unit-ppl_4-pink.svg'
import {ReactComponent as PeopleRed5} from '../../../images/charts/icons 100-perso/unit-ppl_5-pink.svg'
import {ReactComponent as PeopleRed6} from '../../../images/charts/icons 100-perso/unit-ppl_6-pink.svg'
import {ReactComponent as PeopleRed7} from '../../../images/charts/icons 100-perso/unit-ppl_7-pink.svg'
import {ReactComponent as PeopleRed8} from '../../../images/charts/icons 100-perso/unit-ppl_8-pink.svg'
import {ReactComponent as PeopleRed9} from '../../../images/charts/icons 100-perso/unit-ppl_9-pink.svg'
import {ReactComponent as PeopleRed10} from '../../../images/charts/icons 100-perso/unit-ppl_10-pink.svg'
import {ReactComponent as PeopleRed11} from '../../../images/charts/icons 100-perso/unit-ppl_11-pink.svg'

import {ReactComponent as PeopleYellow1} from '../../../images/charts/icons 100-perso/unit-ppl_1-yellow.svg'
import {ReactComponent as PeopleYellow2} from '../../../images/charts/icons 100-perso/unit-ppl_2-yellow.svg'
import {ReactComponent as PeopleYellow3} from '../../../images/charts/icons 100-perso/unit-ppl_3-yellow.svg'
import {ReactComponent as PeopleYellow4} from '../../../images/charts/icons 100-perso/unit-ppl_4-yellow.svg'
import {ReactComponent as PeopleYellow5} from '../../../images/charts/icons 100-perso/unit-ppl_5-yellow.svg'
import {ReactComponent as PeopleYellow6} from '../../../images/charts/icons 100-perso/unit-ppl_6-yellow.svg'
import {ReactComponent as PeopleYellow7} from '../../../images/charts/icons 100-perso/unit-ppl_7-yellow.svg'
import {ReactComponent as PeopleYellow8} from '../../../images/charts/icons 100-perso/unit-ppl_8-yellow.svg'
import {ReactComponent as PeopleYellow9} from '../../../images/charts/icons 100-perso/unit-ppl_9-yellow.svg'
import {ReactComponent as PeopleYellow10} from '../../../images/charts/icons 100-perso/unit-ppl_10-yellow.svg'
import {ReactComponent as PeopleYellow11} from '../../../images/charts/icons 100-perso/unit-ppl_11-yellow.svg'

import {ReactComponent as PeopleGreen1} from '../../../images/charts/icons 100-perso/unit-ppl_1-green.svg'
import {ReactComponent as PeopleGreen2} from '../../../images/charts/icons 100-perso/unit-ppl_2-green.svg'
import {ReactComponent as PeopleGreen3} from '../../../images/charts/icons 100-perso/unit-ppl_3-green.svg'
import {ReactComponent as PeopleGreen4} from '../../../images/charts/icons 100-perso/unit-ppl_4-green.svg'
import {ReactComponent as PeopleGreen5} from '../../../images/charts/icons 100-perso/unit-ppl_5-green.svg'
import {ReactComponent as PeopleGreen6} from '../../../images/charts/icons 100-perso/unit-ppl_6-green.svg'
import {ReactComponent as PeopleGreen7} from '../../../images/charts/icons 100-perso/unit-ppl_7-green.svg'
import {ReactComponent as PeopleGreen8} from '../../../images/charts/icons 100-perso/unit-ppl_8-green.svg'
import {ReactComponent as PeopleGreen9} from '../../../images/charts/icons 100-perso/unit-ppl_9-green.svg'
import {ReactComponent as PeopleGreen10} from '../../../images/charts/icons 100-perso/unit-ppl_10-green.svg'
import {ReactComponent as PeopleGreen11} from '../../../images/charts/icons 100-perso/unit-ppl_11-green.svg'

import {ReactComponent as PeopleBlue1} from '../../../images/charts/icons 100-perso/unit-ppl_1-blue.svg'
import {ReactComponent as PeopleBlue2} from '../../../images/charts/icons 100-perso/unit-ppl_2-blue.svg'
import {ReactComponent as PeopleBlue3} from '../../../images/charts/icons 100-perso/unit-ppl_3-blue.svg'
import {ReactComponent as PeopleBlue4} from '../../../images/charts/icons 100-perso/unit-ppl_4-blue.svg'
import {ReactComponent as PeopleBlue5} from '../../../images/charts/icons 100-perso/unit-ppl_5-blue.svg'
import {ReactComponent as PeopleBlue6} from '../../../images/charts/icons 100-perso/unit-ppl_6-blue.svg'
import {ReactComponent as PeopleBlue7} from '../../../images/charts/icons 100-perso/unit-ppl_7-blue.svg'
import {ReactComponent as PeopleBlue8} from '../../../images/charts/icons 100-perso/unit-ppl_8-blue.svg'
import {ReactComponent as PeopleBlue9} from '../../../images/charts/icons 100-perso/unit-ppl_9-blue.svg'
import {ReactComponent as PeopleBlue10} from '../../../images/charts/icons 100-perso/unit-ppl_10-blue.svg'
import {ReactComponent as PeopleBlue11} from '../../../images/charts/icons 100-perso/unit-ppl_11-blue.svg'

const Theme = {
    red: {
        color: theme.graphColors.Red,
        peopleSvgs: [
            PeopleRed1,
            PeopleRed2,
            PeopleRed3,
            PeopleRed4,
            PeopleRed5,
            PeopleRed6,
            PeopleRed7,
            PeopleRed8,
            PeopleRed9,
            PeopleRed10,
            PeopleRed11,
        ]
    },
    yellow: {
        color: theme.graphColors.Orange,
        peopleSvgs: [
            PeopleYellow1,
            PeopleYellow2,
            PeopleYellow3,
            PeopleYellow4,
            PeopleYellow5,
            PeopleYellow6,
            PeopleYellow7,
            PeopleYellow8,
            PeopleYellow9,
            PeopleYellow10,
            PeopleYellow11,
        ]
    },
    green: {
        color: theme.graphColors.Green,
        peopleSvgs: [
            PeopleGreen1,
            PeopleGreen2,
            PeopleGreen3,
            PeopleGreen4,
            PeopleGreen5,
            PeopleGreen6,
            PeopleGreen7,
            PeopleGreen8,
            PeopleGreen9,
            PeopleGreen10,
            PeopleGreen11,
        ]
    },
    blue: {
        color: theme.graphColors.Cyan,
        peopleSvgs: [
            PeopleBlue1,
            PeopleBlue2,
            PeopleBlue3,
            PeopleBlue4,
            PeopleBlue5,
            PeopleBlue6,
            PeopleBlue7,
            PeopleBlue8,
            PeopleBlue9,
            PeopleBlue10,
            PeopleBlue11,
        ]
    },
}

export type ThemeKey = keyof typeof Theme


const Container = styled.div`
    .People {
        margin-right: -0.5em;
    }
`

interface PercentageContainerProps {
    themeKey: ThemeKey
}

const PercentageContainer = styled.div<PercentageContainerProps>`
    font-family: ${theme.fonts.title};
    color: ${props => Theme[props.themeKey].color};
`

const PercentageContainerValue = styled.span`
    margin-right: 0.5em;
`

const AnswerContainer = styled.div``

const PeopleContainer = styled.div``

interface Props {
    value: number
    valueEvolution: number | null
    answerText: string
    themeKey: ThemeKey
    className?: string
}

const Graph: React.FunctionComponent<Props> = ({
    value,
    valueEvolution,
    answerText,
    themeKey,
    className
}) => {
    const theme = Theme[themeKey]
    const people = times(value, (i) => {
        const People = sample(theme.peopleSvgs)!
        return <People key={i} className="People" />
    })
    return (
        <Container className={className}>
            <PercentageContainer themeKey={themeKey}>
                <PercentageContainerValue>{value}%</PercentageContainerValue>
                {valueEvolution !== null ? <EvolutionHtml value={valueEvolution} />: null}
            </PercentageContainer>
            <AnswerContainer>
                {answerText}
            </AnswerContainer>
            <PeopleContainer>
                {people}
            </PeopleContainer>        
        </Container>
    )
}

export default React.memo(Graph)