import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle } from '../shared'
import { CSSTransition } from 'react-transition-group'
import theme from '../../../theme'
import { CombinedAnswer } from '../../../models'
import Graph, { Bars, Icon, AnswerTitles } from './Graph'
import { ThemeGroups } from './Themes'
import { BarCommon, CohortText  } from './Bar'
import {ReactComponent as NeitherSvg} from '../../../images/charts/icons choix-binaire/arrow-neither.svg'

const LegendMiddle = styled.div`
    font-family: ${theme.fonts.subtitle};
    text-align: center;
    font-size: 110%;
    height: 0.7em;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 140%;
    }

    svg {
        position: relative;
        bottom: 0.4em;
    }
`

const Container = styled.div`
    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }

    ${Bars} {
        ${theme.transitions.buildPageTransition(['max-width'], 0, 1)}
    }

    ${BarCommon} *, ${LegendMiddle}, ${Icon}, ${AnswerTitles}, ${CohortText} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.4, 0.3)}
    }

    .choixBinaire-appear {
        ${Bars} {
            max-width: 0px;
        }

        ${BarCommon} *, ${LegendMiddle}, ${Icon}, ${AnswerTitles}, ${CohortText} {
            opacity: 0;
        }
    }

    .choixBinaire-appear-active {
        ${Bars} {
            max-width: ${theme.dimensions.maxWidth}px;
        }

        ${BarCommon} *, ${LegendMiddle}, ${Icon}, ${AnswerTitles}, ${CohortText} {
            opacity: 1;
        }
    }
`

const VisualizationChoixBinaire: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort, cohortOther
}) => {
    const grouped: {[group: string]: [CombinedAnswer, CombinedAnswer, CombinedAnswer]} = {}
    answerValues.forEach(answerValue => {
        grouped[answerValue.group!] = grouped[answerValue.group!] || []
        grouped[answerValue.group!].push(answerValue)
    })
    const groups = Object.keys(grouped)
    groups.sort()

    const graphs = groups.map(group => {
        return (
            <Graph 
                answerValues={grouped[group] as [CombinedAnswer, CombinedAnswer, CombinedAnswer]} 
                group={group as ThemeGroups}
                key={group}
                cohort={cohort}
                cohortOther={cohortOther}
            />
        )
    })

    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="choixBinaire"
            >
                <div>
                    <VisualizationTitle>{question.text}</VisualizationTitle>
                    <LegendMiddle>
                        <div>Autant l'un que l'autre</div>
                        <NeitherSvg />
                    </LegendMiddle>
                    {graphs}
                </div>
            </CSSTransition>
        </Container>
    )
}

export default VisualizationChoixBinaire