import yup from 'yup'

export type DataRaw = any
export type DataInflated = Array<{[fieldName: string] : string}>
export type DataValidated = Array<{[fieldName: string] : any}>

type CollectionId = string

export type ResultWithError = [yup.ValidationError | null, any]
export type MultipleResultsWithErrors = {[collectionId: string]: ResultWithError}

export interface CollectionAdapter {
    fetch: () => Promise<DataRaw>
    inflate: (dataRaw: DataRaw) => Promise<DataInflated>
    validate: (dataInflated: DataInflated) => Promise<DataValidated>
}

export interface DbSpec {
    collections: {[datasetId: string]: CollectionAdapter}
}

export const loadSingleCollection = async (dbSpec: DbSpec, collectionId: CollectionId): Promise<DataValidated> => {
    const collectionAdapter = dbSpec.collections[collectionId]
    if (!collectionAdapter) {
        throw new Error(`unknown collection id ${collectionId}`)
    }
    const dataRaw = await collectionAdapter.fetch()
    const dataInflated = await collectionAdapter.inflate(dataRaw)
    return collectionAdapter.validate(dataInflated)
}

export const loadSingleCollectionAndError = async (dbSpec: DbSpec, collectionId: CollectionId): Promise<ResultWithError> => {
    try {
        const data = await loadSingleCollection(dbSpec, collectionId)
        return [null, data]
    } catch(err) {
        return [err, null]
    }
}

export const loadSeveralCollectionsAndErrors = async (dbSpec: DbSpec, collectionIds: Array<CollectionId>): Promise<MultipleResultsWithErrors> => {
    const results: MultipleResultsWithErrors = {}
    const resultsList = await Promise.all(collectionIds.map(collectionId =>
        loadSingleCollectionAndError(dbSpec, collectionId)
    ))
    resultsList.forEach((resultWithError, index) => {
        results[collectionIds[index]] = resultWithError
    })
    return results
}