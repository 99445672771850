import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { useDispatch, useSelector } from 'react-redux'
import { selectDataFiltersShowOtherCohort, selectDataFilterCohort } from '../../../store/selectors'
import { setShowOtherCohort } from '../../../store/data-filters'
import { Cohort } from '../../../models'

const Control = styled.input`
    ${theme.mixins.clickable}
`

const ControlContainer = styled.div`
    min-width: ${theme.dimensions.filtersListPadding};
`

const Label = styled.label`
    ${theme.mixins.clickable}
    color: ${theme.colors.Purple50}
`

interface ContainerProps {
    isChecked: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: row;
    ${props => !props.isChecked ? `
        :hover {
            ${Label} {
                color: ${theme.colors.White};
            }
        }
        ${Label} {
            color: ${theme.colors.Purple50};
        }
    `: `
        :hover {
            ${Label} {
                color: ${theme.colors.Purple50};
            }
        }
        ${Label} {
            color: ${theme.colors.White};
        }
    `}
`

interface Props {}

const ShowOtherCohortCheckbox: React.FunctionComponent<Props> = () => {
    const showOtherCohort = useSelector(selectDataFiltersShowOtherCohort)
    const cohort = useSelector(selectDataFilterCohort)
    const dispatch = useDispatch()
    const _onCheckboxChange = () => {
        dispatch(setShowOtherCohort(!showOtherCohort))
    }
    return (
        <Container isChecked={showOtherCohort}>
            <ControlContainer>
                <Control 
                    id="showOtherCohort"
                    type="checkbox" 
                    checked={showOtherCohort}
                    onChange={_onCheckboxChange}
                />
            </ControlContainer>
            <Label htmlFor="showOtherCohort" >
                {cohort === Cohort.GRAND_PUBLIC ? 
                "Afficher les réponses des demandeurs d’emploi":
                "Afficher les réponses de l'ensemble des Français"
                }
                
            </Label>
        </Container>
    
    )
}

export default ShowOtherCohortCheckbox