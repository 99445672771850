import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle } from '../shared'
import Graph, { ThemeKey } from './Graph'
import theme from '../../../theme'
import { CSSTransition } from 'react-transition-group'

const Container = styled.div`
    padding-bottom: ${theme.spacings.vertical1};

    >* {
        ${theme.transitions.buildPageTransition(['opacity'], 0.2, 0.2)}
    }

    .centPersonnes-appear {
        opacity: 0;
    }

    .centPersonnes-appear-active {
        opacity: 1;
    }

    *:nth-child(2) {
        transition-delay: ${theme.transitions.pageTransitionDuration * 0.4 + theme.transitions.pageTransitionDuration * 0.1}ms
    }

    *:nth-child(3) {
        transition-delay: ${theme.transitions.pageTransitionDuration * 0.4 + theme.transitions.pageTransitionDuration * 0.1 * 2}ms
    }

    *:nth-child(4) {
        transition-delay: ${theme.transitions.pageTransitionDuration * 0.4 + theme.transitions.pageTransitionDuration * 0.1 * 3}ms
    }

    *:nth-child(5) {
        transition-delay: ${theme.transitions.pageTransitionDuration * 0.4 + theme.transitions.pageTransitionDuration * 0.1 * 4}ms
    }

    *:nth-child(6) {
        transition-delay: ${theme.transitions.pageTransitionDuration * 0.4 + theme.transitions.pageTransitionDuration * 0.1 * 5}ms
    }
`

const THEMES: Array<ThemeKey> = ['red', 'yellow', 'green', 'blue']

const BORDER_BRACKET_WIDTH = '7px'

const GRAPHS_IN_BRACKET = 3

const borderBracketMixin = `
    padding-left: ${theme.spacings.horizontal05};
    border-left: solid ${BORDER_BRACKET_WIDTH} ${theme.colors.Black};
`

const VisualizationSubtitle = styled.div`
    ${borderBracketMixin}
    border-top-left-radius: ${BORDER_BRACKET_WIDTH};
    margin-top: ${theme.spacings.vertical1};
    padding-bottom: ${theme.spacings.verticalRel05};
    div:first-child {
        font-family: ${theme.fonts.title};
        font-size: 115%;
    }
    div:last-child {
        font-weight: bold;
    }
`

const Graph100PersonnesBracket = styled(Graph)`
    ${borderBracketMixin}
    padding-top: ${theme.spacings.verticalRel05};
    padding-bottom: ${theme.spacings.verticalRel05};
`

const Graph100PersonnesNoBracket = styled(Graph)`
    ${borderBracketMixin}
    border-color: transparent;
`

const Graph100PersonnesBracketLast = styled(Graph100PersonnesBracket)`
    padding-bottom: 0;
    margin-bottom: ${theme.spacings.verticalRel1};
    border-bottom-left-radius: ${BORDER_BRACKET_WIDTH};
`

const Visualization100Personnes: React.FunctionComponent<VisualizationComponentProps> = ({ question, answerValues }) => {
    const content: Array<JSX.Element> = [
        <VisualizationTitle>{question.text}</VisualizationTitle>,
        (
            <VisualizationSubtitle>
                <div>{answerValues[0]["texte-cent-personnes"]}</div>
                <div>sont concernés par le chômage de près ou de loin</div>
            </VisualizationSubtitle>
        ),
        // The first answerValue corresponds to the accolade on top of the graph
        ...answerValues.slice(1).map((answerValue, i) => {
            const value = answerValue["value-tous"]!
            const valueEvolution = answerValue["evolution-tous"]
            const answerText = answerValue["text"]
            let Component = Graph100PersonnesNoBracket
            if (i === GRAPHS_IN_BRACKET - 1) {
                Component = Graph100PersonnesBracketLast
            } else if (i < GRAPHS_IN_BRACKET) {
                Component = Graph100PersonnesBracket
            }
            return (
                <Component 
                    value={value} 
                    valueEvolution={valueEvolution}
                    answerText={answerText}
                    themeKey={THEMES[i % THEMES.length]} 
                    key={answerValue["answer-id"]}
                />
            )
        })
    ]
    return (
        <Container>
            {content.map((component, i) => (
                <CSSTransition 
                    in={true}
                    appear={true}
                    timeout={theme.transitions.pageTransitionDuration} 
                    classNames="centPersonnes"
                    key={i}
                >
                    {component}
                </CSSTransition>
            ))}
        </Container>
    )
}

export default React.memo(Visualization100Personnes)