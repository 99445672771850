import React from 'react'
import styled from 'styled-components/macro'
import { Cohort, CohortDisplay } from '../../../models'
import theme from '../../../theme'
import { Colors } from './config-vizualisation'

const CIRCLE_SIZE = 1.4

const ColorCircle = styled.span<{color: string}>`
    display: inline-block;
    width: ${CIRCLE_SIZE}em;
    height: ${CIRCLE_SIZE / 2}em;
    border-radius: ${CIRCLE_SIZE}em;
    background-color: ${props => props.color};
    margin-right: 0.2em;
    margin-left: 0.7em;
`

const LegendItem = styled.span`
    color: ${theme.colors.Grey150};
    :first-child {
        ${ColorCircle} {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    :last-child {
        ${ColorCircle} {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
`

export interface Props {
    cohort: Cohort
    cohortOther: Cohort
    className?: string
}

const Component: React.FunctionComponent<Props> = ({ 
    cohort,
    cohortOther,
    className = ''
}) => {
    return (
        <div className={className}>
            <LegendItem>
                <ColorCircle color={Colors[0]} />
                {CohortDisplay[cohort]}
            </LegendItem>
            <LegendItem>
                <ColorCircle color={Colors[1]} />
                {CohortDisplay[cohortOther]}
            </LegendItem>
        </div>
    )
}

export default styled(React.memo(Component))`
    background-color: ${theme.colors.Grey};
    font-size: 85%;
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
    border-radius: 0.4em;
    margin-top: ${theme.spacings.vertical05};
`