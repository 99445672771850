import React from 'react'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components/macro'
import {Link, useHistory} from 'react-router-dom'
import configPages, { RESULTATS_ID } from "../../config-pages"
import {ReactComponent as PreviousSvg} from '../../images/navigation sidebar/slide-prev.svg'
import {ReactComponent as NextSvg} from '../../images/navigation sidebar/slide-next.svg'
import theme from '../../theme'
import { Question } from '../../models'
import { useSelector } from 'react-redux'
import { selectQuestionsInChapter } from '../../store/selectors'
import { RootState } from '../../store'

const DOT_SIZE = 0.5
const BUTTON_NEXT_PREVIOUS_RADIUS = '2em';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* to allow absolute positioning of TopNavigationContainer */
    position: relative;
`

const TopNavigationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* to allow displaying on top of header */
    position: absolute;
    top: -1em;
    width: 100%;
    z-index: ${theme.zIndexes.justAboveHeader};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: none;
    }
`

const BottomContainer = styled.div<{hasNextPage: boolean, hasPreviousPage: boolean}>`
    flex: 1;
    display: flex;
    flex-direction: row;
    min-height: 0;

    ${props => !props.hasNextPage ? `
        ${ButtonNextPage} {
            pointer-events: none;
            svg {
                background-color: ${theme.colors.PurpleDisabled};
            }
        }
    `: ``}

    ${props => !props.hasPreviousPage ? `
        ${ButtonPreviousPage} {
            pointer-events: none;
            svg {
                background-color: ${theme.colors.PurpleDisabled};
            }
        }
    `: ``}
`

export const ButtonNextPreviousBase = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
        background-color: ${theme.colors.Purple150};
        path {
            fill: ${theme.colors.White};
        }
    }

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: none;
    }

    svg {
        transition: transform ${theme.transitions.duration1};
        transform: scale(1.0);    
    }
`

const ButtonPreviousPage = styled(ButtonNextPreviousBase)`
    padding-right: ${theme.spacings.horizontal05};
    border-left: 1px solid ${theme.colors.White};
    svg {
        border-top-right-radius: ${BUTTON_NEXT_PREVIOUS_RADIUS};
        border-bottom-right-radius: ${BUTTON_NEXT_PREVIOUS_RADIUS};
        :hover {
            transform: scale(1.05) translateX(3%);
        }
    }
`

const ButtonNextPage = styled(ButtonNextPreviousBase)`
    padding-left: ${theme.spacings.horizontal05};
    svg {
        border-top-left-radius: ${BUTTON_NEXT_PREVIOUS_RADIUS};
        border-bottom-left-radius: ${BUTTON_NEXT_PREVIOUS_RADIUS};
        :hover {
            transform: scale(1.05) translateX(-3%);
        }
    }
`

export const VizualizationContainer = styled.div`
    flex: 1;
    padding-top: 3em;
    overflow-y: auto;
    /* to avoid scrollbars when sliding transitions of graphs */
    max-width: 100%;
    overflow-x: hidden;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        padding-top: ${theme.spacings.vertical05};
        /* To leave space for navigation buttons */
        padding-bottom: calc(${theme.dimensions.mobilePreviousNextButtonsSize} * 1.5);
    }
`

export const ChapterTitle = styled.div`
    background-color: ${theme.colors.Purple150};
    color: ${theme.colors.White};
    border-radius: 1em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0.2em 1em;
    font-size: 95%;
`

export const ChapterQuestionDots = styled.div`
    display: flex;
    flex-direction: row;
    background-color: ${theme.colors.Grey};
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
    padding: 0.5em;

    a {
        margin-right: 0.3em;
        :last-child {
            margin-right: 0;
        }
    }
`

interface ChapterQuestionDotProps {
    isActive: boolean
}

const ChapterQuestionDot = styled.div<ChapterQuestionDotProps>`
    height: ${DOT_SIZE}em;
    width: ${DOT_SIZE}em;
    border-radius: ${DOT_SIZE / 2}em;
    ${props => props.isActive ? `
        background-color: ${theme.colors.Purple150};
    `: `
        background-color: ${theme.colors.Purple50};
    `}

    &:hover {
        background-color: ${theme.colors.Purple100};
    }
`


interface Props {
    nextQuestionId: string | null
    previousQuestionId: string | null
    question: Question
}

const NavigationContainer: React.FunctionComponent<Props> = ({ nextQuestionId, question, previousQuestionId, children }) => {
    const previousPageUrl = configPages[RESULTATS_ID].href(previousQuestionId)
    const nextPageUrl = configPages[RESULTATS_ID].href(nextQuestionId)
    const history = useHistory()
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (nextQuestionId) {
                history.push(nextPageUrl)
            }
        },
        onSwipedRight: () => {
            if (previousQuestionId) {
                history.push(previousPageUrl)
            }
        }
    })
    const questionsInChapter = useSelector((state: RootState) => selectQuestionsInChapter(state, question.chapter))
    const dotElems = questionsInChapter.map(
        otherQuestion => (
            <Link to={configPages[RESULTATS_ID].href(otherQuestion.id)} key={otherQuestion.id}>
                <ChapterQuestionDot isActive={otherQuestion.id === question.id}>
                </ChapterQuestionDot>
            </Link>
        )
    )
    return (
        <Container {...swipeHandlers}>
            <TopNavigationContainer>
                <ChapterTitle><span>{question.chapter}</span></ChapterTitle>
                <ChapterQuestionDots>
                    {dotElems}
                </ChapterQuestionDots>
            </TopNavigationContainer>
            <BottomContainer hasNextPage={nextQuestionId !== null} hasPreviousPage={previousQuestionId !== null}>
                <ButtonPreviousPage to={previousPageUrl}>
                    <PreviousSvg />
                </ButtonPreviousPage>
                <VizualizationContainer>
                    {children}
                </VizualizationContainer>
                <ButtonNextPage to={nextPageUrl}>
                    <NextSvg />
                </ButtonNextPage>
            </BottomContainer>
        </Container>
    )
}

export default NavigationContainer