import { Question, Answer, Wave, RequestStatus, KeyNumber, Video } from '../models'

export const GSHEET_DATA_LOAD = "GSHEET_DATA_LOAD"
export const GSHEET_DATA_LOAD_SUCCESS = "GSHEET_DATA_LOAD_SUCCESS"
export const GSHEET_DATA_LOAD_ERROR = "GSHEET_DATA_LOAD_ERROR"

export interface GsheetData {
    questions: Array<Question>
    answers: Array<Answer>
    waves: Array<Wave>
    keyNumbers: Array<KeyNumber>
    videos: Array<Video>
}

export type GsheetErrors = Array<[string, Error]>

interface GsheetDataLoad {
    type: typeof GSHEET_DATA_LOAD
}

interface GsheetDataLoadSuccess {
    type: typeof GSHEET_DATA_LOAD_SUCCESS
    payload: GsheetData
}

interface GsheetDataLoadError {
    type: typeof GSHEET_DATA_LOAD_ERROR,
    payload: GsheetErrors
}

export type GsheetDataTypes = GsheetDataLoad | GsheetDataLoadSuccess | GsheetDataLoadError

export const gsheetDataLoad = (): GsheetDataTypes => {
    return {
        type: GSHEET_DATA_LOAD,
    }
}

export const gsheetDataLoadSuccess = (gsheetData: GsheetData): GsheetDataTypes => {
    return {
        type: GSHEET_DATA_LOAD_SUCCESS,
        payload: gsheetData
    }
}

export const gsheetDataLoadError = (errors: GsheetErrors): GsheetDataTypes => {
    return {
        type: GSHEET_DATA_LOAD_ERROR,
        payload: errors,
    }
}

export interface GsheetDataState {
    gsheetData: GsheetData | null,
    status: RequestStatus,
    errors: GsheetErrors
}

const initialState: GsheetDataState = {
    gsheetData: null,
    status: RequestStatus.INIT,
    errors: []
}

export function gsheetDataReducer(
    state = initialState,
    action: GsheetDataTypes
): GsheetDataState {
    switch (action.type) {
        case GSHEET_DATA_LOAD:
            return {
                ...state,
                status: RequestStatus.PROGRESS,
                gsheetData: null,
                errors: []
            }
        case GSHEET_DATA_LOAD_SUCCESS:
            return {
                ...state,
                status: RequestStatus.DONE,
                gsheetData: action.payload,
            }
        case GSHEET_DATA_LOAD_ERROR:
            return {
                ...state,
                status: RequestStatus.DONE,
                errors: action.payload
            }
        default:
            return state
    }
}
