import styled from 'styled-components/macro'
import theme from '../theme'

export default styled.div<{ isScrollable: boolean }>`
    @media(max-width: ${theme.dimensions.thresholdMobile}px) {
        padding: 0 ${theme.spacings.horizontal05};
    }

    @media(min-width: ${theme.dimensions.thresholdMobile}px) {
        ${props => !props.isScrollable ? `
            flex: 1;
            min-height: 0;
        `: ``}
    }
`