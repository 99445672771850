import React from 'react'
import styled from 'styled-components/macro'
import config from '../config'

export const Container = styled.div``

interface Props {
    width: number,
    youtubeId: string,
}

const Video: React.FunctionComponent<Props> = ({ width, youtubeId }) => {
    const height = width / config.VIDEO_ASPECT_RATIO
    return (
        <Container>
            <iframe 
                width={width} height={height}
                src={`https://www.youtube.com/embed/${youtubeId}`}
                frameBorder="0"
                title={`Video ${youtubeId}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            ></iframe>
        </Container>
    )
}

export default Video