import React from 'react'
import styled from 'styled-components/macro'
import isEqual from 'lodash.isequal'
import { useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import pageConfig, {RESULTATS_ID} from '../../../config-pages'
import theme from '../../../theme'
import { Question } from '../../../models'
import { wrapText } from '../../../utils/strings'
import { ReactComponent as NextSvg } from '../../../images/navigation sidebar/slide-next.svg'
import { selectChapters } from '../../../store/selectors'

const ChaptersList = styled.ul``

interface ChapterItemProps {
    isActive: boolean
}

const ChapterText = styled(Link)`
    ${theme.mixins.clickable};
`

const ChapterItem = styled.li<ChapterItemProps>`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacings.verticalRel05};

    ${ChapterText} {
        color: ${theme.colors.Purple50};
        :hover {
            color: ${theme.colors.White};
        }
        ${props => props.isActive ? `
            color: ${theme.colors.White};
            font-weight: bold;
        `: ``}
    }

    svg {
        position: relative;
        bottom: 0.8em;
        right: 0.8em;
        height: 3.2em;
    }
    ${props => props.isActive ? `
        svg rect, svg path {
            fill: ${theme.colors.White};
        }
    `: `
        svg {
            visibility: hidden;
        }
    `}
`

const ChapterArrow = styled.div`
    width: ${theme.dimensions.filtersListPadding};
    height: 1em;
    overflow: visible;
`
export interface Props {
    question: Question
}

const Chapters: React.FunctionComponent<Props> = ({ 
    question
}) => {
    const chapters = useSelector(selectChapters, isEqual)
    const activeChapter = question.chapter
    
    const chapterElems = chapters.map(([chapter, questionId]) => {
        const chapterLines = wrapText(chapter, 2)
        return (
            <ChapterItem 
                key={chapter} 
                isActive={activeChapter === chapter}
            >
                <ChapterArrow>
                    <NextSvg />
                </ChapterArrow>
                <ChapterText to={pageConfig[RESULTATS_ID].href(questionId)}>
                    {chapterLines.map((line, i) => <div key={i}>{line}</div>)}
                </ChapterText>
            </ChapterItem>
        )
    })

    return (
        <ChaptersList>
            {chapterElems}
        </ChaptersList>
    )
}

export default Chapters