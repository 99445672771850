import React from 'react'
import styled from 'styled-components/macro'
import { Question, CombinedAnswer, Cohort } from "../../models";
import theme from '../../theme';
import arrowDownSrc, { ReactComponent as ArrowDownSvg } from '../../images/charts/evol-arrow-down.svg'
import arrowUpSrc, { ReactComponent as ArrowUpSvg } from '../../images/charts/evol-arrow-up.svg'
import { numberDisplay } from '../../utils/strings';

const EVOLUTION_BG_COLORS = {
    'up': '#25820d',
    'down': '#e20613',
    'stable': '#515154',
}

const EVOLUTION_ARROWS_SVG = {
    'up': ArrowUpSvg,
    'down': ArrowDownSvg,
}

const EVOLUTION_ARROWS_SRC = {
    'up': arrowUpSrc,
    'down': arrowDownSrc,
}

type EvolutionArrowKey = 'up' | 'down' | 'stable'

export interface VisualizationComponentProps {
    question: Question
    answerValues: Array<CombinedAnswer>
    width: number
    height: number
    cohort: Cohort
    cohortOther: Cohort | null
}

export const VisualizationTitle = styled.h1`
    font-family: ${theme.fonts.subtitle};
    font-size: 150%;
    margin-bottom: ${theme.spacings.vertical05};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 180%;
    }
`

interface EvolutionProps {
    value: number
    className?: string
    scale?: number
}

interface EvolutionHtmlProps extends Omit<EvolutionProps, 'value'> {
    label?: string
    arrow?: EvolutionArrowKey
    value?: number
}

const valueToEvolutionArrowKey = (value: number): EvolutionArrowKey => {
    if (value === 0) {
        return 'stable'
    } else if (value > 0) {
        return 'up'
    } else {
        return 'down'
    }
}

const EvolutionHtmlUnstyled: React.FunctionComponent<EvolutionHtmlProps> = ({ value, arrow, label, className, scale = 1 }) => {
    if (value !== undefined) {
        arrow = valueToEvolutionArrowKey(value)
        label = `${numberDisplay(Math.abs(value))}`
    }

    let content = <span>{'='}</span>
    if (arrow !== 'stable') {
        const EvolutionSvg = EVOLUTION_ARROWS_SVG[arrow!]
        content = <><EvolutionSvg /> <span>{label}</span></>
    }

    return (
        <span className={className}>
            {content}
        </span>
    )
}

const EVOLUTION_FONT_FAMILY = theme.fonts.title

export const EvolutionHtml = styled(EvolutionHtmlUnstyled)`
    white-space: nowrap;
    min-width: 2.5em;
    display: inline-block;
    text-align: center;
    font-size: ${props => (props.scale || 1) * 100}%;
    font-family: ${EVOLUTION_FONT_FAMILY};
    color: ${theme.colors.White};
    padding: 0.05em 0.5em;
    background-color: ${props => props.value ? EVOLUTION_BG_COLORS[valueToEvolutionArrowKey(props.value)] : EVOLUTION_BG_COLORS.stable};
    border-radius: ${theme.dimensions.borderRadius1};
    svg {
        position: relative;
        bottom: 0.05em;
    }
`

const EvolutionSvgText = styled.text`
    fill: ${theme.colors.White};
    font-family: ${EVOLUTION_FONT_FAMILY};
`

export const EvolutionSvg: React.FunctionComponent<EvolutionProps> = ({ value, scale = 1 }) => {
    const width = 110 * scale
    const height = 50 * scale
    const x = -width / 2
    const y = -height / 2
    const fontSize = height * 0.8
    const radius = height / 2

    const arrow = valueToEvolutionArrowKey(value)
    const textX = 0
    const textY = height * 0.3
    let textAnchor = 'middle'
    let textContent = '='
    let imageElem: JSX.Element | null = null
    if (arrow !== 'stable') {
        textAnchor = 'initial'
        const imageSrc = EVOLUTION_ARROWS_SRC[arrow]
        textContent = numberDisplay(Math.abs(value))
        const imgHeight = height / 2
        const imgWidth = width / 2
        const imgX = - 2.5 * width / 5
        const imgY = -imgHeight / 2
        imageElem = <image
            href={imageSrc}
            xlinkHref={imageSrc}
            width={imgWidth}
            height={imgHeight}
            x={imgX}
            y={imgY}
        />
    }

    return (
        <g>
            <rect
                x={x} y={y}
                rx={radius} ry={radius}
                width={width} height={height}
                fill={EVOLUTION_BG_COLORS[arrow]}
            />
            {imageElem}
            <EvolutionSvgText
                fontSize={fontSize}
                x={textX}
                y={textY}
                textAnchor={textAnchor}
            >
                {textContent}
            </EvolutionSvgText>
        </g>
    )
}