import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { CombinedAnswer, Cohort } from '../../../models'
import { Themes, ThemeGroups } from './Themes'
import Bar, {CohortText} from './Bar'

const ICON_SIZE = '2.5rem'
const TITLE_HEIGHT = '2.7em' // Corresponds with answers containing 2 lines 

type ValueTriplet = [number, number, number]

export interface Props {
    className?: string
    answerValues: [CombinedAnswer, CombinedAnswer, CombinedAnswer]
    group: ThemeGroups
    cohort: Cohort
    cohortOther: Cohort | null
}

export const Icon = styled.img`
    height: ${ICON_SIZE};
    position: relative;
    bottom: 0.22em;
`

export const AnswerTitles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 ${ICON_SIZE};
    height: ${TITLE_HEIGHT};
    padding-bottom: 0.3em;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 110%;
        padding: 0;
    }
`

const AnswerTitle = styled.div`
    max-width: 30%;
    :last-child{
        text-align: right;
    }
`

const CentralContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const Bars = styled.div`
    flex: 1;
`

const StyledBar = styled(Bar)`
    ${CohortText} {
        margin-bottom: 0.5em;
    }
`

const Graph: React.FunctionComponent<Props> = ({ 
    answerValues,
    group,
    cohort,
    cohortOther,
    className = '',
}) => {
    const values = answerValues.map(answerValue => answerValue.value)
    const evolutions = answerValues.map(answerValue => answerValue.evolution)
    const valuesOther = cohortOther ? answerValues.map(answerValue => answerValue["value-other"]) : null
    const evolutionsOther = cohortOther ? answerValues.map(answerValue => answerValue["evolution-other"]) : null
    return (
        <div className={className}>
            <AnswerTitles>
                <AnswerTitle>{answerValues[0].text}</AnswerTitle>
                <AnswerTitle>{answerValues[2].text}</AnswerTitle>
            </AnswerTitles>
            <CentralContainer>
                <Icon src={Themes[group].images[0]} alt="" />
                <Bars>
                    <StyledBar 
                        values={values as ValueTriplet} 
                        evolutions={evolutions as ValueTriplet}
                        // Show cohort only if other is there
                        cohort={cohortOther ? cohort : null}
                        colors={Themes[group].colors}
                    />
                    {cohortOther ?
                        <StyledBar 
                            values={valuesOther as ValueTriplet} 
                            evolutions={evolutionsOther as ValueTriplet}
                            // Show cohort only if other is there
                            cohort={cohortOther}
                            colors={Themes[group].colorsOther}
                        />: null
                    }
                </Bars>
                <Icon src={Themes[group].images[1]} alt="" />
            </CentralContainer>
        </div>
    )
}

export default styled(React.memo(Graph))`
    padding-bottom: ${theme.spacings.vertical05};
`