import React, { useState, useEffect, createRef } from 'react'
import styled from 'styled-components/macro'
import * as d3Shape from 'd3-shape'
import happySrc from '../../../images/charts/icon-happy.svg'
import unhappySrc from '../../../images/charts/icon-unhappy.svg'
import Tooltip from './Tooltip'
import { Cohort } from '../../../models'

type Position = [number, number]

const VALUE_MAX = 10
const HEIGHT_RATIO = 1/3
const VIEWPORT_WIDTH = 1000
const VIEWPORT_HEIGHT = VIEWPORT_WIDTH * HEIGHT_RATIO
const TETA = Math.PI / 3.2
const OUTER_RADIUS = VIEWPORT_WIDTH
const INNER_RADIUS = VIEWPORT_WIDTH * (1 - HEIGHT_RATIO * 0.3)
const DOT_RADIUS = 15
const SMILEY_SIZE = (OUTER_RADIUS - INNER_RADIUS) * 0.7
const PADDING_TOP = '6em'
const PADDING_BOTTOM = '4em'

const COLORS = [
    '#E72C65',
    '#ED5355',
    '#FF9622',
    '#F9A234',
    '#FFC923',
    '#E3CA2C',
    '#C7CB35',
    '#ACCB3E',
    '#90CC47',
    '#74CD50',
]

const BORDER_RADIUS = 25
const BORDER_RADIUS_ANGLE = TETA / COLORS.length * 0.3


export const Dot = styled.circle`
    stroke: white;
    stroke-width: 2px;
`

export const ArcPath = styled.path``

const tooltipAbsolutePosition = (width: number, svgPosition: Position): [string, string] => {
    return [
        `${width / 2 + width / VIEWPORT_WIDTH * svgPosition[0]}px`,
        `calc(${(OUTER_RADIUS + svgPosition[1]) * (width / VIEWPORT_WIDTH)}px + ${PADDING_TOP})`
    ]
}

const buildSmiley = (src: string, position: Position, angle: number) => {
    const offset = SMILEY_SIZE * 0.5
    const translate = `translate(${position[0] - offset} ${position[1] - offset})`
    const rotate = `rotate(${angle} ${offset} ${offset})`
    return (
        <g transform={`${translate} ${rotate}`}>
            <image 
                href={src} 
                xlinkHref={src}
                width={SMILEY_SIZE}
                height={SMILEY_SIZE}
            />
        </g>
    )
}

const buildDot = (value: number, position: Position) => {
    const color = COLORS[Math.floor(value)]
    return (
        <Dot 
            fill={color} 
            cx={position[0]}
            cy={position[1]}
            r={DOT_RADIUS} 
        />
    )
}

export const FakeRoundedRect = styled.rect``

const buildFakeRoundedCorner = (angle: number, rotate: number, color: string) => {
    const x = OUTER_RADIUS * Math.cos(angle)
    const y = OUTER_RADIUS * Math.sin(angle)
    return (
        <FakeRoundedRect 
            x={x}
            y={y} 
            height={OUTER_RADIUS - INNER_RADIUS} 
            width={BORDER_RADIUS * 2}
            rx={BORDER_RADIUS}
            transform={`rotate(${rotate * 180/Math.PI} ${x} ${y})`}
            fill={color}
        />
    )
}

const valueToPosition = (value: number, radius: number): Position => {
    const angle = -TETA / 2 + value / VALUE_MAX * TETA
    const x = radius * Math.sin(angle)
    const y = -radius * Math.cos(angle)
    return [x, y]
}

const buildArcPaths = () => {
    const pieGenerator = d3Shape.pie<number>()
        .startAngle(-TETA / 2)
        .endAngle(TETA / 2)
        .sort(null)
    const arcGenerator = d3Shape.arc()
    const pie = pieGenerator([1, 1, 1, 1, 1, 1, 1, 1, 1, 1])
    return pie
        .map(({startAngle, endAngle}, i) => arcGenerator({
            startAngle: (i === 0 ? startAngle + BORDER_RADIUS_ANGLE: startAngle), 
            endAngle: (i === pie.length - 1 ? endAngle - BORDER_RADIUS_ANGLE: endAngle),
            innerRadius: INNER_RADIUS,
            outerRadius: OUTER_RADIUS,
        }))
        .map((d, i) => (
            <ArcPath d={d!} key={i} fill={COLORS[i]} />
        ))
}

export interface Props {
    className?: string
    value: number
    valueOther: number | null
    evolution: number | null
    evolutionOther: number | null
    width: number
    cohort: Cohort
    cohortOther: Cohort | null
}

const Graph: React.FunctionComponent<Props> = ({ 
    className = '',
    value,
    valueOther,
    evolution,
    evolutionOther,
    width,
    cohort,
    cohortOther
}) => {
    const [measuredWidth, setMeasuredWidth] = useState<number>(width)
    const containerRef = createRef<HTMLDivElement>()
    useEffect(() => {
        if (containerRef.current) {
            setMeasuredWidth(containerRef.current.clientWidth)
        }
    })

    const viewbox = `${-VIEWPORT_WIDTH * 0.5} ${-VIEWPORT_WIDTH - DOT_RADIUS} ${VIEWPORT_WIDTH} ${VIEWPORT_HEIGHT}`
    const arcPaths = buildArcPaths()
    const dotPosition = valueToPosition(value, OUTER_RADIUS)
    const dotValue = buildDot(value, dotPosition)
    const tooltipPosition = tooltipAbsolutePosition(measuredWidth, dotPosition)

    let dotValueOther: JSX.Element | null = null
    let tooltipOtherPosition: [string, string] | null = null
    if (valueOther) {
        const dotOtherPosition = valueToPosition(valueOther, INNER_RADIUS)
        dotValueOther = buildDot(valueOther, dotOtherPosition)
        tooltipOtherPosition = tooltipAbsolutePosition(measuredWidth, dotOtherPosition)
    }

    const positionUnhappy = valueToPosition(0.5, 
        INNER_RADIUS + (OUTER_RADIUS - INNER_RADIUS) * 0.5)
    const positionHappy = valueToPosition(9.5, 
        INNER_RADIUS + (OUTER_RADIUS - INNER_RADIUS) * 0.5)
    const smileyHappy = buildSmiley(happySrc, positionHappy, 25)
    const smileyUnhappy = buildSmiley(unhappySrc, positionUnhappy, -25)

    const fakeLeftRoundedCorner = buildFakeRoundedCorner(
        -Math.PI / 2 -TETA / 2, 
        -TETA / 2 + BORDER_RADIUS_ANGLE, 
        COLORS[0]
    )
    const fakeRightRoundedCorner = buildFakeRoundedCorner(
        -Math.PI / 2 +TETA / 2 - BORDER_RADIUS_ANGLE * 1.67, 
        TETA / 2 - BORDER_RADIUS_ANGLE, 
        COLORS[COLORS.length - 1]
    )

    return (
        <div className={className} ref={containerRef}>
            <svg 
                width="100%" 
                viewBox={viewbox}
            >
                <g>
                    {fakeLeftRoundedCorner}
                    {fakeRightRoundedCorner}
                </g>

                {/* Put them in a group to be able to use nth-child pseudo selectors */}
                <g>
                    {arcPaths}
                </g>
                {dotValue}
                {dotValueOther}
                {smileyHappy}
                {smileyUnhappy}
            </svg>
            <Tooltip 
                left={tooltipPosition[0]} 
                top={tooltipPosition[1]} 
                orientation={'top'}
                value={value}
                evolution={evolution}
                maxValue={VALUE_MAX}
                cohort={cohort}
            />
            {tooltipOtherPosition ? 
                <Tooltip 
                    left={tooltipOtherPosition[0]} 
                    top={tooltipOtherPosition[1]} 
                    orientation={'bottom'}
                    value={valueOther!}
                    evolution={evolutionOther}
                    maxValue={VALUE_MAX}
                    cohort={cohortOther!}
                /> : null
            }
        </div>
    )
}

export default styled(React.memo(Graph))`
    position: relative;
    padding-top: ${PADDING_TOP};
    padding-bottom: ${PADDING_BOTTOM};
`