import styled from 'styled-components/macro'
import theme from '../theme'

const {exitTransition, enterOrAppearTransition} = theme.transitions.buildPageTransitionMaker('&.PageContainerHeader')

export default styled.div`
    overflow: hidden;

    @media (min-width: ${theme.dimensions.thresholdMobile}px) {
        ${enterOrAppearTransition(
            ['> *'],
            [['transform', 'translateY(-300%)', 'translateY(0)']],
            0, 0.3
        )}

        ${exitTransition(
            ['> *'],
            [['transform', 'translateY(0)', 'translateY(-1000%)']],
            0, 1
        )}
    }
`