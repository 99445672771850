import React, { useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { EvolutionHtml } from '../shared'
import { Cohort, CohortDisplay } from '../../../models'
import { Colors } from './config-visualization'

const HIDE_LABEL_THRESHOLD = 5
const BORDER_RADIUS = '0.4em'

export const Title = styled.div`
    font-family: ${theme.fonts.subtitle};
    font-size: 115%;
    color: ${theme.colors.Grey150};
    width: 6em;
    text-align: center;
    margin-bottom: ${theme.spacings.vertical05};
`

export const Segments = styled.div<{hasTitle: boolean}>`
    width: 6em;
    ${props => props.hasTitle ? `
        height: 80%;
    `: `
        height: 90%;
    `}
`

const Segment = styled.div<{color: string, value: number}>`
    background-color: ${props => props.color};
    border: 1px solid ${theme.colors.White};
    height: ${props => props.value}%;
    display: flex;
    justify-content: center;
    align-items: center;

    :first-child {
        border-top-left-radius: ${BORDER_RADIUS};
        border-top-right-radius: ${BORDER_RADIUS};
    }
    :last-child {
        border-bottom-left-radius: ${BORDER_RADIUS};
        border-bottom-right-radius: ${BORDER_RADIUS};
    }
`

const Percentage = styled.div<{color: string, bgColor: string}>`
    font-family: ${theme.fonts.title};
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
`

const StyledEvolutionHtml = styled(EvolutionHtml)`
    position: relative;
    bottom: 0.3em;
`

export const Labels = styled.div<{zIndex: number}>`
    z-index: ${props => props.zIndex};
`

export interface Props {
    cohort: Cohort | null
    values: Array<number>
    evolutions: Array<number | null>
    className?: string
}

const Graph: React.FunctionComponent<Props> = ({ 
    cohort,
    values, evolutions,
    className = '',
}) => {
    const [expandedLabelIndex, setExpandedLabelIndex] = useState<null | number>(null)

    const segments = values.map((value, i) => {
        const [bgColor, color] = Colors[i % Colors.length]

        const onMouseEnter = () => setExpandedLabelIndex(i)
        const onMouseLeave = () => setExpandedLabelIndex(null)

        return (
            <Segment 
                color={bgColor} 
                value={value} 
                key={i}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                { (value > HIDE_LABEL_THRESHOLD) || expandedLabelIndex === i ?
                    <Labels 
                        onMouseEnter={value <= HIDE_LABEL_THRESHOLD ? onMouseEnter : undefined}
                        onMouseLeave={value <= HIDE_LABEL_THRESHOLD ? onMouseLeave : undefined}
                        zIndex={expandedLabelIndex === i ? 1 : 0}
                    >
                        <Percentage color={color} bgColor={bgColor}>{value}%</Percentage>
                        {evolutions[i] !== null ? <StyledEvolutionHtml value={evolutions[i]!} scale={0.8}/> : null}
                    </Labels>
                : null}
            </Segment>
        )
    })
    return (
        <div className={className}>
            {cohort ? 
                <Title>{CohortDisplay[cohort]}</Title>: null}
            <Segments hasTitle={cohort !== null}>
                {segments}
            </Segments>
        </div>
    )
}

export default styled(React.memo(Graph))`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${theme.spacings.horizontal05};
`