import React from 'react'
import styled from 'styled-components/macro'
import Video from '../Video'
import theme from '../../theme'

const Title = styled.div`
    font-weight: bold;
    font-size: 120%;
`

const Subtitle = styled.div``

interface Props {
    title: string
    description: string
    width: number
    youtubeId: string
    className?: string
}

const VideoEntry: React.FunctionComponent<Props> = ({ title, description, width, youtubeId, className }) => {
    return (
        <div className={className}>
            <Video width={width} youtubeId={youtubeId} />
            <Title>{title}</Title>
            <Subtitle>{description}</Subtitle>
        </div>
    )
}

export default styled(React.memo(VideoEntry))`
    margin-bottom: ${theme.spacings.vertical1};
`