import React, { useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'
import FixedTop from './FixedTop'
import Menu from './Menu'
import { Container as MenuItemContainer } from './Menu/MenuItem'
import PageContainerChildren from './PageContainerChildren'

export const ScrollingContainer = styled.div`
    flex: 1;
    min-height: 0;
    overflow: auto;
`

export const scrollToPageTop = () =>
    (document as Document)
        .querySelector(`${ScrollingContainer}`)!
        .scrollTo({top: 0, behavior: 'smooth'})

const HeaderAndChildren = styled.div`
    max-width: ${theme.dimensions.maxWidth}px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    ${PageContainerChildren} {
        flex: 1;
    }
`

const {exitTransition, enterOrAppearTransition} = theme.transitions.buildPageTransitionMaker('&.PageContainer')

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${enterOrAppearTransition(
        [`${MenuItemContainer}:nth-child(1)`],
        [['opacity', '0', '1']],
        0, 0.2
    )}

    ${enterOrAppearTransition(
        [`${MenuItemContainer}:nth-child(2)`],
        [['opacity', '0', '1']],
        0.1, 0.2
    )}
    
    ${enterOrAppearTransition(
        [`${MenuItemContainer}:nth-child(3)`],
        [['opacity', '0', '1']],
        0.2, 0.2
    )}

    ${enterOrAppearTransition(
        [`${MenuItemContainer}:nth-child(4)`],
        [['opacity', '0', '1']],
        0.3, 0.2
    )}

    ${exitTransition(
        [`${MenuItemContainer}`],
        [['opacity', '1', '0']],
        0.5, 0.5
    )}

    &.PageContainer-enter-active {
        /* Avoid scroll bars during transition */
        ${HeaderAndChildren} {
            overflow: hidden;
        }
    }
`

export interface Props {
    header: JSX.Element
    footer?: JSX.Element
    className?: string
}

const PageContainer: React.FunctionComponent<Props> = ({ 
    children, header, footer, className = '',
}) => {
    const [isAtTop, setIsAtTop] = useState(true)
    const _onScroll = (event: React.SyntheticEvent) => {
        setIsAtTop(false)
        if (event.currentTarget.scrollTop === 0) {
            setIsAtTop(true)
        }
    }
    return (
        <Container className={className}>
            <ScrollingContainer onScroll={_onScroll}>
                <HeaderAndChildren>
                    <FixedTop isAtTop={isAtTop}>
                        <Menu />
                        {header}
                    </FixedTop>
                    {children}
                </HeaderAndChildren>
            </ScrollingContainer>
            {footer ? footer: null}
        </Container>
    )
}

export default PageContainer