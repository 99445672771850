import React, { useEffect, useState } from 'react'
import isEqual from 'lodash.isequal'
import styled from 'styled-components/macro'
import { RESULTATS_ID } from '../../config-pages'
import theme from '../../theme'
import FiltersDesktop, {Container as FiltersDesktopContainer, Props as FiltersDesktopProps} from './FiltersDesktop/FiltersDesktop'
import { useSelector, useDispatch } from 'react-redux'
import { selectQuestionPreviousAndNext, selectAnswerValuesToQuestion, selectWindowDimensions, selectDataFilterCohort, selectDataFiltersOtherCohort, selectDataFiltersWave, selectDataFiltersWaveCompare } from '../../store/selectors'
import { RootState } from '../../store'
import { QuestionType, Question } from '../../models'
import NavigationContainer, { ChapterQuestionDots, ChapterTitle, VizualizationContainer, ButtonNextPreviousBase } from './NavigationContainer'
import { VisualizationComponentProps } from './shared'
import Visualization100Personnes from './Visualization100Personnes'
import VisualizationChoixUnique from './VisualizationChoixUnique'
import { setCurrentPage } from '../../store/page-transitions'
import VisualizationChoixMultiples from './VisualizationChoixMultiples'
import VisualizationChoixBinaire from './VisualizationChoixBinaire'
import VisualizationEchelle0A10 from './VisualizationEchelle0A10'
import VisualizationEstimation from './VisualizationEstimation'
import VisualizationReponsesMirroir from './VisualizationReponsesMirroir'
import VisualizationNuageDeMots from './VisualizationNuageDeMots'
import PageContainerChildren from '../PageContainerChildren'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { useCurrentQuestionId } from '../../hooks'
import { scrollToPageTop } from '../PageContainer'

type Direction = 'left' | 'right' | null

const questionIdToIndex = (questionId: string) =>
    parseInt(questionId.slice(1), 10)

const Container = styled.div`
    border: solid 1px ${theme.colors.Grey100};
    border-radius: ${theme.dimensions.borderRadius1};
    height: 100%;
    display: flex;
    flex-direction: row;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        flex-direction: column;
        border: none;
        ${FiltersDesktopContainer} {
            display: none;
        }
    }
`

const { exitTransition } = theme.transitions.buildPageTransitionMaker('.VisualizationComponent')
const {enterOrAppearTransition: enterOrAppearTransitionPage, exitTransition: exitTransitionPage} = theme.transitions.buildPageTransitionMaker('&.PageResultatsDetailles')

const StyledPageContainerChildren = styled(PageContainerChildren)<{direction: Direction}>`
    ${enterOrAppearTransitionPage(null, [['max-height', '5em', '100%']], 0, 0.4)}

    ${enterOrAppearTransitionPage(
        [`${ChapterQuestionDots} > *`, `${ChapterTitle} > *`, `${FiltersDesktopContainer} > *`, `${ButtonNextPreviousBase}`],
        [['opacity', '0', '1']], 0, 0.5)}

    ${exitTransitionPage(
        null,
        [['opacity', '1', '0']], 0, 0.5
    )}

    /* To avoid undesired scrollbars during transition */
    &.PageResultatsDetailles-enter-active {
        ${VizualizationContainer}, ${FiltersDesktopContainer} {
            overflow: hidden;
        }
    }

    ${props => {
        switch(props.direction) {
            case 'left':
                return `
                    ${exitTransition(null, 
                        [['transform', 'translateX(0)', 'translateX(-100%)']], 0, 0.5)
                    }
                `
            case 'right':
                return `
                    ${exitTransition(null, 
                        [['transform', 'translateX(0)', 'translateX(100%)']], 0, 0.5)
                    }
                `
            default:
                return `
                    ${exitTransition(null, 
                        [['opacity', '1', '0']], 0, 0.5)
                    }
                `
        }
    }}
`

const getVisualizationComponent = (question: Question): React.FunctionComponent<VisualizationComponentProps> => {
    switch (question.type) {
        case QuestionType.CENT_PERSONNES:
            return Visualization100Personnes
        case QuestionType.CHOIX_UNIQUE:
            return VisualizationChoixUnique
        case QuestionType.CHOIX_MULTIPLES:
            return VisualizationChoixMultiples
        case QuestionType.CHOIX_BINAIRE:
            return VisualizationChoixBinaire
        case QuestionType.ECHELLE_0_10:
            return VisualizationEchelle0A10
        case QuestionType.ESTIMATION:
            return VisualizationEstimation
        case QuestionType.NUAGE_MOTS:
            return VisualizationNuageDeMots
        case QuestionType.REPONSES_MIRROIR:
            return VisualizationReponsesMirroir
    }
}

const getFiltersProps = (question: Question): FiltersDesktopProps => {
    return {
        question,
        showOtherCohortCheckbox: [QuestionType.CENT_PERSONNES, QuestionType.REPONSES_MIRROIR].includes(question.type) ? false : true
    }
}

const PageResultatsDetailles: React.FunctionComponent = () => {
    const dispatch = useDispatch()
    
    const questionId = useCurrentQuestionId()
    const [previousQuestion, question, nextQuestion] = useSelector(
        (state: RootState) => selectQuestionPreviousAndNext(state, questionId), isEqual)
    const previousQuestionId = previousQuestion ? previousQuestion.id: null
    const nextQuestionId = nextQuestion ? nextQuestion.id: null
    const answerValues = useSelector((state: RootState) => selectAnswerValuesToQuestion(state, question), isEqual)    

    const { width, height } = useSelector(selectWindowDimensions)
    const isMobile = width <= theme.dimensions.thresholdMobile
    const visualizationWidth = Math.min(width, theme.dimensions.maxWidth) * (isMobile ? 0.9 : 0.6)
    
    const cohort = useSelector(selectDataFilterCohort)
    const cohortOther = useSelector(selectDataFiltersOtherCohort)
    const wave = useSelector(selectDataFiltersWave)
    const waveCompare = useSelector(selectDataFiltersWaveCompare)

    // When we detect a new questionId, we calculate the direction of the next animation and cache it.
    const [[previouslyVisited, directionAnimation], setPreviouslyVisited] = useState<[string | null, Direction]>([null, null])
    // We generate a transition key that changes everytime some filter changes for the visualization.
    // this will trigger the transition group to unmount the visualization, and mount a new visualization 
    // with the correct filters.
    const transitionKey = `${question.id}-${cohort}-${cohortOther}-${wave}-${waveCompare}`
    useEffect(() => { 
        let directionAnimationCache: Direction = null
        if (previouslyVisited !== null) {
            const currentIndex = questionIdToIndex(questionId)
            const previousIndex = questionIdToIndex(previouslyVisited)
            if ( currentIndex === previousIndex ) {
                directionAnimationCache = null
            } else if (currentIndex > previousIndex) {
                directionAnimationCache = 'left'
            } else {
                directionAnimationCache = 'right'
            }
        }
        setPreviouslyVisited([questionId, directionAnimationCache])
    }, [questionId, transitionKey])

    useEffect(() => {
        dispatch(setCurrentPage(RESULTATS_ID))
    }, [dispatch])

    const VisualizationComponent = getVisualizationComponent(question)

    return (
        <StyledPageContainerChildren isScrollable={false} direction={directionAnimation}>
            <Container>
                <FiltersDesktop {...getFiltersProps(question)} />
                <NavigationContainer 
                    previousQuestionId={previousQuestionId}
                    question={question}
                    nextQuestionId={nextQuestionId}
                >
                    <SwitchTransition>
                        <CSSTransition
                            key={transitionKey}
                            timeout={{
                                enter: theme.transitions.pageTransitionDuration,
                                exit: theme.transitions.pageExitTransitionDuration,
                            }}
                            classNames="VisualizationComponent"
                            onEnter={scrollToPageTop}
                            unmountOnExit
                            appear
                        >
                            <VisualizationComponent
                                question={question}
                                answerValues={answerValues}
                                width={visualizationWidth}
                                height={height}
                                cohort={cohort}
                                cohortOther={cohortOther}
                            />
                        </CSSTransition>
                    </SwitchTransition>
                </NavigationContainer>
            </Container>
        </StyledPageContainerChildren>
    )
}

export default React.memo(PageResultatsDetailles)