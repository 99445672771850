import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { EvolutionHtml } from '../shared'
import { Cohort, CohortDisplay } from '../../../models'
import { numberDisplay } from '../../../utils/strings'

const NOSE_SIZE = 1

type Orientation = 'top' | 'bottom'

interface ContainerProps {
    left: string
    top: string
    orientation: Orientation
}

export const Container = styled.div<ContainerProps>`
    position: absolute;
    left: ${props => props.left};
    top: ${props => props.top};
    ${props => props.orientation === 'top' ? `
        transform: translate(-${NOSE_SIZE * 1.5}em, -100%);
    `: `
        transform: translate(-${NOSE_SIZE * 1.5}em, 1em);
    `}
`

const InnerContainer = styled.div`
    background-color: ${theme.colors.White};
    box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.4);
    padding: ${theme.spacings.vertical05};
    border-radius: 0.5em;
`

const Title = styled.div``

const Score = styled.span`
    font-size: 150%;
    font-family: ${theme.fonts.title};
`

const MaxScore = styled.span`
    font-family: ${theme.fonts.title};
    margin-right: 0.3em;
`

const Nose = styled.div<{orientation: Orientation}>`
    width: ${NOSE_SIZE}em;
    height: ${NOSE_SIZE}em;
    background-color: ${theme.colors.White};
    ${props => props.orientation === 'top' ? `
        transform: translate(${NOSE_SIZE}em, -${NOSE_SIZE / 2}em) rotate(45deg);
        box-shadow: 3px 3px 2px 1px rgba(0,0,0,0.15);
    `: `
        transform: translate(${NOSE_SIZE}em, ${NOSE_SIZE / 2}em) rotate(45deg);
        box-shadow: -3px -3px 2px 1px rgba(0,0,0,0.15);
    `}
`

const StyledEvolutionHtml = styled(EvolutionHtml)`
    position: relative;
    bottom: 0.1em
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
    left: string
    top: string
    orientation: Orientation
    cohort: Cohort
    value: number
    maxValue: number
    evolution: number | null
}

const Tooltip: React.FunctionComponent<Props> = ({ 
    className = '',
    left, top, orientation, cohort, evolution, value, maxValue
}) => {
    return (
        <Container 
            className={className} 
            left={left} 
            top={top} 
            orientation={orientation}
        >
            {orientation === 'bottom' ? <Nose orientation={orientation} />: null}
            <InnerContainer>
                <Title>{CohortDisplay[cohort]}</Title>
                <div>
                    <Score>{numberDisplay(value)}</Score><MaxScore>/{maxValue}</MaxScore>
                    {evolution !== null ? <StyledEvolutionHtml value={evolution} /> : null}
                </div>
            </InnerContainer>
            {orientation === 'top' ? <Nose orientation={orientation} />: null}
        </Container>
    )
}

export default React.memo(Tooltip)