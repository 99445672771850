import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import configPages, { RESULTATS_ID } from "../../../config-pages"
import FiltersMobile from './FiltersMobile'
import {ReactComponent as PreviousSvg} from '../../../images/navigation sidebar/slide-prev.svg'
import {ReactComponent as NextSvg} from '../../../images/navigation sidebar/slide-next.svg'
import theme from '../../../theme'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { selectQuestionPreviousAndNext } from '../../../store/selectors'
import isEqual from 'lodash.isequal'
import { useCurrentQuestionId } from '../../../hooks'

export const Container = styled.div`
    display: none;
    /* to position the next / previous page buttons absolute */
    position: relative;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: block;
    }
`

const nextPreviousDisabledMixin = `
    pointer-events: none;
    background-color: ${theme.colors.PurpleDisabled};
`

const NextPreviousContainer = styled.div<{hasNext: boolean, hasPrevious: boolean}>`
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
    text-align: center;

    ${props => !props.hasNext ? `
        ${ButtonNextPrevious}:last-child {
            ${nextPreviousDisabledMixin}
        }
    `: ``}

    ${props => !props.hasPrevious ? `
        ${ButtonNextPrevious}:first-child {
            ${nextPreviousDisabledMixin}
        }
    `: ``}
`

const ButtonNextPrevious = styled(Link)`
    display: inline-block;
    background-color: ${theme.colors.Purple150};
    border-radius: ${theme.dimensions.mobilePreviousNextButtonsSize};
    width: ${theme.dimensions.mobilePreviousNextButtonsSize};
    height: ${theme.dimensions.mobilePreviousNextButtonsSize};
    border: 2px solid ${theme.colors.White};
    svg {
        width: ${theme.dimensions.mobilePreviousNextButtonsSize};
        height: ${theme.dimensions.mobilePreviousNextButtonsSize};
        position: relative;
        bottom: 0.15em;
    }
    :first-child {
        margin-right: 1em;
        svg {
            transform: scale(1.5) translateX(2px);
        }
    }
    :last-child {
        margin-left: 1em;
        svg {
            right: 0.1em;
            transform: scale(1.5) translateX(-2px);
        }
    }

    svg {
        
        * {
            fill: ${theme.colors.White};
        }
    } 
`

export interface Props {
    className?: string
}

const FooterMobile: React.FunctionComponent<Props> = ({ 
    className = ''
}) => {
    const questionId = useCurrentQuestionId()
    const [previousQuestion,, nextQuestion] = useSelector(
        (state: RootState) => selectQuestionPreviousAndNext(state, questionId), isEqual)
    const previousQuestionId = previousQuestion ? previousQuestion.id: null
    const nextQuestionId = nextQuestion ? nextQuestion.id: null
    return (
        <Container className={className}>
            <NextPreviousContainer 
                hasPrevious={previousQuestionId !== null} 
                hasNext={nextQuestionId !== null}
            >
                <ButtonNextPrevious to={configPages[RESULTATS_ID].href(previousQuestionId)}>
                    <PreviousSvg />
                </ButtonNextPrevious>
                <ButtonNextPrevious to={configPages[RESULTATS_ID].href(nextQuestionId)}>
                    <NextSvg />
                </ButtonNextPrevious>
            </NextPreviousContainer>
            <FiltersMobile />
        </Container>
    )
}

export default FooterMobile