import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'

interface LegendColorProps {
    color: string
}

const LegendColor = styled.span<LegendColorProps>`
    display: inline-block;
    background-color: ${props => props.color};
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.3em;
`

export interface Props {
    text: string
    color: string
    className?: string
}

const LegendEntry: React.FunctionComponent<Props> = ({ 
    text, color,
    className = ''
}) => {
    return (
        <div className={className}>
            <LegendColor color={color} />
            {text}
        </div>
    )
}

export default styled(React.memo(LegendEntry))`
    margin-bottom: ${theme.spacings.vertical05};
`