import { PageId } from "../config-pages"

export const PAGE_TRANSITIONS_SET_CURRENT_PAGE = "PAGE_TRANSITIONS_SET_CURRENT_PAGE"

interface SetCurrentPage {
    type: typeof PAGE_TRANSITIONS_SET_CURRENT_PAGE
    payload: PageId
}

export type PageTransitionsTypes = SetCurrentPage

export const setCurrentPage = (pageId: PageId): PageTransitionsTypes => {
    return {
        type: PAGE_TRANSITIONS_SET_CURRENT_PAGE,
        payload: pageId,
    }
}

export type PageTransitionsState = {
    routeRendered: boolean,
    currentPage: PageId | null,
}

const initialState: PageTransitionsState = {
    routeRendered: false,
    currentPage: null,
}

export function pageTransitionsReducer(
    state = initialState,
    action: PageTransitionsTypes
): PageTransitionsState {
    switch (action.type) {
        case PAGE_TRANSITIONS_SET_CURRENT_PAGE:
            if (state.currentPage === action.payload) {
                return state
            }
            return {
                ...state,
                routeRendered: true,
                currentPage: action.payload,
            }
        default:
            return state
    }
}
