import findIndex from 'lodash.findindex'
import round from 'lodash.round'
import { RootState } from "."
import { Question, CombinedAnswer, Cohort, QuestionType, AnswerValue } from '../models'

const ROUND_PRECISION = 2

export const selectDataFilterCohort = (state: RootState) => 
    state.dataFilters.cohort

export const selectDataFiltersOtherCohort = (state : RootState) => {
    const cohort = selectDataFilterCohort(state)
    const showOtherCohort = selectDataFiltersShowOtherCohort(state)
    if (showOtherCohort) {
        return cohort === Cohort.CHOMEURS ? Cohort.GRAND_PUBLIC : Cohort.CHOMEURS
    } else {
        return null
    }
}

export const selectGsheetDataLoadStatus = (state: RootState) => 
    state.gsheetData.status

export const selectGsheetData = (state: RootState) =>
    state.gsheetData.gsheetData

export const selectGsheetDataFailsafe = (state: RootState) => {
    if (!state.gsheetData.gsheetData) {
        throw new Error('Expected state to contain gsheetData')
    }
    return state.gsheetData.gsheetData
}

export const selectQuestions = (state: RootState) =>
    selectGsheetDataFailsafe(state).questions
        .map(question => {
            if (question.text.endsWith(' ?')) {
                question.text = question.text
                    .slice(0, -(' ?'.length)) + '\xa0?'
            }
            return question
        })

export const selectAnswers = (state: RootState) =>
    selectGsheetDataFailsafe(state).answers

export const selectWaves = (state: RootState) =>
    selectGsheetDataFailsafe(state).waves

export const selectKeyNumbers = (state: RootState) =>
    selectGsheetDataFailsafe(state).keyNumbers

export const selectVideos = (state: RootState) =>
    selectGsheetDataFailsafe(state).videos

export const selectVideoHome = (state: RootState) =>
    selectVideos(state).filter(video => video!["show-on-home"] === 'oui')[0]

export const selectFilteredKeyNumbers = (state: RootState) => {
    const keyNumbers = selectKeyNumbers(state)
    const cohort = selectDataFilterCohort(state)
    return keyNumbers.filter(keyNumber => keyNumber.cohort === cohort)
}

export const selectGsheetDataErrors = (state: RootState) => 
    state.gsheetData.errors

export const selectLatestWave = (state: RootState) => 
    selectGsheetDataFailsafe(state).waves.slice(-1)[0]!

export const selectQuestionPreviousAndNext = (state: RootState, questionId: string): [Question | null, Question, Question | null] => {
    const questions = selectQuestions(state)
    let questionIndex = findIndex(
        questions, 
        (question) => question.id === questionId
    )
    if (questionIndex === -1) {
        questionIndex = 0
    }
    const previousQuestion = questions[questionIndex - 1]
    const question = questions[questionIndex]
    const nextQuestion = questions[questionIndex + 1]
    return [previousQuestion || null, question, nextQuestion || null]
}

export const selectAnswersToQuestion = (state: RootState, question: Question) => {
    const answers = selectAnswers(state)
    return answers.filter(answer => answer["question-id"] === question.id)
}

export const selectAnswerValuesToQuestion = (
    state: RootState, 
    question: Question
): Array<CombinedAnswer> => {
    const answersToQuestion = selectAnswersToQuestion(state, question)
    const waveIndex = selectDataFiltersWave(state)
    const waveCompareIndex = selectDataFiltersWaveCompare(state)
    const cohort = selectDataFilterCohort(state)
    const cohortOther = selectDataFiltersOtherCohort(state)
    const waves = selectWaves(state)
    const currentWave = waves[waveIndex]
    const compareWave = waveCompareIndex !== null ? waves[waveCompareIndex] : null
    const combinedAnswers: Array<CombinedAnswer> = []
    let valueMax = -Infinity

    answersToQuestion.forEach(answer => {
        let answerValues = currentWave["answer-values"]
            .filter(answerValue => answerValue["answer-id"] === answer.id)
        
        // There is normally only one answer value per answer, 
        // except for "nuage de mots" which is an open question
        if (question.type === QuestionType.NUAGE_MOTS) {
            // do nothing
        } else {
            answerValues = answerValues.slice(0, 1)
        }

        answerValues.forEach(answerValue => {
            let evolutionTous: number | null = null
            let evolutionGrandPublic: number | null = null
            let evolutionChomeurs: number | null = null
    
            const valueTous = answerValue["value-tous"]
            const valueGrandPublic = answerValue["value-grand-public"]
            const valueChomeurs = answerValue["value-chomeurs"]
    
            const value: number = (cohort === Cohort.CHOMEURS ? valueChomeurs : valueGrandPublic) || 0
            let valueOther: CombinedAnswer['value-other'] = null
            let evolution: CombinedAnswer['evolution'] = null
            let evolutionOther: CombinedAnswer['evolution-other'] = null
    
            if (cohortOther !== null) {
                valueOther = (cohortOther === Cohort.CHOMEURS ? valueChomeurs : valueGrandPublic) || 0
                valueMax = Math.max(valueMax, valueOther)
            }
            valueMax = Math.max(valueMax, value)
    
            if (compareWave !== null) {
                let compareAnswerValue: AnswerValue | null = null
                if (question.type === QuestionType.NUAGE_MOTS) {
                    compareAnswerValue = compareWave["answer-values"]
                        .filter(otherAnswerValue => 
                            otherAnswerValue["answer-id"] === answer.id
                            && otherAnswerValue["nuage-mots"] === answerValue["nuage-mots"]
                        )[0]
                } else {
                    compareAnswerValue = compareWave["answer-values"]
                        .filter(answerValue => answerValue["answer-id"] === answer.id)[0]
                }
    
                if (compareAnswerValue) {
                    const compareValueTous = compareAnswerValue["value-tous"]
                    const compareValueGrandPublic = compareAnswerValue["value-grand-public"]
                    const compareValueChomeurs = compareAnswerValue["value-chomeurs"]
            
                    if (compareValueTous !== undefined && valueTous !== undefined) {
                        evolutionTous = round(valueTous - compareValueTous, ROUND_PRECISION)
                    }
                    if (compareValueGrandPublic !== undefined && valueGrandPublic !== undefined) {
                        evolutionGrandPublic = round(valueGrandPublic - compareValueGrandPublic, ROUND_PRECISION)
                    }
                    if (compareValueChomeurs !== undefined && valueChomeurs !== undefined) {
                        evolutionChomeurs = round(valueChomeurs - compareValueChomeurs, ROUND_PRECISION)
                    }
        
                    evolution = cohort === Cohort.CHOMEURS ? evolutionChomeurs : evolutionGrandPublic
                    if (cohortOther !== null) {
                        evolutionOther = cohortOther === Cohort.CHOMEURS ? evolutionChomeurs : evolutionGrandPublic
                    }    
                }
            }
    
            combinedAnswers.push({
                ...answer, 
                ...answerValue,
                "evolution-tous": evolutionTous,
                "evolution-grand-public": evolutionGrandPublic,
                "evolution-chomeurs": evolutionChomeurs,
                "value": value || 0,
                "value-other": valueOther,
                // Must be replaced when we get the total max
                "value-max": -Infinity,
                "evolution": evolution,
                "evolution-other": evolutionOther,
            })
        })
    })
    return combinedAnswers.map(combinedAnswer => ({
        ...combinedAnswer, 
        "value-max": valueMax
    }))
}

export const selectFirstQuestionId = (state: RootState) =>
    selectQuestions(state)[0].id

export const selectGetQuestion = (state: RootState, questionId: string) =>
    selectQuestions(state).filter(question => question.id === questionId)[0] || null

export const selectRouteRendered = (state: RootState) => 
    state.pageTransitions.routeRendered

export const selectWindowDimensions = (state: RootState) =>
    state.windowDimensions

export const selectCurrentPage = (state: RootState) =>
    state.pageTransitions.currentPage

export const selectChapters = (state: RootState) => {
    const chaptersFound = new Set<string>()
    const chapters: Array<[string, string]> = []
    selectQuestions(state).forEach(question => {
        if (!chaptersFound.has(question.chapter)) {
            chaptersFound.add(question.chapter)
            chapters.push([question.chapter, question.id])
        }
    })
    return chapters
}

export const selectQuestionsInChapter = (state: RootState, chapter: string) => {
    const questions = selectQuestions(state)
    return questions.filter(question => question.chapter === chapter)
}

export const selectDataFiltersWave = (state: RootState) => {
    const waveIndex = state.dataFilters.wave
    const waves = selectWaves(state)
    if (waveIndex === -1) {
        return waves.length - 1
    }
    return waveIndex
}

export const selectDataFiltersWaveCompare = (state: RootState) => 
    state.dataFilters.waveCompare

export const selectDataFiltersShowOtherCohort = (state: RootState) => 
    state.dataFilters.showOtherCohort