import React from 'react'
import { Route } from 'react-router-dom'
import PageChiffresCles from "./PageChiffresCles"
import PageResultatsDetailles from "./PageResultatsDetailles"
import DelayIn from "./DelayIn"
import PageContainer, { scrollToPageTop } from "./PageContainer"
import HeaderMethodologie from "./PageMethodologie/Header"
import HeaderChiffresCles from "./PageChiffresCles/Header"
import HeaderResultatsDetailles from "./PageResultatsDetailles/Header"
import { selectRouteRendered, selectWindowDimensions } from "../store/selectors"
import { CSSTransition } from 'react-transition-group'
import PageAccueil from "./PageAccueil"
import PageMethodologie from "./PageMethodologie"
import configPages, { METHODOLOGIE_ID, ACCUEIL_ID, CHIFFRES_ID, RESULTATS_ID } from "../config-pages"
import theme from '../theme'
import { useSelector } from 'react-redux'
import FooterChiffresCles from './PageChiffresCles/Footer'
import FooterMobile from './PageResultatsDetailles/FooterMobile'

const ROUTES = [
    {
        paths: [configPages[METHODOLOGIE_ID].href()],
        classNames: 'PageMethodologie',
        Body: PageMethodologie,
        Header: HeaderMethodologie,
    },
    {
        paths: [configPages[CHIFFRES_ID].href()],
        classNames: 'PageChiffresCles',
        Body: PageChiffresCles,
        Header: HeaderChiffresCles,
        Footer: FooterChiffresCles,
    },
    {
        paths: [configPages[RESULTATS_ID].href(), configPages[RESULTATS_ID].href(':questionId')],
        classNames: 'PageResultatsDetailles',
        Body: PageResultatsDetailles,
        Header: HeaderResultatsDetailles,
        Footer: FooterMobile,
    },
]

const DelayedRoute: React.FunctionComponent<{
    path: string | Array<string>
    transitionClassNames: string
    hasInitialDelay?: boolean
    onExit?: () => void
}> = ({ path, transitionClassNames, hasInitialDelay = false, children, onExit }) => {
    const {width} = useSelector(selectWindowDimensions)
    const isMobile = width <= theme.dimensions.thresholdMobile

    if (isMobile) {
        return (
            <Route exact path={path}>
                {children}
            </Route>
        )
    }

    return (
        <Route exact path={path}>
            {({ match }) => (
                <DelayIn
                    in={match != null} 
                    delay={isMobile ? 100: theme.transitions.pageExitTransitionDuration}
                    hasInitialDelay={hasInitialDelay}
                >
                    {(delayedIn: boolean) => (
                        <CSSTransition
                            in={delayedIn}
                            onExit={onExit} 
                            timeout={{
                                enter: isMobile ? 0: theme.transitions.pageTransitionDuration,
                                exit: isMobile ? 0: theme.transitions.pageExitTransitionDuration,
                            }}
                            classNames={transitionClassNames}
                            unmountOnExit
                            appear
                        >
                            {children}
                        </CSSTransition>
                    )}
                </DelayIn>
            )}
        </Route>
    )
}

const AppRoutes = () => {
    const isNotFirstRender = useSelector(selectRouteRendered)

    const header = (
        <>
            {ROUTES.map(routeConfig => (
                <DelayedRoute
                    key={routeConfig.paths.join('')} 
                    path={routeConfig.paths} 
                    transitionClassNames="PageContainerHeader"
                >
                    <routeConfig.Header />
                </DelayedRoute>
            ))}
        </>
    )

    const footer = (
        <>
            {ROUTES.map(routeConfig => 
                routeConfig.Footer ? 
                (<DelayedRoute
                    key={routeConfig.paths.join('')} 
                    path={routeConfig.paths} 
                    transitionClassNames="PageContainerFooter"
                >
                    <routeConfig.Footer />
                </DelayedRoute>) : null
            )}
        </>
    )

    return (
        <>
            <DelayedRoute 
                path={configPages[ACCUEIL_ID].href()}
                transitionClassNames="PageAccueil"
                hasInitialDelay={isNotFirstRender}
            >
                <PageAccueil />
            </DelayedRoute>
            <DelayedRoute 
                path={ROUTES.reduce((allPaths, routeConfig) => ([...allPaths, ...routeConfig.paths]), [] as Array<string>)}
                transitionClassNames="PageContainer"
                hasInitialDelay={isNotFirstRender}
            >
                <PageContainer header={header} footer={footer} >
                    {ROUTES.map(routeConfig => (
                        <DelayedRoute 
                            key={routeConfig.paths.join('')} 
                            path={routeConfig.paths}
                            transitionClassNames={routeConfig.classNames}
                            // To avoid issues with the headers transitions (when shadow active, transition looks wrong)
                            onExit={() => scrollToPageTop()}
                        >
                            <routeConfig.Body />
                        </DelayedRoute>
                    ))}
                </PageContainer>           
            </DelayedRoute>
            
        </>
    )
}

export default AppRoutes