import React from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'

interface ContainerProps {
    isFake: boolean
    isAtTop: boolean
}

const Container = styled.div<ContainerProps>`
    z-index: ${theme.zIndexes.header};
    background-color: ${theme.colors.White};
    ${theme.mixins.topBar}

    ${props => props.isFake ? `
        visibility: hidden;
    `: `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-width: ${theme.dimensions.maxWidth}px;
        @media (min-width: ${theme.dimensions.maxWidth}px) {
            left: calc((100% - ${theme.dimensions.maxWidth}px) / 2);
        }
    `}
    ${props => !props.isFake && !props.isAtTop ? `
        box-shadow: 10px 10px 50px 0px rgba(0,0,0,0.75);
    `: ``}
`

interface Props {
    isAtTop: boolean
}

const FixedTop: React.FunctionComponent<Props> = ({ children, isAtTop }) => {
    // Create 2 elements, one that is fixed, the other in the flow
    // to push the main content down, therefore leaving space for the fixed menu.
    const containers = [false, true].map((isFake) => (
        <Container isFake={isFake} isAtTop={isAtTop} key={`${isFake}`}>
            {children}
        </Container>
    ))

    return (
        <React.Fragment>
            {containers}
        </React.Fragment>
    )
}

export default FixedTop