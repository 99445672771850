import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import VideoEntry from './VideoEntry'
import { METHODOLOGIE_ID } from '../../config-pages'
import theme from '../../theme'
import { useSelector, useDispatch } from 'react-redux'
import { selectWaves, selectWindowDimensions, selectVideos } from '../../store/selectors'
import { setCurrentPage } from '../../store/page-transitions'
import PageContainerChildren from '../PageContainerChildren'
import listBulletSrc from "../../images/arrow-methodo.svg"
import { scrollToPageTop } from '../PageContainer'

const Subtitle = styled.h2`
    font-family: ${theme.fonts.subtitle};
    margin-top: ${theme.spacings.vertical2};
    margin-bottom: ${theme.spacings.vertical1};
    font-size: 180%;
    @media(max-width: ${theme.dimensions.thresholdMobile}px) {
        margin-top: ${theme.spacings.vertical1};
        margin-bottom: ${theme.spacings.vertical05};    
    }
`

const Description = styled.div`
    padding-top: ${theme.spacings.vertical05};

    p {
        margin-bottom: ${theme.spacings.verticalRel05};
    }
    @media(max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 125%;
    }

    a {
        color: ${theme.colors.Black};
        text-decoration: underline;
    }

    ul {
        padding-left: 1em;
        margin-left: 2em;
        list-style: none;

        @media(max-width: ${theme.dimensions.thresholdMobile}px) {
            margin-left: 1em;
        }

        li {
            margin-bottom: ${theme.spacings.horizontal05};
            position: relative;
            img {
                position: absolute;
                left: -1.5em;
                top: 0.15em;
            }
        }

        li:last-child {
            margin-bottom: 0;
        }
    }
`

const Question = styled.p`
    text-align: center;
    color: ${theme.colors.Purple100};
    font-style: italic;
    font-size: 120%;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        text-align: left;
        font-size: 110%;
    }
`

const HorizontalLine = styled.div<{marginBottom?: string}>`
    border-top: solid 1px ${theme.colors.Purple100};
    width: 25%;
    margin: ${theme.spacings.vertical05} 0;
    margin-left: 37.5%;
    ${props => props.marginBottom ? 
        `margin-bottom: ${props.marginBottom}` : ''}
`

const VagueTitle = styled.h3`
    font-weight: bold;
    font-size: 100%;
    color: ${theme.colors.Purple100};
`

const VideoEntries = styled.div`
    @media (min-width: ${theme.dimensions.thresholdMobile}px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${theme.spacings.horizontal2};
    }

    ${VideoEntry}:nth-child(2n) {
        justify-self: right;
    }
`

const {enterOrAppearTransition, exitTransition} = theme.transitions.buildPageTransitionMaker('&.PageMethodologie')

const StyledPageContainerChildren = styled(PageContainerChildren)`

    @media (min-width: ${theme.dimensions.thresholdMobile}px) {
        padding: 0 ${theme.dimensions.menuDesktopHPaddingPercent}%;
    }

    ${enterOrAppearTransition(
        [`${Description}`],
        [['opacity', '0', '1']],
        0.5, 0.1
    )}

    ${enterOrAppearTransition(
        [`${Subtitle}`],
        [['opacity', '0', '1']],
        0.6, 0.1
    )}

    ${enterOrAppearTransition(
        [`${VideoEntries} > :nth-child(1)`],
        [['opacity', '0', '1']],
        0.7, 0.1
    )}

    ${enterOrAppearTransition(
        [`${VideoEntries} > :nth-child(2)`],
        [['opacity', '0', '1']],
        0.8, 0.1
    )}

    ${enterOrAppearTransition(
        [`${VideoEntries} > :nth-child(3)`],
        [['opacity', '0', '1']],
        0.9, 0.1
    )}

    ${enterOrAppearTransition(
        [`${VideoEntries} > :nth-child(4)`],
        [['opacity', '0', '1']],
        1.0, 0.1
    )}

    ${exitTransition(
        [`${Description}`, `${Subtitle}`, `${VideoEntries}` ],
        [['opacity', '1', '0']],
        0, 1
    )}
`

interface Props {
    className?: string
}

const PageMethodologie: React.FunctionComponent<Props> = ({ className }) => {
    const waves = useSelector(selectWaves)
    const videos = useSelector(selectVideos)
    const {width} = useSelector(selectWindowDimensions)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(METHODOLOGIE_ID))
        // On mobile, since we don't have transitions we need to handle scrolling too
        scrollToPageTop()
    }, [dispatch])
    
    let videoWidth: number
    if (width > theme.dimensions.thresholdMobile) {
        videoWidth = Math.min(theme.dimensions.maxWidth, width) / 2 
            - theme.fontSizes.baseDesktop * 2.2 * parseFloat(theme.spacings.horizontal05)
        videoWidth = videoWidth * (1 - 2 * theme.dimensions.menuDesktopHPaddingPercent / 100)
    } else {
        videoWidth = width - theme.fontSizes.baseMobile * 2
    }
    
    return (
        <StyledPageContainerChildren isScrollable={true} className={className}>
            <Description>
                <p>
                    <b>
                L’Unédic réalise un baromètre de la perception du chômage. 
                Menée avec <a href="https://elabe.fr/" target="_blank" rel="noopener noreferrer"> l’institut Elabe</a>, cette étude miroir met en perspective 
                le regard des Français et des demandeurs d’emploi et permet ainsi 
                d’éclairer les représentations de la société liées au chômage.
                    </b>
                </p>

                <Question>
                    Quel regard les Français portent-ils sur le chômage et les chômeurs ?
                    <br />
                    Et comment eux-mêmes se perçoivent-ils ?
                </Question>

                <HorizontalLine />
                
                <p>
                    Le baromètre est réalisé en ligne. Il s’agit d’une étude quantitative, 
                    menée auprès d’un échantillon représentatif de la population française 
                    âgée de 15 ans et plus, raisonné pour les demandeurs d’emploi inscrits 
                    à Pôle emploi. Pour obtenir les résultats sur l’ensemble des Français, 
                    les deux cibles ont été fusionnées et les demandeurs d’emploi issus 
                    du Fichier national des allocataires (FNA) remis à leur bon poids. 
                    Les demandeurs d’emploi inscrits à Pôle emploi issus du panel 
                    n’ont pas été fusionnés avec ceux du FNA, et ont été attribués d’un poids 
                    nul lors des traitements statistiques.
                </p>

                <HorizontalLine marginBottom="1.5em" />

                <p>
                    La représentativité est assurée selon la méthode des quotas appliquée 
                    aux variables suivantes :
                </p>

                <ul>
                    <li>
                        <img src={listBulletSrc} alt="" />
                        <b>Population française âgée de 15 ans et plus en France métropolitaine</b> :
                        sexe, âge, croisés sexe-âge, catégorie socioprofessionnelle, 
                        catégorie d’agglomération, région de résidence, et chômeur/non-chômeur 
                        (+ niveau de diplôme en suivi).
                    </li>
                    <li>
                        <img src={listBulletSrc} alt="" />
                        <b>Demandeurs d’emploi inscrits à Pôle emploi en France métropolitaine et 
                        Outre-mer (hors annexes A8A10 et Mayotte)</b> :
                        sexe, âge, croisés sexe-âge, niveau de diplôme, région de résidence, 
                        catégorie d’agglomération, statut d’indemnisation, catégorie Pôle emploi, 
                        ancienneté d’inscription, motif d’inscription à Pôle emploi.
                    </li>
                </ul>

            </Description>

            <Subtitle>
                En ligne actuellement 
            </Subtitle>

            <Description>
                {waves.map((wave) => (
                    <div key={wave.title}>
                        <VagueTitle>{wave.title}</VagueTitle>
                        <p>
                            {wave.methodologie}
                        </p>
                    </div>
                ))}
            </Description>

            <Subtitle>Synthèses vidéos</Subtitle>

            <VideoEntries>
                {videos.map((video) => (
                    <VideoEntry 
                        key={video.title} 
                        title={video.title}
                        description={video.description} 
                        width={videoWidth} 
                        youtubeId={video["youtube-id"]} 
                    />
                ))}
            </VideoEntries>

        </StyledPageContainerChildren>
    )
}

export default PageMethodologie