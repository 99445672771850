import React from 'react'
import styled from 'styled-components/macro'
import { CHIFFRES_ID, METHODOLOGIE_ID, ACCUEIL_ID, RESULTATS_ID, PageId } from '../../config-pages'
import theme from '../../theme'
import MenuItem from './MenuItem'

export const Container = styled.nav`
    /* for centering vertically the content */
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.Purple150};
    padding: ${theme.spacings.vertical1} 0;
    height: ${theme.dimensions.menuHeight};
    ${theme.mixins.topBar}
`

const MenuList = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    max-width: ${theme.dimensions.maxWidth}px;
    margin: auto;
    padding: 0 ${theme.dimensions.menuDesktopHPaddingPercent}%;
`

interface Props {
    active: PageId | null
}

const MenuDesktop: React.FunctionComponent<Props> = ({ active }) => {
    const menuItems = [ACCUEIL_ID, CHIFFRES_ID, RESULTATS_ID, METHODOLOGIE_ID].map((pageId) => (
        <MenuItem pageId={pageId as PageId} active={active} key={pageId} />
    ))

    return (
        <Container>
            <MenuList>
                {menuItems}
            </MenuList>
        </Container>
    )
}

export default React.memo(MenuDesktop)