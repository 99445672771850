import citySrc from '../../../images/charts/icons choix-binaire/icon-city.svg'
import countrySrc from '../../../images/charts/icons choix-binaire/icon-country.svg'
import abledSrc from '../../../images/charts/icons choix-binaire/icon-abled.svg'
import disabledSrc from '../../../images/charts/icons choix-binaire/icon-disabled.svg'
import educatedSrc from '../../../images/charts/icons choix-binaire/icon-educated.svg'
import uneducatedSrc from '../../../images/charts/icons choix-binaire/icon-uneducated.svg'
import manSrc from '../../../images/charts/icons choix-binaire/icon-man.svg'
import womanSrc from '../../../images/charts/icons choix-binaire/icon-woman.svg'
import oldSrc from '../../../images/charts/icons choix-binaire/icon-old.svg'
import youngSrc from '../../../images/charts/icons choix-binaire/icon-young.svg'
import beneficiarySrc from '../../../images/charts/icons choix-binaire/icon-beneficiary.svg'
import nonBeneficiarySrc from '../../../images/charts/icons choix-binaire/icon-nonBeneficiary.svg'

import theme from '../../../theme'

export type Palette = [[string, string], [string, string], [string, string]]

interface ThemeEntry {
    images: [string, string]
    colors: Palette
    colorsOther: Palette
}

export const Themes: {[group: string]: ThemeEntry} = {
    '1': {
        images: [manSrc, womanSrc], 
        colors: [
            ['#8f4c98', theme.colors.White],
            ['#482856', theme.colors.White],
            ['#8f4c98', theme.colors.White],
        ],
        colorsOther: [
            ['#bc92c1', theme.colors.Black],
            ['#8f4c98', theme.colors.White],
            ['#bc92c1', theme.colors.Black],
        ]
    },
    '2': {
        images: [citySrc, countrySrc], 
        colors: [
            ['#3778bb', theme.colors.White],
            ['#003259', theme.colors.White],
            ['#3778bb', theme.colors.White]
        ],
        colorsOther: [
            ['#00a3da', theme.colors.White],
            ['#3778bb', theme.colors.White],
            ['#00a3da', theme.colors.White],
        ]
    },
    '3': {
        images: [uneducatedSrc, educatedSrc], 
        colors: [
            ["#00aead", theme.colors.White],
            ["#006665", theme.colors.White],
            ["#00aead", theme.colors.White]
        ],
        colorsOther: [
            ['#80d6d6', theme.colors.Black],
            ['#00aead', theme.colors.White],
            ['#80d6d6', theme.colors.Black],
        ]
    },
    '4': {
        images: [disabledSrc, abledSrc], 
        colors: [
            ['#e72c65', theme.colors.White],
            ['#990009', theme.colors.White],
            ['#e72c65', theme.colors.White]
        ],
        colorsOther: [
            ['#ee7999', theme.colors.Black],
            ['#e72c65', theme.colors.White],
            ['#ee7999', theme.colors.Black],
        ]
    },
    '5': {
        images: [beneficiarySrc, nonBeneficiarySrc], 
        colors: [
            ['#ff9622', theme.colors.White],
            ['#cc6900', theme.colors.White],
            ['#ff9622', theme.colors.White]
        ],
        colorsOther: [
            ['#ffc923', theme.colors.Black],
            ['#ff9622', theme.colors.White],
            ['#ffc923', theme.colors.Black],
        ]
    },
    '6': {
        images: [youngSrc, oldSrc], 
        colors: [
            ['#4bae32', theme.colors.White],
            ['#0f4d00', theme.colors.White],
            ['#4bae32', theme.colors.White]
        ],
        colorsOther: [
            ['#74cd50', theme.colors.Black],
            ['#4bae32', theme.colors.White],
            ['#74cd50', theme.colors.Black],
        ]
    }
}

export type ThemeGroups = keyof typeof Themes