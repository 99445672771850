import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { setDataFiltersCohort } from '../../store/data-filters'
import { useDispatch, useSelector } from 'react-redux'
import { selectDataFilterCohort } from '../../store/selectors'
import { Cohort } from '../../models'
import { scrollToPageTop } from '../PageContainer'

const Container = styled.h1`
    margin: auto;
    padding: ${theme.spacings.vertical1} 0;
    max-width: ${theme.dimensions.maxWidth}px;
    text-align: center;
    font-family: ${theme.fonts.title};
    font-size: 175%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        text-align: left;
        font-size: 160%;
        padding-left: ${theme.spacings.horizontal05};
        padding-top: ${theme.spacings.vertical05};
        padding-bottom: ${theme.spacings.vertical05};
    }
`

const Text = styled.div`
    padding-right: calc(${theme.dimensions.hamburger} + 0.6rem);
    margin-bottom: 0.5em;
`

const Selector = styled.ul`
    list-style: none;
`

const SelectorItem = styled.li<{
    isActive: boolean
}>`
    ${theme.mixins.clickable}
    color: ${theme.colors.Grey100};
    display: inline-block;

    ${props => props.isActive ? `
        color: ${theme.colors.Cyan};
    `: `
        &:hover {
            color: #9ba6b2;
        }
        @media (max-width: ${theme.dimensions.thresholdMobile}px) {
            margin-left: 0.6em;
        }
    `}

    :first-child {
        margin-right: ${theme.spacings.horizontal05};
    }
`

const CohortSelector = () => {
    const dispatch = useDispatch()
    const currentCohort = useSelector(selectDataFilterCohort)

    return (
        <Container>
            <Text>Quel est le regard porté sur le chômage par...</Text>
            <Selector>
                <SelectorItem 
                    onClick={() => {
                        dispatch(setDataFiltersCohort(Cohort.GRAND_PUBLIC))
                        scrollToPageTop()
                    }}
                    isActive={Cohort.GRAND_PUBLIC === currentCohort}
                >
                    <span>{Cohort.GRAND_PUBLIC === currentCohort ? '> ' : ''}</span>
                    l’ensemble des Français
                </SelectorItem>
                <SelectorItem 
                    onClick={() => {
                        dispatch(setDataFiltersCohort(Cohort.CHOMEURS))
                        scrollToPageTop()
                    }}
                    isActive={Cohort.CHOMEURS === currentCohort}
                >
                    <span>{Cohort.CHOMEURS === currentCohort ? '> ': ''}</span>
                    les demandeurs d’emploi
                </SelectorItem>
            </Selector>
        </Container>
    )
}

export default CohortSelector