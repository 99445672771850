import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle, EvolutionHtml } from '../shared'
import { CSSTransition } from 'react-transition-group'
import theme from '../../../theme'
import Legend from './Legend'
import { Cohort, AnswerMirrorPositivity } from '../../../models'
import Graph, { Bar, Percentage, Text } from './Graph'
import sortBy from 'lodash.sortby'

const MirroirCohortDisplay = {
    [Cohort.CHOMEURS]: 'Demandeurs d\'emploi',
    [Cohort.GRAND_PUBLIC]: 'Non-demandeurs d\'emploi',
}

const SplitContainer = styled.div`
    display: flex;
    flex-direction: row;

    > * {
        flex: 1;
    }

    > *:first-child {
        text-align: right;
        padding-right: ${theme.spacings.horizontal05};
    }
    > *:last-child {
        text-align: left;
        padding-left: ${theme.spacings.horizontal05};
    }
`

const TitleContainer = styled.div`
    width: 50%;
`

const CohortTitle = styled.div`
    font-family: ${theme.fonts.subtitle};
    color: ${theme.colors.Grey150};
`

const Container = styled.div`
    ${TitleContainer} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.2, 0.3)}
    }

    ${Legend}, ${Text}, ${Percentage}, ${EvolutionHtml} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.5, 0.3)}
    }

    ${Bar} {
        ${theme.transitions.buildPageTransition(['max-width'], 0.2, 1)}
    }

    .reponsesMirroir-appear {
        ${TitleContainer}, ${Legend}, ${Text}, ${Percentage}, ${EvolutionHtml} {
            opacity: 0;
        }

        ${Bar} {
            max-width: 0%;
        }
    }

    .reponsesMirroir-appear-active {
        ${TitleContainer}, ${Legend}, ${Text}, ${Percentage}, ${EvolutionHtml} {
            opacity: 1;
        }

        ${Bar} {
            max-width: 100%;
        }
    }
`

const VisualizationReponsesMirroir: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort
}) => {
    const cohortOther = cohort === Cohort.CHOMEURS ? Cohort.GRAND_PUBLIC : Cohort.CHOMEURS
    const sortedAnswerValues = sortBy(answerValues, (answerValues) => -answerValues.value)
    const graphs = sortedAnswerValues.map((answerValue) => {
        return (
            <SplitContainer key={answerValue.text}>
                <Graph 
                    value={answerValue.value} 
                    valueMax={answerValue["value-max"]}
                    evolution={answerValue.evolution}
                    alignment="left"
                    positivity={answerValue["mirror-positivity"]! as AnswerMirrorPositivity}
                    text={cohort === Cohort.GRAND_PUBLIC ? answerValue.text : answerValue["mirror-text"]!}
                />
                <Graph 
                    value={cohortOther === Cohort.GRAND_PUBLIC ? answerValue["value-grand-public"]! : answerValue["value-chomeurs"]!} 
                    valueMax={answerValue["value-max"]}
                    evolution={cohortOther === Cohort.GRAND_PUBLIC ? answerValue["evolution-grand-public"]! : answerValue["evolution-chomeurs"]!} 
                    alignment="right"
                    positivity={answerValue["mirror-positivity"]! as AnswerMirrorPositivity}
                    text={cohortOther === Cohort.GRAND_PUBLIC ? answerValue.text : answerValue["mirror-text"]!}
                />
            </SplitContainer>
        )
    })

    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="reponsesMirroir"
            >
                <div>
                    <SplitContainer>
                        <TitleContainer>
                            <CohortTitle>{MirroirCohortDisplay[cohort]}</CohortTitle>
                            <VisualizationTitle>{cohort === Cohort.CHOMEURS ? question["mirror-text"]! : question["text"]}</VisualizationTitle>
                        </TitleContainer>
                        <TitleContainer>
                            <CohortTitle>{MirroirCohortDisplay[cohortOther]}</CohortTitle>
                            <VisualizationTitle>{cohortOther === Cohort.CHOMEURS ? question["mirror-text"]! : question["text"]}</VisualizationTitle>
                        </TitleContainer>
                    </SplitContainer>
                    <Legend />
                    {graphs}
                </div>
            </CSSTransition>
        </Container>
    )
}

export default VisualizationReponsesMirroir