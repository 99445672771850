import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import PageContainerHeader from '../PageContainerHeader'

const Title = styled.h1`
    font-family: ${theme.fonts.title};
    text-align: center;
    margin-top: ${theme.spacings.vertical1};
    margin-bottom: ${theme.spacings.vertical1};

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        text-align: left;
        padding-left: ${theme.spacings.horizontal05};
        margin-top: ${theme.spacings.vertical1};
        margin-bottom: ${theme.spacings.vertical1};
    }
`

const Header: React.FunctionComponent = () => {
    return (
        <PageContainerHeader>
            <Title>Méthodologie</Title>
        </PageContainerHeader>
    )
}

export default Header