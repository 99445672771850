import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import theme from '../../theme'
import configPages, { PageId } from '../../config-pages'

const Line = styled.div<{isActive: boolean}>`
    position: relative;
    bottom: 3px;
    border-top: solid 3px ${theme.colors.Purple50};
    ${props => props.isActive ? ``: `
        border-color: transparent;
    `}
    font-weight: bold;
    height: 0.05em;
    transition-property: width, left;
    transition-duration: ${theme.transitions.duration1};
`

export const Container = styled.li<{isActive: boolean}>`
    a, span {
        color: ${theme.colors.Purple50};
        text-decoration: none;
    }
    ${props => props.isActive ? `
        ${Line} {
            width: 100%;
        }
        a, span {
            color: ${theme.colors.White};
        }
    `: `
        ${Line} {
            width: 0%;
            left: 50%;
        }

        &:hover {
            a, span {
                color: ${theme.colors.White};
                position: relative;
                top: 2px;
            }
            ${Line} {
                border-top: 1px solid white;
                width: 100%;
                left: 0%;
            }
        }

    `}
`

interface Props {
    active: PageId | null
    pageId: PageId
    onClick?: () => void
}

const MenuItem: React.FunctionComponent<Props> = ({active, pageId, onClick}) => {
    const isActive = active === pageId
    return (
        <Container isActive={isActive} onClick={onClick}>
            <Line isActive={isActive} />
            {isActive ? 
                <span>{configPages[pageId].title}</span> :
                <Link to={configPages[pageId].href()}>{configPages[pageId].title}</Link>
            }
        </Container>
    )
}

export default React.memo(MenuItem)