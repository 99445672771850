import React from 'react'
import styled from 'styled-components/macro'
import LegendEntry from '../LegendEntry'
import { CombinedAnswer } from '../../../models'
import { Colors } from './config-visualization'
import theme from '../../../theme'

const LegendTitle = styled.div`
    font-weight: bold;
    margin-bottom: ${theme.spacings.vertical05};
`

export interface Props {
    className?: string
    answerValues: Array<CombinedAnswer>
}

const Legend: React.FunctionComponent<Props> = ({ 
    className = '',
    answerValues
}) => {
    const legendEntries = answerValues.map((answerValue, i) => {
        return (<LegendEntry text={answerValue.text} color={Colors[i % Colors.length][0]} key={i} />)
    })
    return (
        <div className={className}>
            <LegendTitle>Les répondants…</LegendTitle>
            {legendEntries}
        </div>
    )
}

export default styled(React.memo(Legend))``