import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as BurgerSvg } from '../../images/mobile/button-burger_open.svg'
import { ReactComponent as CloseSvg } from '../../images/mobile/button-burger_close.svg'
import { ReactComponent as CursorSvg } from '../../images/mobile/selector-arrow-blue-small.svg'
import configPages, { CHIFFRES_ID, METHODOLOGIE_ID, ACCUEIL_ID, RESULTATS_ID, PageId } from '../../config-pages'
import MenuItem, {Container as MenuItemContainer} from './MenuItem'
import theme from '../../theme'
import { useSelector } from 'react-redux'
import { selectChapters, selectGetQuestion } from '../../store/selectors'
import { useCurrentQuestionId } from '../../hooks'
import {RootState} from '../../store'
import { Link } from 'react-router-dom'

export const Container = styled.div`
    position: absolute;
    right: ${theme.spacings.vertical05};
    top: ${theme.spacings.vertical05};
    z-index: ${theme.zIndexes.justAboveHeader};
    font-size: 110%
`

const roundButtonMixin = `
    border-radius: ${theme.dimensions.hamburger};
    height: ${theme.dimensions.hamburger};
    width: ${theme.dimensions.hamburger};
    background-color: ${theme.colors.Purple150};
`

const HamburgerContainer = styled.div`
    ${roundButtonMixin}
    display: inline-block;

    svg {
        width: 100%;
        height: 100%;
    }
`

const CloseContainer = styled.div`
    ${roundButtonMixin}
    position: absolute;
    left: 0;
    top: ${theme.spacings.vertical05};
    transform: translateX(-50%);
    border: 1px solid ${theme.colors.White};
`

const Drawer = styled.div<State>`
    overflow: visible;
    position: fixed;
    top: 0;
    right: 0;
    z-index: ${theme.zIndexes.modal};
    width: ${theme.dimensions.menuDrawerWidth};
    height: 100%;
    background-color: ${theme.colors.Purple150};
    padding-left: 5em;
    ${props => props.isExpanded ? `
        transform: translateX(0%);
    `: `
        transform: translateX(150%);
    `}
    ${theme.mixins.cssTransition}
    transition-property: transform;
    transition-duration: 500ms;
`

const DrawerContent = styled.div<State>``

const DrawerOverlay = styled.div<State>`
    position: fixed;
    opacity: 0;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndexes.modalOverlay};
    ${props => props.isExpanded ? `
        left: 0;
        opacity: 1;
    `: ``}
    background-color: ${theme.colors.OverlayBackground};
    ${theme.mixins.cssTransition}
    transition-property: opacity;
    transition-duration: 2s;
`

const MenuList = styled.ul`
    list-style: none;
    padding-top: 0.5em;
    padding-right: 2.3em;

    ${MenuItemContainer} {
        margin: ${theme.spacings.vertical1} 0;
    }
`

const ChaptersContainer = styled.div`
    padding-bottom: 1em;
`

const Chapter = styled.div<{isActive: boolean}>`
    /* to place cursor */
    position: relative;
    color: white;
    margin-bottom: 1em;
    ${props => props.isActive ? `
        color: ${theme.colors.Cyan};
    `: ``}
`
const ChapterCursor = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-200%);
`

const renderChapters = (chapters: Array<[string, string]>, active: string, onClick: () => void) => {
    const chapterElems = chapters.map(([chapter, firstQuestionId]) => (
        <Link to={configPages[RESULTATS_ID].href(firstQuestionId)} key={chapter}>
            <Chapter isActive={chapter === active} onClick={onClick}>
                {chapter === active ? <ChapterCursor><CursorSvg /></ChapterCursor>: null}
                {chapter}
            </Chapter>
        </Link>
    ))
    return (
        <ChaptersContainer>
            {chapterElems}
        </ChaptersContainer>
    )
}

interface State {
    isExpanded: boolean;
}

export interface Props {
    active: PageId | null
}

const MenuMobile: React.FunctionComponent<Props> = ({active}) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const _onHamburgerClick = () => setIsExpanded(true)
    const _onCloseClick = () => setIsExpanded(false)
    
    const chapters = useSelector(selectChapters)
    const questionId = useCurrentQuestionId()
    const question = useSelector((state: RootState) => {
        if (questionId !== null) {
            return selectGetQuestion(state, questionId)
        }
        return null
    })
    let activeChapter: string | null = null
    if (question) {
        activeChapter = question.chapter
    }

    return (
        <Container>
            <HamburgerContainer onClick={_onHamburgerClick}>
                <BurgerSvg />
            </HamburgerContainer>
            <DrawerOverlay isExpanded={isExpanded} />
            <Drawer isExpanded={isExpanded}>
                <CloseContainer>
                    <CloseSvg onClick={_onCloseClick} />
                </CloseContainer>
                <DrawerContent isExpanded={isExpanded}>
                    <MenuList>
                        <MenuItem onClick={_onCloseClick} pageId={ACCUEIL_ID} active={active}/>
                        <MenuItem onClick={_onCloseClick} pageId={CHIFFRES_ID} active={active}/>
                        <MenuItem onClick={_onCloseClick} pageId={RESULTATS_ID} active={active}/>
                        {active === RESULTATS_ID ? renderChapters(chapters, activeChapter!, _onCloseClick) : ''}
                        <MenuItem onClick={_onCloseClick} pageId={METHODOLOGIE_ID} active={active}/>
                    </MenuList>
                </DrawerContent>
            </Drawer>
        </Container>
    )
}

export default React.memo(MenuMobile)