import React from 'react'
import styled from 'styled-components/macro'
import { CombinedAnswer } from '../../../models'
import { ColorScheme } from './Graph'
import theme from '../../../theme'
import LegendEntry from '../LegendEntry'

interface LegendColorProps {
    color: string
}

const ContainerGroup1 = styled.div`
    border-bottom: 1px solid ${theme.colors.Grey150};
`

const ContainerGroup2 = styled.div`
    padding-top: ${theme.spacings.vertical05};
    ${LegendEntry} {
        font-weight: bold;
    }
`

const makeLegendEntry = (
    answerValue: CombinedAnswer, 
    group: keyof typeof ColorScheme, 
    index: number
) => {
    const color = ColorScheme[group][index][0]
    return (
        <LegendEntry 
            key={color} 
            text={answerValue.text} 
            color={color} 
        />
    )
}

export interface Props {
    className?: string
    answerValuesGroup1: Array<CombinedAnswer>
    answerValuesGroup2: Array<CombinedAnswer>
}

const Legend: React.FunctionComponent<Props> = ({ 
    className = '',
    answerValuesGroup1,
    answerValuesGroup2,
}) => {
    const legendGroup1 = answerValuesGroup1
        .map((answerValue, i) => {
            if (answerValue.value || answerValue["value-other"]) {
                return makeLegendEntry(answerValue, '1', i)
            } else {
                return null
            }
        })
    const legendGroup2 = answerValuesGroup2
        .map((answerValue, i) => {
            if (answerValue.value || answerValue["value-other"]) {
                return makeLegendEntry(answerValue, '2', i)
            } else {
                return null
            }
        })
    return (
        <div className={className}>
            <ContainerGroup1>
                {legendGroup1}
            </ContainerGroup1>
            <ContainerGroup2>
                {legendGroup2}
            </ContainerGroup2>
        </div>
    )
}

const LegendStyled = styled(React.memo(Legend))`
    max-width: 22%;
    margin-top: 2.5rem;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        max-width: 30%;
        font-size: 110%;
    }
`

export default LegendStyled