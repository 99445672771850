const borderRadius1 = '1.2rem'

const fonts = {
    normal: "'Roboto', sans-serif",
    title: "'Arvo', serif",
    subtitle: "'Dosis', sans-serif",
}

const colors = {
    Purple150: '#482856',
    Purple100: '#8F4C98',
    Purple50: '#BC92C1',
    Purple40: '#9A5EA2',
    Grey: '#F4F2F7',
    Grey100: '#CCD4DD',
    PurpleDisabled: '#b6a9bb',
    Grey150: '#707982',
    Cyan: '#00A3DA',
    LightCyan: '#7fc1da',
    Black: '#3A3A3B',
    White: '#FFF',
    OverlayBackground: 'rgba(255, 255, 255, 0.6)',
}

const graphColors = {
    DarkRed: '#A52248',
    DarkBlue: '#005190',
    Turquoise: '#00AEAD',
    Red: '#E72C65',
    Blue: '#3778BB',
    Petrol: '#108BA4',
    Orange: '#FF9622',
    Cyan: '#00A3DA',
    Green: '#74CD50',
    Yellow: '#FFC923',
    Prairie: '#A3C515',
    Grey100: '#CCD4DD',
}

const pageTransitionDuration = 2000
const pageExitTransitionDuration = 700

const buildPageTransition = (
    props: Array<string>, 
    start: number, 
    duration: number,
    baseDuration: number = pageTransitionDuration
) => {
    const dur = duration * baseDuration
    const st = start * baseDuration
    return `transition: ${
        props.map(prop => `${prop} ${dur}ms ${transitions.function} ${st}ms`).join(', ')
    };`
}

const pageTransitionMaker = (
    classBase: string,
    initStates: Array<string>, 
    transStates: Array<string>,
    transitionDuration: number,
) =>
    (
        selectors: Array<string> | null,
        props: Array<[string, string, string]>,
        start: number, 
        duration: number,
    ) => {
        const shared = buildPageTransition(
            props.map(prop => prop[0]), start, duration, transitionDuration)

        const generateClasses = (states: Array<string>) => 
            states.map(
                state => (selectors  || [''])
                    .map(selector => `${classBase}-${state} ${selector}`)
                    .join(', ')
            ).join(', ')

        const initClasses = generateClasses(initStates)
        const transClasses = generateClasses(transStates)

        return `
            ${initClasses} {
                ${props.map(prop => `${prop[0]}: ${prop[1]};`).join('\n')}
            }

            ${transClasses} {
                ${shared}
                ${props.map(prop => `${prop[0]}: ${prop[2]};`).join('\n')}
            }
        `
    }

const buildPageTransitionMaker = (classBase: string) => {
    const enterOrAppearTransition = pageTransitionMaker(
        classBase, ['enter', 'appear'], ['enter-active', 'appear-active'], 
        pageTransitionDuration
    )
    const enterTransition = pageTransitionMaker(classBase, ['enter'], ['enter-active'], pageTransitionDuration)
    const appearTransition = pageTransitionMaker(classBase, ['appear'], ['appear-active'], pageTransitionDuration)
    const exitTransition = pageTransitionMaker(classBase, ['exit'], ['exit-active'], pageExitTransitionDuration)
    
    return ({
        enterTransition,
        enterOrAppearTransition,
        exitTransition,
        appearTransition,
    })
}

const transitions = {
    pageTransitionDuration,
    pageExitTransitionDuration,
    duration1: '200ms',
    function: 'ease-in-out',
    buildPageTransition,
    buildPageTransitionMaker,
}

const clickableMixin = `
    cursor: pointer;
`

const buttonMixin = `
    border-radius: 2.5em;
    text-align: center;
    padding: 0.75em 1em;
    &:hover: {
        background-color: ${colors.Purple50};
        font-size: 105%;
    }
`

const buttonPurpleMixin = `
    ${buttonMixin}    
    color: ${colors.Purple150};
    background-color: ${colors.Purple50};
    font-weight: bold;
`

const buttonWhiteMixin = `
    ${buttonPurpleMixin}
    color: ${colors.Purple100};
    background-color: ${colors.White};
`

const cssTransitionMixin = `
    transition-timing-function: ${transitions.function};
    transition-duration: 2ms;
`

const topBarMixin = `
    border-bottom-left-radius: ${borderRadius1};
    border-bottom-right-radius: ${borderRadius1};
`

const mixins = {
    button: buttonMixin,
    buttonPurple: buttonPurpleMixin,
    buttonWhite: buttonWhiteMixin,
    cssTransition: cssTransitionMixin,
    clickable: clickableMixin,
    topBar: topBarMixin,
}

const spacings = {
    horizontal05: '0.7rem',
    vertical05: '0.6rem',
    verticalRel05: '0.6em',
    vertical1: '1.5rem',
    verticalRel1: '1em',
    horizontal1: '1.5rem',
    vertical2: '2.5rem',
    horizontal2: '2rem',
}

const fontSizes = {
    baseDesktop: 16,
    baseMobile: 13,
    baseSmallMobile: 12,
}

const dimensions = {
    thresholdBigDesktop: 1120,
    thresholdMobile: 740,
    thresholdMobileSmall: 340,
    thresholdMobileVerySmall: 314,
    maxWidth: 1280,
    keyNumberCardWidth: 340,
    keyNumberCardHeight: 216,
    mobilePreviousNextButtonsSize: '2.7rem',
    menuHeight: '5rem',
    hamburger: '3.5rem',
    filtersListPadding: '1.2em',
    menuDrawerWidth: '70%',
    menuDesktopHPaddingPercent: 10,
    borderRadius1,
}

const zIndexes = {
    modal: 10,
    modalOverlay: 9,
    justAboveHeader: 8,
    header: 7
}

export default {
    colors,
    fonts,
    graphColors,
    mixins,
    spacings,
    fontSizes,
    dimensions,
    zIndexes,
    transitions,
}