import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as IconSvg } from '../../images/chiffres-cles/button-share.svg'
import theme from '../../theme'
import ShareModal from './ShareModal'

const Button = styled.button`
    background-color: white;
    border-radius: 1em;
    padding: 0.3em 1em;
    color: black;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,0.5);
    border: none;
    outline: none;
    font-weight: bold;
    font-family: ${theme.fonts.normal};
    font-size: 85%;
    svg {
        margin-left: 0.2em;
        vertical-align: middle;
    }
`

interface Props {
    className?: string
    shareId: string
}

const ShareButton: React.FunctionComponent<Props> = ({ className, shareId }) => {
    const [showingModal, setShowingModal] = useState<boolean>(false)
    const onClick = () => setShowingModal(true)
    const onClose = () => setShowingModal(false)
    return (
        <>
            <Button className={className} onClick={onClick}>
                <div>Partagez <IconSvg /></div>
            </Button>
            {showingModal ?
                <ShareModal onClose={onClose} shareId={shareId} /> : null}
        </>
    )
}

export default ShareButton