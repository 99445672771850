import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { Colors } from './config-vizualisation'
import { AnswerMirrorPositivity } from '../../../models'

const COLOR_SIZE = '1em'

const LegendItem = styled.div`
    display: inline-block;
    margin-right: ${theme.spacings.horizontal1};
`

const LegendItemColor = styled.span`
    display: inline-block;
    background-color: ${props => props.color};
    width: ${COLOR_SIZE};
    height: ${COLOR_SIZE};
    vertical-align: middle;
    margin-right: 0.3em;
`

const LegendItemText = styled.span`
    color: ${theme.colors.Grey150};
    vertical-align: middle;
`

export interface Props {
    className?: string
}

const Legend: React.FunctionComponent<Props> = ({ className }) => {
    return (
        <div className={className}>
            <LegendItem>
                <LegendItemColor color={Colors[AnswerMirrorPositivity.NEGATIVE]} />
                <LegendItemText>À-priori négatifs</LegendItemText>
            </LegendItem>
            <LegendItem>
                <LegendItemColor color={Colors[AnswerMirrorPositivity.POSITIVE]} />
                <LegendItemText>À-priori positifs</LegendItemText>
            </LegendItem>
        </div>
    )
}

export default styled(React.memo(Legend))`
    font-size: 90%;
    background-color: ${theme.colors.Grey};
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
    margin: ${theme.spacings.vertical05} 0;
    border-radius: 0.5em;
`