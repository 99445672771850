import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { Cohort, CohortDisplay } from '../../../models'
import { EvolutionHtml } from '../shared'
import {Palette} from './Themes'

const BORDER_RADIUS = '0.4em'

interface BarProps {
    value: number
    colors: Palette
}

interface PercentageProps {
    colors: Palette
}

const BarCombined = styled.div`
    display: flex;
    flex-direction: row;
    height: 2.1em;
`

export const BarCommon = styled.div<BarProps>`
    width: ${props => props.value}%;
    text-align: center;
`

const BarLeft = styled(BarCommon)`
    border-top-left-radius: ${BORDER_RADIUS};
    border-bottom-left-radius: ${BORDER_RADIUS};
    background-color: ${props => props.colors[0][0]};
`

const BarMiddle = styled(BarCommon)`
    background-color: ${props => props.colors[1][0]};
    color: ${props => props.colors[1][1]};
    border-left: solid 1px ${theme.colors.White};
    border-right: solid 1px ${theme.colors.White};
`

const BarRight = styled(BarCommon)`
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
    background-color: ${props => props.colors[2][0]};
    color: ${props => props.colors[2][1]};
`

const BarContent = styled.div`
    position: relative;
`

const PercentageBase = styled.div<PercentageProps>`
    font-family: ${theme.fonts.title};
    display: inline-block;
    position: relative;
    top: 0.3em;
`

const PercentageLeft = styled(PercentageBase)`
    background-color: ${props => props.colors[0][0]};
    color: ${props => props.colors[0][1]};
`

const PercentageMiddle = styled(PercentageBase)`
    background-color: ${props => props.colors[1][0]};
    color: ${props => props.colors[1][1]};
`

const PercentageRight = styled(PercentageBase)`
    background-color: ${props => props.colors[2][0]};
    color: ${props => props.colors[2][1]};
`


export const CohortText = styled.div`
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
`

const StyledEvolutionHtml = styled(EvolutionHtml)`
    position: absolute;
    bottom: 0;
    width: max-content;
    left: 50%;
    transform: translate(-50%, 110%);
`

export interface Props {
    className?: string
    values: [number, number, number]
    evolutions: [number | null, number | null, number | null]
    cohort: Cohort | null
    colors: Palette
}

const Component: React.FunctionComponent<Props> = ({ 
    values,
    colors,
    cohort,
    evolutions,
    className = '',
}) => {
    return (
        <div className={className}>
            <BarCombined>
                <BarLeft value={values[0]} colors={colors}>
                    <BarContent>
                        <PercentageLeft colors={colors}>{values[0]}%</PercentageLeft>
                        {evolutions[0] !== null ? 
                            <StyledEvolutionHtml value={evolutions[0]} scale={0.8} />: 
                        null}
                    </BarContent>
                </BarLeft>
                <BarMiddle value={values[1]} colors={colors}>
                    <BarContent>
                        <PercentageMiddle colors={colors}>{values[1]}%</PercentageMiddle>
                        {evolutions[1] !== null ? 
                            <StyledEvolutionHtml value={evolutions[1]} scale={0.8} />: 
                        null}
                    </BarContent>
                </BarMiddle>
                <BarRight value={values[2]} colors={colors}>
                    <BarContent>
                        <PercentageRight colors={colors}>{values[2]}%</PercentageRight>
                        {evolutions[2] !== null ? 
                            <StyledEvolutionHtml value={evolutions[2]} scale={0.8} />: 
                        null}
                    </BarContent>
                </BarRight>
            </BarCombined>
            {cohort ? 
                <CohortText>{CohortDisplay[cohort]}</CohortText>: 
                null
            }
        </div>
    )
}

export default styled(React.memo(Component))`
    background-color: ${theme.colors.Grey};
    color: ${theme.colors.Grey150};
    border-bottom-left-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
`