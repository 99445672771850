import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as IconSvg } from '../../images/chiffres-cles/button-details-black.svg'
import { Link } from 'react-router-dom'
import theme from '../../theme'

interface ButtonProps {
    color: string
}

const Button = styled(Link)<ButtonProps>`
    ${theme.mixins.button}
    font-size: 80%;
    border: 1px ${props => props.color} solid;
    color: ${props => props.color};
    path, circle, rect {
        fill: ${props => props.color};
    }
    padding: 0.2em 0.3em;
    width: 7em;
    svg {
        margin-left: 0.35em;
    }
`

interface Props {
    url: string
    color: string
}

const ChiffreCleDetailsButton: React.FunctionComponent<Props> = ({ url, color }) => {
    return (
        <Button to={url} color={color}>En détail <IconSvg /></Button>
    )
}

export default ChiffreCleDetailsButton