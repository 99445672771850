import { Cohort } from "../models"

export const DATA_FILTERS_COHORT_SET = "DATA_FILTERS_COHORT_SET"
export const DATA_FILTERS_WAVE_SET = "DATA_FILTERS_WAVE_SET"
export const DATA_FILTERS_WAVE_COMPARE_SET = "DATA_FILTERS_WAVE_COMPARE_SET"
export const DATA_FILTERS_SHOW_OTHER_COHORT_SET = "DATA_FILTERS_SHOW_OTHER_COHORT_SET"

interface CohortSet {
    type: typeof DATA_FILTERS_COHORT_SET
    payload: Cohort
}

interface WaveSet {
    type: typeof DATA_FILTERS_WAVE_SET
    payload: number
}

interface WaveCompareSet {
    type: typeof DATA_FILTERS_WAVE_COMPARE_SET
    payload: number | null
}

interface ShowOtherCohortSet {
    type: typeof DATA_FILTERS_SHOW_OTHER_COHORT_SET
    payload: boolean
}

export type DataFiltersTypes = CohortSet | WaveSet | WaveCompareSet | ShowOtherCohortSet

export const setDataFiltersCohort = (cohort: Cohort): DataFiltersTypes => {
    return {
        type: DATA_FILTERS_COHORT_SET,
        payload: cohort,
    }
}

export const setWave = (waveIndex: number): DataFiltersTypes => {
    return {
        type: DATA_FILTERS_WAVE_SET,
        payload: waveIndex,
    }
}

export const setWaveCompare = (waveIndex: number | null): DataFiltersTypes => {
    return {
        type: DATA_FILTERS_WAVE_COMPARE_SET,
        payload: waveIndex,
    }
}

export const setShowOtherCohort = (value: boolean): DataFiltersTypes => {
    return {
        type: DATA_FILTERS_SHOW_OTHER_COHORT_SET,
        payload: value,
    }
}

export interface DataFiltersState {
    cohort: Cohort
    wave: number
    waveCompare: number | null
    showOtherCohort: boolean
}

const initialState: DataFiltersState = {
    cohort: Cohort.GRAND_PUBLIC,
    wave: -1,
    waveCompare: null,
    showOtherCohort: false,
}

export function dataFiltersReducer(
    state = initialState,
    action: DataFiltersTypes
): DataFiltersState {
    switch (action.type) {
        case DATA_FILTERS_COHORT_SET:
            return {
                ...state,
                cohort: action.payload
            }
        case DATA_FILTERS_WAVE_SET:
            return {
                ...state,
                wave: action.payload,
                // Reinitialise waveCompare if same as the new wave selected
                waveCompare: state.waveCompare === action.payload ? null: state.waveCompare
            }
        case DATA_FILTERS_WAVE_COMPARE_SET:
            return {
                ...state,
                waveCompare: action.payload
            }
        case DATA_FILTERS_SHOW_OTHER_COHORT_SET:
            return {
                ...state,
                showOtherCohort: action.payload
            }
        default:
            return state
    }
}
