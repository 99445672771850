import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle } from '../shared'
import { CSSTransition } from 'react-transition-group'
import {Container as TooltipContainer} from './Tooltip'
import theme from '../../../theme'
import Graph, { ArcPath, Dot, FakeRoundedRect } from './Graph'

const Container = styled.div`
    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }
    ${ArcPath}:nth-child(1), ${FakeRoundedRect}:first-child {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.05)}
    }
    ${ArcPath}:nth-child(2) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.05, 0.05)}
    }
    ${ArcPath}:nth-child(3) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.1, 0.05)}
    }
    ${ArcPath}:nth-child(4) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.15, 0.05)}
    }
    ${ArcPath}:nth-child(5) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.2, 0.05)}
    }
    ${ArcPath}:nth-child(6) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.25, 0.05)}
    }
    ${ArcPath}:nth-child(7) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.3, 0.05)}
    }
    ${ArcPath}:nth-child(8) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.35, 0.05)}
    }
    ${ArcPath}:nth-child(9) {
        ${theme.transitions.buildPageTransition(['opacity'], 0.4, 0.05)}
    }
    ${ArcPath}:nth-child(10), ${FakeRoundedRect}:last-child  {
        ${theme.transitions.buildPageTransition(['opacity'], 0.45, 0.05)}
    }
    ${TooltipContainer}, ${Dot} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.5, 0.2)}
    }

    .echelle0a10-appear {
        ${VisualizationTitle}, ${ArcPath}, ${TooltipContainer}, ${Dot}, ${FakeRoundedRect} {
            opacity: 0;
        }
    }

    .echelle0a10-appear-active {
        ${VisualizationTitle}, ${ArcPath}, ${TooltipContainer}, ${Dot}, ${FakeRoundedRect} {
            opacity: 1;
        }
    }
`

const VisualizationEchelle0A10: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort, cohortOther, width
}) => {
    const answerValue = answerValues[0]
    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="echelle0a10"
            >
                <div>
                    <VisualizationTitle>{question.text}</VisualizationTitle>
                    <Graph
                        value={answerValue.value}
                        valueOther={answerValue["value-other"]}
                        evolution={answerValue.evolution}
                        evolutionOther={answerValue["evolution-other"]}
                        width={width}
                        cohort={cohort}
                        cohortOther={cohortOther}
                    />
                </div>
            </CSSTransition>
        </Container>
    )
}

export default VisualizationEchelle0A10