import React from 'react'
import sortBy from 'lodash.sortby'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle, EvolutionHtml } from '../shared'
import Legend from './Legend'
import { CSSTransition } from 'react-transition-group'
import Graph, { BarContainer, Title as GraphTitle, Percentage } from './Graph'
import theme from '../../../theme'

const Container = styled.div`
    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }

    ${BarContainer} {
        ${theme.transitions.buildPageTransition(['width'], 0.2, 0.5)}
    }

    ${GraphTitle}, ${Percentage}, ${EvolutionHtml}, ${Legend} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.7, 0.2)}
    }

    .choixMultiples-appear {
        ${VisualizationTitle}, ${GraphTitle}, ${Percentage}, ${EvolutionHtml}, ${Legend} {
            opacity: 0;
        }

        ${BarContainer} {
            width: 0;
        }
    }

    .choixMultiples-appear-active {
        ${VisualizationTitle}, ${GraphTitle}, ${Percentage}, ${EvolutionHtml}, ${Legend} {
            opacity: 1;
        }

        ${BarContainer} {
            width: 100%;
        }
    }
`

const VisualizationChoixMultiples: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort, cohortOther
}) => {
    const graphs = sortBy(answerValues, (answerValue) => -answerValue.value)
    .map((answerValue) => {
            return (
                <Graph 
                    title={answerValue.text}
                    value={answerValue.value} 
                    valueOther={answerValue["value-other"]} 
                    valueMax={answerValue["value-max"]}
                    evolution={answerValue.evolution}
                    evolutionOther={answerValue["evolution-other"]}
                    key={answerValue.text}
                />
            )
        })

    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="choixMultiples"
            >
                <div>
                    <VisualizationTitle>{question.text}</VisualizationTitle>
                    {cohortOther ? 
                        <Legend cohort1={cohort} cohort2={cohortOther} /> 
                    : null}
                    {graphs}
                </div>
            </CSSTransition>
        </Container>
    )
}

export default React.memo(VisualizationChoixMultiples)