import { put, takeLatest, all } from 'redux-saga/effects'
import { GsheetDataTypes, GSHEET_DATA_LOAD, gsheetDataLoadSuccess, gsheetDataLoadError } from './gsheet-data'
import { questionSchema, answerSchema, waveSchema, answerValueSchema, Wave, keyNumberSchema, videoSchema } from '../models'
import config from '../config'
import { DbSpec, loadSeveralCollectionsAndErrors, MultipleResultsWithErrors } from '../utils/db-core'
import { fetchRemoteGsheet, FetchRemoteGsheetConfig, inflateGsheetFirstRow, validateYup, fetchInMemory } from '../utils/db-operations'

import answersJson from '../production-data/Answers.json'
import keyNumbersJson from '../production-data/Chiffres-Clés.json'
import questionsJson from '../production-data/Questions.json'
import wavesJson from '../production-data/Vagues.json'
import videosJson from '../production-data/Videos.json'
import wave1Json from '../production-data/Vague1.json'
import wave2Json from '../production-data/Vague2.json'
import wave3Json from '../production-data/Vague3.json'
import wave4Json from '../production-data/Vague4.json'

const PRODUCTION_DATA = {
    "Questions": questionsJson,
    "Answers": answersJson,
    "Vagues": wavesJson,
    "Chiffres-Clés": keyNumbersJson,
    "Videos": videosJson,
    "Vague1": wave1Json,
    "Vague2": wave2Json,
    "Vague3": wave3Json,
    "Vague4": wave4Json,
}

interface UnedicGsheetConfig extends FetchRemoteGsheetConfig {
    sheetId: keyof typeof PRODUCTION_DATA
}

const makeFetch = (fetchConfig: UnedicGsheetConfig) => {
    if (config.ENVIRONMENT === 'staging') {
        console.log('fetch remote')
        return fetchRemoteGsheet.bind(null, fetchConfig)
    } else {
        console.log('fetch local')
        return fetchInMemory.bind(null, PRODUCTION_DATA[fetchConfig.sheetId])
    }
}

const staticDbConfig: FetchRemoteGsheetConfig = {
    spreadsheetId: config.STATIC_GSHEET_ID,
    apiKey: config.GOOGLE_API_KEY,
    sheetId: ''
}

const wavesDbConfig: FetchRemoteGsheetConfig = {
    spreadsheetId: config.WAVES_GSHEET_ID,
    apiKey: config.GOOGLE_API_KEY,
    sheetId: ''
}

const dbSpec: DbSpec = {
    collections: {
        questions: {
            fetch: makeFetch({...staticDbConfig, sheetId: 'Questions'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, questionSchema)
        },
        answers: {
            fetch: makeFetch({...staticDbConfig, sheetId: 'Answers'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, answerSchema)
        },
        waves: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Vagues'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, waveSchema)
        },
        keyNumbers: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Chiffres-Clés'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, keyNumberSchema)
        },
        videos: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Videos'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, videoSchema)
        },
        wave1: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Vague1'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, answerValueSchema)
        },
        wave2: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Vague2'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, answerValueSchema)
        },
        wave3: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Vague3'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, answerValueSchema)
        },
        wave4: {
            fetch: makeFetch({...wavesDbConfig, sheetId: 'Vague4'}),
            inflate: inflateGsheetFirstRow,
            validate: validateYup.bind(this, answerValueSchema)
        },
    }
}

const WAVE_SHEET_IDS = ['wave1', 'wave2', 'wave3', 'wave4'] 

function* getAllGsheets(action: GsheetDataTypes) {
    const baseData: MultipleResultsWithErrors = yield loadSeveralCollectionsAndErrors(dbSpec, [
        'questions', 'answers', 'waves', 'keyNumbers', 'videos'
    ])

    let wavesData: MultipleResultsWithErrors = {}
    if (baseData.waves[0] === null) {
        const waveCollectionIds = WAVE_SHEET_IDS.slice(0, baseData.waves[1].length)
        wavesData = yield loadSeveralCollectionsAndErrors(dbSpec, waveCollectionIds)
    }

    const allData: MultipleResultsWithErrors = {
        ...baseData,
        ...wavesData
    }

    const loadedData: {[key : string]: any} = {}
    const errors: Array<[string, Error]> = []
    Object.entries(allData).forEach(([collectionId, [error, validatedData]]) => {
        if (validatedData) {
            loadedData[collectionId] = validatedData
        }
        if (error) {
            errors.push([collectionId, error])
        }
    })

    if (errors.length === 0) {
        yield put(gsheetDataLoadSuccess({
            questions: loadedData.questions,
            answers: loadedData.answers,
            keyNumbers: loadedData.keyNumbers,
            videos: loadedData.videos,
            waves: loadedData.waves.map((wave: Wave, i: number) => ({
                'title': wave.title,
                'date': wave.date,
                'description': wave.description,
                'methodologie': wave.methodologie,
                'answer-values': loadedData[WAVE_SHEET_IDS[i]],
            }))
        }))
    } else {
        yield put(gsheetDataLoadError(errors))
    }
}

function* gsheetLoadSaga() {
    yield takeLatest(GSHEET_DATA_LOAD, getAllGsheets)
}

export default function* rootSaga() {
    yield all([
        gsheetLoadSaga(),
    ])
}