import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { ReactComponent as IconComparisonSvg } from '../../../images/resultats-details/icon-comparison.svg'
import { ReactComponent as IconResultsSvg } from '../../../images/resultats-details/icon-results.svg'
import { ReactComponent as IconThemesSvg } from '../../../images/resultats-details/icon-themes.svg'
import ShowOtherCohortCheckbox from './ShowOtherCohortCheckbox'
import { Question } from '../../../models'
import WaveSelector from './WaveSelector'
import Chapters from './Chapters'
import { CSSTransition } from 'react-transition-group'

const {
    enterOrAppearTransition: enterOrAppearOtherCohortTransition, 
    exitTransition: exitOtherCohortTransition} = theme.transitions.buildPageTransitionMaker('.ShowOtherCohortCheckbox')

export const Container = styled.div`
    color: ${theme.colors.White};
    background-color: ${theme.colors.Purple150};
    min-width: 25%;
    width: 25%;
    border-top-left-radius: ${theme.dimensions.borderRadius1};
    border-bottom-left-radius: ${theme.dimensions.borderRadius1};
    font-size: 92%;
    padding: 0 ${theme.spacings.horizontal05};
    overflow: auto;
    padding-bottom: ${theme.spacings.vertical1};

    ${enterOrAppearOtherCohortTransition(null, [['opacity', '0', '1']], 0.7, 0.3)}
    ${exitOtherCohortTransition(null, [['opacity', '1', '0']], 0.7, 0.3)}
`

const SectionTitle = styled.h2`
    color: ${theme.colors.White};
    text-align: center;
    margin-top: ${theme.spacings.vertical1};
    :first-child {
        margin-top: ${theme.spacings.verticalRel05};
    }
`

const SectionTitleIconContainer = styled.div`
    height: 0;
    overflow: visible;    

    svg {
        position: relative;
        bottom: 0.7em;
    }

    svg * {
        fill: ${theme.colors.White};
    }
`

const SectionTitleText = styled.div`
    text-transform: uppercase;
    background-color: ${theme.colors.Purple150};
    /* to position the text above the line */
    position: relative;
    display: inline-block;
    padding: 0 0.2em;
    font-weight: normal;
    font-size: 65%;
    letter-spacing: 0.15em;
`

const SectionTitleLine = styled.div`
    border-bottom: 2px solid ${theme.colors.White};
    position: relative;
    top: 0.8em;
`

const Section = styled.div``

export interface Props {
    question: Question
    showOtherCohortCheckbox: boolean
}

const FiltersDesktop: React.FunctionComponent<Props> = ({ question, showOtherCohortCheckbox }) => {
    return (
        <Container>
            <SectionTitle>
                <SectionTitleIconContainer><IconResultsSvg /></SectionTitleIconContainer>
                <SectionTitleLine></SectionTitleLine>
                <SectionTitleText>Résultats</SectionTitleText>
            </SectionTitle>

            <Section>
                <WaveSelector />
            </Section>

            <SectionTitle>
                <SectionTitleIconContainer><IconThemesSvg /></SectionTitleIconContainer>
                <SectionTitleLine></SectionTitleLine>
                <SectionTitleText>Thèmes</SectionTitleText>
            </SectionTitle>

            <Section>
                <Chapters question={question} />
            </Section>

            <CSSTransition
                in={showOtherCohortCheckbox}
                appear={showOtherCohortCheckbox}
                timeout={theme.transitions.pageTransitionDuration}
                classNames="ShowOtherCohortCheckbox"
                unmountOnExit
            >
                <div>
                <SectionTitle>
                    <SectionTitleIconContainer><IconComparisonSvg /></SectionTitleIconContainer>
                    <SectionTitleLine></SectionTitleLine>
                    <SectionTitleText>Comparer</SectionTitleText>
                </SectionTitle>

                <Section>
                    <ShowOtherCohortCheckbox/>
                </Section>
                </div>
            </CSSTransition>
        </Container>
    )
}

export default FiltersDesktop