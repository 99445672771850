import React from 'react'
import styled from 'styled-components/macro'
import isEqual from 'lodash.isequal'
import WaveSelect from '../WaveSelect'
import theme from '../../../theme'
import { useSelector } from 'react-redux'
import { selectWaves, selectDataFiltersWave, selectDataFiltersWaveCompare } from '../../../store/selectors'
import { setWave, setWaveCompare } from '../../../store/data-filters'
import { EvolutionHtml } from '../shared'

const SelectLabel = styled.label<{isDisabled?: boolean}>`
    display: block;
    margin-top: ${theme.spacings.verticalRel05};
    :first-child {
        margin-top: 0;
    }
    margin-bottom: 0.2em;
    ${props => props.isDisabled ? 
        `color: ${theme.colors.Purple50}`: 
        ``}
`

const WaveCompareLegend = styled.div`
    font-style: italic;
    text-align: center;
    padding-top: 0.5em;
`

const StyledEvolutionHtml = styled(EvolutionHtml)`
    background-color: ${theme.colors.White};
    color: ${theme.colors.Purple150};
    svg * {
        fill: ${theme.colors.Purple150};
    }
`

const WaveSelector: React.FunctionComponent = () => {
    const waves = useSelector(selectWaves, isEqual)
    const waveIndex = useSelector(selectDataFiltersWave)
    const waveCompareIndex = useSelector(selectDataFiltersWaveCompare)
    const wavesCompare = waves
        // We can only compare to previous waves
        .filter((_, i) => i < waveIndex)
    const isCompareDisabled = wavesCompare.length === 0

    return (
        <>
            <SelectLabel>Afficher la vague</SelectLabel>
            <WaveSelect 
                variant="white" 
                waves={waves} 
                value={waveIndex}
                actionCreator={setWave}
            /> 
            <SelectLabel isDisabled={isCompareDisabled}>Évolution par rapport à</SelectLabel>
            <WaveSelect 
                variant="purple" 
                waves={wavesCompare} 
                isClearable={true}
                value={waveCompareIndex}
                actionCreator={setWaveCompare}
                placeholder={isCompareDisabled === true ? 'Pas de vague antérieure' : 'Sélectionnez...'}
                height={24}
            />
            <WaveCompareLegend>
                {waveCompareIndex !== null ? 
                    <><StyledEvolutionHtml label="X" arrow="up" /> évolution en points</> 
                    : null
                }
            </WaveCompareLegend>
        </>
    )
}

export default WaveSelector