import React from 'react'
import styled from 'styled-components/macro'
import sortBy from 'lodash.sortby'
import { VisualizationComponentProps, VisualizationTitle, EvolutionHtml } from '../shared'
import { CSSTransition } from 'react-transition-group'
import theme from '../../../theme'
import Legend from './Legend'
import Graph, { Title, Percentage } from './Graph'
import { NSPP } from './config-vizualisation'

const Container = styled.div`

    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }

    ${Title} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.2, 0.2)}
    }

    ${Legend}, ${EvolutionHtml}, ${Percentage} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.6, 0.2)}
    }

    .nuageMots-appear {
        ${VisualizationTitle}, ${Title}, ${Legend}, ${EvolutionHtml}, ${Percentage} {
            opacity: 0;
        }
    }

    .nuageMots-appear-active {
        ${VisualizationTitle}, ${Title}, ${Legend}, ${EvolutionHtml}, ${Percentage} {
            opacity: 1;
        }
    }
`

const GraphsContainer = styled.div`
    text-align: center;
    padding-top: ${theme.spacings.vertical05};
`

const VisualizationNuageDeMots: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, answerValues, cohort, cohortOther
}) => {
    // NSPP must be at the end
    const graphs = sortBy(answerValues, (answerValue) =>
        answerValue["nuage-mots"]!.toLowerCase() === NSPP ? Infinity : -answerValue.value
    )
        .map(answerValue => (
            <Graph 
                value={answerValue.value}
                key={answerValue["nuage-mots"]}
                valueOther={answerValue["value-other"]}
                evolution={answerValue.evolution}
                evolutionOther={answerValue["evolution-other"]}
                title={answerValue["nuage-mots"]!}
            />
        ))
    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="nuageMots"
            >
                <div>
                    <VisualizationTitle>{question.text}</VisualizationTitle>
                    {cohortOther ? <Legend cohort={cohort} cohortOther={cohortOther} />: null}
                    <GraphsContainer>
                        {graphs}
                    </GraphsContainer>
                </div>
            </CSSTransition>
        </Container>
    )
}

export default VisualizationNuageDeMots