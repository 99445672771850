import config from "./config"

export const ACCUEIL_ID = 'accueil'
export const CHIFFRES_ID = 'chiffres'
export const RESULTATS_ID = 'resultats'
export const METHODOLOGIE_ID = 'methodologie'

export type PageId = typeof ACCUEIL_ID | typeof CHIFFRES_ID | typeof RESULTATS_ID | typeof METHODOLOGIE_ID

interface PageConfig {
    title: string
    href: (...params: any[]) => string
}

type ConfigPages = { [pageId: string]: PageConfig }

const CONFIG_PAGES: ConfigPages = {
    [ACCUEIL_ID]: {
        title: 'Accueil',
        href: () => `${config.ROOT_URL}/`
    },
    [CHIFFRES_ID]: {
        title: 'Les chiffres-clés',
        href: () => `${config.ROOT_URL}/${CHIFFRES_ID}`
    },
    [RESULTATS_ID]: {
        title: 'Résultats détaillés',
        href: (questionId?: string) => {
            const root = `${config.ROOT_URL}/${RESULTATS_ID}`
            if (questionId) {
                return `${root}/${questionId}`
            } else {
                return root
            }
        }
    },
    [METHODOLOGIE_ID]: {
        title: 'Méthodologie',
        href: () => `${config.ROOT_URL}/${METHODOLOGIE_ID}`
    },
}

export const buildShareUrl = (shareHandle: string) => 
    `${config.SITE_URL}/share/${shareHandle}.html`

export default CONFIG_PAGES