import React from 'react'
import styled from 'styled-components/macro'
import ChiffreCleDetailsButton from './ChiffreCleDetailsButton'
import ShareButton from './ShareButton'
import theme from '../../theme'
import bgDownSrc from '../../images/chiffres-cles/background-down-@2x.png'
import bgUpSrc from '../../images/chiffres-cles/background-up-@2x.png'
import { KeyNumbersPictos } from '../../models'
import { Link } from 'react-router-dom'

const PICTO_HEIGHT = 216
const PICTO_WIDTH = 117

interface CardParams {
    gradient: [string, string],
    color: string,
}

const CARD_PARAMS: Array<CardParams> = [
    {color: theme.colors.White, gradient: ['#00AEAD', '#108BA4']},
    {color: theme.colors.Black, gradient: ['#FFC923', '#FF9622']},
    {color: theme.colors.White, gradient: ['#E72C65', '#A52248']},
    {color: theme.colors.Black, gradient: ['#74CD50', '#4BAE32']},
    {color: theme.colors.Black, gradient: ['#FF9622', '#D47000']},
]

const Container = styled.div<CardParams>`
    height: ${theme.dimensions.keyNumberCardHeight}px;
    width: ${theme.dimensions.keyNumberCardWidth}px;
    color: ${props => props.color};
    background: linear-gradient(135deg, ${props => props.gradient[0]} 50%, ${props => props.gradient[1]} 100%);
    /* To allow positioning the background and share button */
    position: relative;
    border-radius: ${theme.dimensions.borderRadius1};

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        margin-bottom: ${theme.spacings.vertical1};
        width: calc(100% - 2*${theme.spacings.horizontal05});
        margin-left: ${theme.spacings.horizontal05};
        margin-right: ${theme.spacings.horizontal05};
    }

    @media (max-width: ${theme.dimensions.thresholdMobileSmall}px) {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
`

interface ContainerBackgroundProps {
    background: "up" | "down",
}

const ContainerBackground = styled.div<ContainerBackgroundProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent no-repeat center center
        ${props => props.background === "up" ? 
            `url("${bgUpSrc}")` : `url("${bgDownSrc}")`};
    background-size: 100% auto;
    pointer-events: none;
`

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
`

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.2rem;
    padding-right: 0;
`

const Title = styled.div<{color: string}>`
    line-height: 1em;
    font-family: ${theme.fonts.title};
    font-size: 210%;
    -webkit-text-stroke: 2px ${props => props.color};
    -webkit-text-fill-color: transparent;
    em {
        font-style: normal;
        font-size: 150%;
    }
`

const Body = styled.div<{color: string}>`
    margin-bottom: ${theme.spacings.vertical05};
    color: ${props => props.color};
    line-height: 1.15em;
`

const PictoContainer = styled.div<CardParams>``

const StyledShareButton = styled(ShareButton)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: transform ${theme.transitions.duration1};
    transform: translate(-50%, 25%);
    &:hover {
        transform: translate(-50%, 25%) scale(1.05);
    }
`

const InfoLink = styled(Link)`
    transition: transform ${theme.transitions.duration1};
    &:hover {
        transform: scale(1.02) translate(2%, -2%);
    }
`

interface Props {
    index: number
    title: string
    body: string
    detailsUrl: string | null
    shareId: string
    picto: string
    className?: string
}

const ChiffreCle: React.FunctionComponent<Props> = ({ title, body, picto, detailsUrl, shareId, index, className }) => {
    const cardParams = CARD_PARAMS[index % CARD_PARAMS.length]
    const background = (index % 3 === 2) ? "down" : "up"
    const PictoSvg = KeyNumbersPictos[picto as keyof typeof KeyNumbersPictos][cardParams.color]
    const infoContainerContent = (
        <>
        <Title color={cardParams.color} dangerouslySetInnerHTML={{__html: title}}></Title>
        <Body color={cardParams.color} dangerouslySetInnerHTML={{__html: body}}></Body>
        </>
    )
    let infoContainer = infoContainerContent
    if (detailsUrl) {
        infoContainer = (
            <InfoLink to={detailsUrl}>
                {infoContainerContent}
            </InfoLink>
        )
    }
    return (
        <Container className={className} {...cardParams}>
            <ContainerBackground background={background} />
            <MainContainer>
                <InfoContainer>
                    {infoContainer}
                    {detailsUrl !== null ? 
                        <ChiffreCleDetailsButton url={detailsUrl} color={cardParams.color} /> 
                        : null
                    }
                </InfoContainer>
                <PictoContainer {...cardParams}>
                    <PictoSvg height={PICTO_HEIGHT} width={PICTO_WIDTH} />
                </PictoContainer>
            </MainContainer>
            {/* <StyledShareButton shareId={shareId} /> */}
        </Container>
    )
}

export default styled(ChiffreCle)``