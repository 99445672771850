import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { selectGsheetDataLoadStatus, selectGsheetData, selectGsheetDataErrors } from '../../store/selectors'
import { RequestStatus } from '../../models'
import ErrorsDisplay from './ErrorsDisplay'

const Container = styled.div`
    height: 100%;
    overflow: auto;

    .loader {
        &-main {
          position: fixed;
          bottom: 0;
          right: 0;
          z-index: 4000;
          width: 100%;
          height: 100vh;
          display: flex;
          flex-flow: wrap;
          align-items: center;
          justify-content: center;
        }
        .inner-loader {
          width: 80px;
          height: 80px;
        }
      }.cmp-loader {
    position: relative;
    margin: auto;
    width: 80px;
    height: 80px;
    div {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      animation: anim-loader 1.2s linear infinite;
      -webkit-animation: anim-loader 1.2s linear infinite;    &:nth-child(1) {
        top: 8px;
        left: 8px;
        animation-delay: 0s;
        background-color: #8f4c98;
      }
      &:nth-child(2) {
        top: 8px;
        left: 32px;
        animation-delay: -0.4s;
        background-color: #e72c65;
      }
      &:nth-child(3) {
        top: 8px;
        left: 56px;
        animation-delay: -0.8s;
        background-color: #008c88;
      }
      &:nth-child(4) {
        top: 32px;
        left: 8px;
        animation-delay: -0.4s;
        background-color: #ffc923;
      }
      &:nth-child(5) {
        top: 32px;
        left: 32px;
        animation-delay: -0.8s;
        background-color: #a3c515;
      }
      &:nth-child(6) {
        top: 32px;
        left: 56px;
        animation-delay: -1.2s;
        background-color: #00a6c2;
      }
      &:nth-child(7) {
        top: 56px;
        left: 8px;
        animation-delay: -0.8s;
        background-color: #136775;
      }
      &:nth-child(8) {
        top: 56px;
        left: 32px;
        animation-delay: -1.2s;
        background-color: #a52248;
      }
      &:nth-child(9) {
        top: 56px;
        left: 56px;
        animation-delay: -1.6s;
        background-color: #482856;
      }
    }
  }@keyframes anim-loader {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  @-webkit-keyframes anim-loader {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`

interface Props {
    fontsLoaded: boolean
}

const LoadingData: React.FunctionComponent<Props> = ({ children, fontsLoaded }) => {
    const loadingStatus = useSelector(selectGsheetDataLoadStatus)
    const gsheetErrors = useSelector(selectGsheetDataErrors)
    const gsheetData = useSelector(selectGsheetData)

    if (fontsLoaded && loadingStatus === RequestStatus.DONE) {
        if (gsheetData === null) {
            const content = [
                <h1 key="h1">Les spreadsheets contiennent des erreurs</h1>,
                ...gsheetErrors.map(([label, error]) => (
                    <ErrorsDisplay error={error} label={label} key={label} />
                ))
            ]
            return <Container>{content}</Container>
        } else {
            return <>{children}</>
        }
    }

    return (
        <Container>
            <div className="loader loader-main" id="mainLoader">
                <div className="inner-loader">
                    <div className="cmp-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </Container>
    )
    
}

export default LoadingData