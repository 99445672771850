import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { EvolutionHtml } from '../shared'
import { Colors, ColorsNSPP, NSPP } from './config-vizualisation'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

type Orientation = 'top' | 'bottom'

const APPEAR_CIRCLES_TIMEOUT = theme.transitions.pageTransitionDuration * 0.4
const APPEAR_CIRCLES_DURATION = 0.4

const EVOLUTION_SCALE = 0.75
const FONT_SIZE_FUNC = (value: number) => (80 + Math.log(1 + value * 0.06) * 200)
const CIRCLE_SIZE_FUNC = (value: number) => (2 + Math.log(1 + value * 0.06) * 10)


const StyledEvolutionHtml = styled(EvolutionHtml)<{orientation: Orientation}>`
    position: absolute;
    right: -0.2em;
    ${props => props.orientation === 'top' ? `
        bottom: 0.15em;
    `: `
        top: 0.15em;
    `}    
    transform: translateX(100%);
`

export const Percentage = styled.div<{value: number, orientation: Orientation, color: string}>`
    font-family: ${theme.fonts.title};
    font-size: ${props => FONT_SIZE_FUNC(props.value)}%;
    width: 100%;
    text-align: center;
    display: inline-block;
    position: absolute;
    ${props => props.orientation === 'top' ? `
        bottom: 1%;
    `: `
        top: 0%;
    `}
    ${props => props.value > 0 ? `
        color: ${theme.colors.White};
    `: `
        color: ${props.color};
    `};
    left: 0;
`

const CircleContainer = styled.div<{width: number, height: number}>`
    width: ${props => props.width}em;
    height: ${props => props.height}em;
    /* Position the evolution indicator */
    position: relative;
`

export const ColorCircle = styled.div<{color: string, value: number, size: number, orientation: Orientation, transitionStatus: TransitionStatus}>`
    position: absolute;
    ${props => props.orientation === 'top' ? `
        bottom: 0;
    `:`
        top: 0;
    `}
    left: 50%;
    transform: translateX(-50%);

    ${props => props.value > 0 ? `
        background-color: ${props.color};
    `: ``};

    ${theme.transitions.buildPageTransition(['height', 'width', 'border-radius'], 0, APPEAR_CIRCLES_DURATION)}

    ${props => {
        switch(props.transitionStatus) {
            case 'exited':
            case 'entering':
                return `
                    width: 0;
                    height: 0;
                    border-radius: 0;
                `
            default:
                return `
                    width: ${props.size}em; 
                    height: ${props.size / 2}em;
                    border-radius: ${props.size}em;
                `
        }
    }}

    ${props => props.orientation === 'top' ? `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    `:`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    `}
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Title = styled.div<{value: number}>`
    text-align: center;
    max-width: ${props => CIRCLE_SIZE_FUNC(props.value) + 1}em;
    min-width: 8em;
    font-size: 85%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 105%;
    }
`

export interface Props {
    value: number
    valueOther: number | null
    evolution: number | null
    evolutionOther: number | null
    title: string
    className?: string
}

const Graph: React.FunctionComponent<Props> = ({ 
    value,
    valueOther,
    evolution,
    evolutionOther,
    title,
    className = '',
}) => {
    const widthTop: number = CIRCLE_SIZE_FUNC(value)
    const colorScheme = title.toLowerCase() === NSPP ? ColorsNSPP : Colors
    let widthBottom: number | null = null
    let widthContainer: number = widthTop
    if (valueOther !== null) {
        widthBottom = CIRCLE_SIZE_FUNC(valueOther)
        widthContainer = Math.max(widthTop, widthBottom)
    }

    return (
        <div className={className}>
            <InnerContainer>
                <CircleContainer width={widthContainer} height={widthTop / 2}>
                    <Transition
                        in={true}
                        appear={true}
                        timeout={APPEAR_CIRCLES_TIMEOUT}
                    >
                        {transitionStatus =>
                            <ColorCircle color={colorScheme[0]} value={value} orientation="top" size={widthTop} transitionStatus={transitionStatus} />
                        }
                    </Transition>
                    <Percentage value={value} orientation="top" color={colorScheme[0]}>{value}%</Percentage>
                    {evolution !== null ? 
                        <StyledEvolutionHtml scale={EVOLUTION_SCALE} value={evolution} orientation="top" />
                    : null}
                </CircleContainer>
                {valueOther !== null ? 
                    <CircleContainer width={widthContainer} height={widthBottom! / 2}>
                        <Transition
                            in={true}
                            appear={true}
                            timeout={APPEAR_CIRCLES_TIMEOUT}
                        >
                            {transitionStatus => 
                                <ColorCircle color={colorScheme[1]} value={valueOther} orientation="bottom" size={widthBottom!} transitionStatus={transitionStatus} />
                            }
                        </Transition>
                        <Percentage value={valueOther} orientation="bottom" color={colorScheme[1]}>{valueOther}%</Percentage>
                        {evolutionOther !== null ? 
                            <StyledEvolutionHtml scale={EVOLUTION_SCALE} value={evolutionOther} orientation="bottom" />
                        : null}
                    </CircleContainer>
                : null}
                <Title value={value}>{title}</Title>
            </InnerContainer>
        </div>
    )
}

export default styled(React.memo(Graph))`
    display: inline-block;
    padding: 0.5em 2.2em;
    padding-left: 0.3em;
`