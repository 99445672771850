import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import {ReactComponent as SelectorSvg} from '../../images/navigation sidebar/selector-updown.svg'
import { setDataFiltersCohort } from '../../store/data-filters'
import { useDispatch, useSelector } from 'react-redux'
import { selectDataFilterCohort } from '../../store/selectors'
import { Cohort } from '../../models'
import findIndex from 'lodash.findindex'

const SELECTOR_ITEMS = [
    {
        cohort: Cohort.GRAND_PUBLIC,
        title: "l’ensemble des Français",
    },
    {
        cohort: Cohort.CHOMEURS,
        title: "les demandeurs d’emploi",
    },
]

const TitleText = styled.div`
    display: inline-block;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: block;
    }
`

export const Container = styled.h1`
    margin: ${theme.spacings.vertical1} auto;
    margin-bottom: ${theme.spacings.vertical2};
    max-width: ${theme.dimensions.maxWidth}px;
    text-align: center;
    font-family: ${theme.fonts.title};
    font-size: 125%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        text-align: left;
        padding-left: ${theme.spacings.horizontal05};
        margin-top: ${theme.spacings.vertical05};
        margin-bottom: ${theme.spacings.vertical05};
    }
`

const StyledSelectorSvg = styled(SelectorSvg)`
    margin-right: 0.2em;
    position: relative;
    right: 0.2em;
    top: 0.1em;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        left: 0.2em;
        top: 0.2em;
    }
`

const SelectorItems = styled.div`
    display: flex;
    flex-direction: column;
    height: 1.2em;
    margin: 0 0.3em;
    overflow: hidden;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        margin: 0;
    }
`

const SelectorItem = styled.div<{
    selectedIndex: number
}>`
    ${theme.mixins.clickable}
    color: ${theme.colors.Cyan};
    display: inline-block;
    transition: transform 200ms ${theme.transitions.function};
    ${props => 
        `transform: translateY(-${props.selectedIndex * 100}%);`
    }
`

const SelectorInnerContainer = styled.div`
    display: inline-block;
`

const SelectorContainer = styled.span`
    display: inline-block;
    &:hover {
        ${SelectorItem} {
            color: ${theme.colors.LightCyan};
        }
        ${StyledSelectorSvg} * {
            fill: ${theme.colors.LightCyan};
        }
    }
`

const CohortSelector = () => {
    const dispatch = useDispatch()
    const currentCohort = useSelector(selectDataFilterCohort)
    const selectedIndex = findIndex(SELECTOR_ITEMS, 
        ({cohort}) => cohort === currentCohort)

    const _onSelectorClick = () => {
        let cohort: Cohort = Cohort.GRAND_PUBLIC
        if (currentCohort === Cohort.GRAND_PUBLIC) {
            cohort = Cohort.CHOMEURS
        }
        dispatch(setDataFiltersCohort(cohort))
    }

    const selectorItemsElems = SELECTOR_ITEMS.map(({cohort, title}) => (
        <SelectorItem 
            selectedIndex={selectedIndex}
            key={cohort}
        >
            {title}
        </SelectorItem>
    ))

    return (
        <Container>
            <TitleText>Quel regard</TitleText>
            <SelectorContainer onClick={_onSelectorClick}>
                <SelectorInnerContainer>
                    <SelectorItems>
                        {selectorItemsElems}
                    </SelectorItems>
                </SelectorInnerContainer>
                <StyledSelectorSvg/>
            </SelectorContainer>
            <TitleText>portent-ils sur le chômage ?</TitleText>
        </Container>
    )
}

export default CohortSelector