import React from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components/macro'
import MenuDesktop, {Container as MenuDesktopContainer} from './MenuDesktop'
import MenuMobile, {Container as MenuMobileContainer} from './MenuMobile'
import theme from '../../theme'
import { selectCurrentPage } from '../../store/selectors'

const Container = styled.div`
    ${MenuMobileContainer} {
        display: none;
    }
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        /* No height because we don't want the div to take space 
        in the DOM flow */
        height: 0;
        ${MenuMobileContainer} {
            display: block;
        }
        ${MenuDesktopContainer} {
            display: none;
        }
    }
`

const Menu: React.FunctionComponent = () => {
    const currentPage = useSelector(selectCurrentPage)
    return (
        <Container>
            <MenuDesktop active={currentPage} />
            <MenuMobile active={currentPage} />
        </Container>
    )
}

export default React.memo(Menu)