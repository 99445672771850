import React from 'react'
import CohortSelector from './CohortSelector'
import PageContainerHeader from '../PageContainerHeader'

const Header: React.FunctionComponent = () => {
    return (
        <PageContainerHeader>
            <CohortSelector />
        </PageContainerHeader>
    ) 
}

export default React.memo(Header)