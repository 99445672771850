import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import { windowDimensionsReducer } from "./window-dimensions"
import { dataFiltersReducer } from "./data-filters"
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import { gsheetDataReducer } from "./gsheet-data"
import { pageTransitionsReducer } from "./page-transitions"

const rootReducer = combineReducers({
    windowDimensions: windowDimensionsReducer,
    dataFilters: dataFiltersReducer,
    gsheetData: gsheetDataReducer,
    pageTransitions: pageTransitionsReducer,
})

const sagaMiddleware = createSagaMiddleware()

export type RootState = ReturnType<typeof rootReducer>

const enhancers = [
    applyMiddleware(sagaMiddleware)
]
if (process.env.NODE_ENV === "development" && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    )
}

const preloadedState = {}

export const store = createStore(rootReducer, preloadedState, compose(...enhancers))

sagaMiddleware.run(rootSaga)