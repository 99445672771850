const [STATIC_GSHEET_ID, WAVES_GSHEET_ID] = process.env.REACT_APP_GSHEET_IDS!.split(',')
const environment = process.env.REACT_APP_STAGING
export default {
    GOOGLE_API_KEY: 'AIzaSyBE4yqqCLl3ECZJt7w4Q0imDIpYQbahNkw',
    STATIC_GSHEET_ID, WAVES_GSHEET_ID,
    ROOT_URL: '',
    SITE_URL: 'https://barometre.unedic.wedodata.dev',
    TWEET_TEXT: 'Retrouvez l’intégralité des résultats de la 2ème édition du Baromètre @Unédic https://www.unedic.org/publications/barometre-unedic-quel-regard-les-francais-portent-ils-sur-le-chomage-et-les-chomeurs #RegardsSurleChômage',
    VIDEO_ASPECT_RATIO: 16/9,
    ENVIRONMENT: environment,
}