export const wrapText = (text: string, numLines: number) => {
    const charsPerLine = text.length / numLines
    const words = text.split(' ')
    const lines: Array<string> = []
    while (words.length) {
        let line: string = ''
        while (line.length < charsPerLine) {
            const word = words.shift()
            if (!word) {
                break
            }
            line += ' ' + word
        }
        lines.push(line)
    }
    return lines
}

export const numberDisplay = (value: number) => {
    return `${value}`.replace('.', ',')
}