import React from 'react'
import styled from 'styled-components/macro'
import {ReactComponent as FbSvg} from '../../images/share/share-fb.svg'
import {ReactComponent as LknSvg} from '../../images/share/share-lkn.svg'
import {ReactComponent as TwtSvg} from '../../images/share/share-twt.svg'
import theme from '../../theme'
import { CSSTransition } from 'react-transition-group'
import { buildFacebookShareUrl, buildLinkedInShareUrl, buildTwitterShareUrl } from '../../utils/social-networks'
import { buildShareUrl } from '../../config-pages'
import config from '../../config'

const OVERLAY_OPACITY = 0.5

const ModalOverlay = styled.div`
    background-color: ${theme.colors.Purple150};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${theme.zIndexes.modalOverlay};
    opacity: ${OVERLAY_OPACITY};
    cursor: pointer;
`

const Modal = styled.div`
    min-width: 20em;
    background-color: ${theme.colors.White};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10em;
    z-index: ${theme.zIndexes.modal};
    padding: 2em 3em;
    border-radius: ${theme.dimensions.borderRadius1};
`

const Label = styled.div`
    text-align: center;
    color: ${theme.colors.Black};
    padding-bottom: ${theme.spacings.horizontal1};
    font-weight: bold;
`
const Icons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
    onClose: () => void
    shareId: string
}

const ShareModal: React.FunctionComponent<Props> = ({ 
    className = '',
    onClose,
    shareId
}) => {
    const shareUrl = buildShareUrl(shareId)
    return (
        <CSSTransition
            in={true}
            appear={true}
            classNames="ShareModal"
            timeout={theme.transitions.pageTransitionDuration}
        >
            <div className={className}>
                <ModalOverlay onClick={onClose} />
                <Modal>
                    <Label>Partager sur</Label>
                    <Icons>
                        <a 
                            rel='noopener noreferrer'
                            aria-label='Partager sur Facebook'
                            target="_blank" href={buildFacebookShareUrl(shareUrl)}
                        ><FbSvg /></a>
                        <a 
                            rel='noopener noreferrer'
                            aria-label='Partager sur LinkedIn'
                            target="_blank" href={buildLinkedInShareUrl(shareUrl)}
                        ><LknSvg /></a>
                        <a 
                            rel='noopener noreferrer'
                            aria-label='Partager sur Twitter'
                            target="_blank" href={buildTwitterShareUrl(shareUrl, config.TWEET_TEXT)}
                        ><TwtSvg /></a>
                    </Icons>
                </Modal> 
            </div>
        </CSSTransition>
    )
}

export default styled(React.memo(ShareModal))``