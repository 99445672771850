import React from 'react'
import styled from 'styled-components/macro'
import { VisualizationComponentProps, VisualizationTitle } from '../shared'
import Graph, { CoverPath, GraphTitle, LabelGroup, HEIGHT_RATIO } from './Graph'
import Legend from './Legend'
import theme from '../../../theme'
import { CohortDisplay } from '../../../models'
import { CSSTransition } from 'react-transition-group'
import { selectWindowDimensions } from '../../../store/selectors'
import { useSelector } from 'react-redux'

const GRAPH_WIDTH_RATIO = 1 / 3
const GRAPH_MOBILE_WIDTH_RATIO = 0.5
const GRAPH_MAX_HEIGHT = 380

const Container = styled.div`
    
    ${VisualizationTitle} {
        ${theme.transitions.buildPageTransition(['opacity'], 0, 0.2)}
    }

    ${CoverPath} {
        transform: rotate(180deg);
        ${theme.transitions.buildPageTransition(['transform'], 0.2, 0.5)}
    }

    ${GraphTitle}, ${LabelGroup}, ${Legend} {
        ${theme.transitions.buildPageTransition(['opacity'], 0.7, 0.2)}
    }

    .choixUnique-appear {
        ${VisualizationTitle}, ${GraphTitle}, ${LabelGroup}, ${Legend} {
            opacity: 0;
        }

        ${CoverPath} {
            transform: rotate(0);
        }
    }

    .choixUnique-appear-active {
        ${VisualizationTitle}, ${GraphTitle}, ${LabelGroup}, ${Legend} {
            opacity: 1;
        }

        ${CoverPath} {
            transform: rotate(180deg);
        }
    }
`

const InnerContainer = styled.div<{hasOther: boolean}>`
    display: flex;
    flex-direction: row;
    ${props => props.hasOther ? `
        justify-content: space-between;
    `: `
        justify-content: space-around;
    `}
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        justify-content: space-between;
    }
    padding-top: ${theme.spacings.vertical05};
`

const VisualizationChoixUnique: React.FunctionComponent<VisualizationComponentProps> = ({ 
    question, 
    answerValues,
    width,
    cohort,
    cohortOther,
}) => {
    const {width: windowWidth} = useSelector(selectWindowDimensions)

    const answerValuesGroup1 = answerValues
        .filter(answerValue => answerValue.group === '1')
    const answerValuesGroup2 = answerValues
        .filter(answerValue => answerValue.group === '2')
    let graphWidth = width * (windowWidth > theme.dimensions.thresholdBigDesktop ? GRAPH_WIDTH_RATIO : GRAPH_MOBILE_WIDTH_RATIO)
    graphWidth = Math.min(graphWidth, GRAPH_MAX_HEIGHT / HEIGHT_RATIO)

    const group1Values = answerValuesGroup1
        .map(answerValue => ({
            value: answerValue.value,
            evolution: answerValue.evolution,
        }))
    const group2Values = answerValuesGroup2
        .map(answerValue => ({
            value: answerValue.value,
            evolution: answerValue.evolution,
        }))

    let otherGraph: JSX.Element | null = null
    if (cohortOther) {
        const group1ValuesOther = answerValuesGroup1
            .map(answerValue => ({
                value: answerValue["value-other"]!,
                evolution: answerValue["evolution-other"]
            }))
        const group2ValuesOther = answerValuesGroup2
            .map(answerValue => ({
                value: answerValue["value-other"]!,
                evolution: answerValue["evolution-other"]
            }))
        otherGraph = (
            <Graph
                width={graphWidth} 
                group1Values={group1ValuesOther}
                group2Values={group2ValuesOther}
                orientation='right'
                title={CohortDisplay[cohortOther]}
            />
        )
    }

    return (
        <Container>
            <CSSTransition
                in={true}
                appear={true}
                timeout={theme.transitions.pageTransitionDuration} 
                classNames="choixUnique"
            >
                <div>
                <VisualizationTitle>{question.text}</VisualizationTitle>
                <InnerContainer hasOther={!!cohortOther}>
                    <Graph 
                        width={graphWidth} 
                        group1Values={group1Values}
                        group2Values={group2Values}
                        orientation='left'
                        title={CohortDisplay[cohort]}
                    />
                    <Legend 
                        answerValuesGroup1={answerValuesGroup1}
                        answerValuesGroup2={answerValuesGroup2} 
                    />
                    {otherGraph}
                </InnerContainer>
                </div>
            </CSSTransition>
        </Container>
    )
}

export default React.memo(VisualizationChoixUnique)