import React, { useState, useEffect } from 'react'

interface Props {
    in: boolean
    delay: number
    hasInitialDelay?: boolean
    children: (in_: boolean) => React.ReactElement
}

const DelayIn: React.FunctionComponent<Props> = (props) => {
    const [currentIn, setCurrentIn] = useState<boolean | null>(null)
    
    useEffect(() => {
        if (props.in === true 
        && (currentIn === false || (currentIn === null && props.hasInitialDelay === true))) {
            const timeout = setTimeout(() => {
                setCurrentIn(true)
            }, props.delay)
            return () => clearTimeout(timeout)
        } else if (props.in !== currentIn) {
            setCurrentIn(props.in)
        }
    }, [props.in, props.hasInitialDelay, props.delay, currentIn])

    return props.children(currentIn || false)
}

export default DelayIn