import * as yup from 'yup'
import { ReactComponent as OneOutTwoSvgBlack } from "./images/chiffres-cles/featured-1out2-black.svg"
import { ReactComponent as SevenOutTenSvgBlack } from "./images/chiffres-cles/featured-7out10-black.svg"
import { ReactComponent as CareerSvgBlack } from "./images/chiffres-cles/featured-career-black.svg"
import { ReactComponent as EndureSvgBlack } from "./images/chiffres-cles/featured-endure-black.svg"
import { ReactComponent as ImportanceSvgBlack } from "./images/chiffres-cles/featured-importance-black.svg"
import { ReactComponent as LessMoreSvgBlack } from "./images/chiffres-cles/featured-less_more-black.svg"
import { ReactComponent as LoveFranceSvgBlack } from "./images/chiffres-cles/featured-love_france-black.svg"
import { ReactComponent as OutsourcingSvgBlack } from "./images/chiffres-cles/featured-outsourcing-black.svg"
import { ReactComponent as ResponsibleSvgBlack } from "./images/chiffres-cles/featured-responsible-black.svg"
import { ReactComponent as SitWaitSvgBlack } from "./images/chiffres-cles/featured-sit_wait-black.svg"
import { ReactComponent as WorseSvgBlack } from "./images/chiffres-cles/featured-worse-black.svg"

import { ReactComponent as OneOutTwoSvgWhite } from "./images/chiffres-cles/featured-1out2-white.svg"
import { ReactComponent as SevenOutTenSvgWhite } from "./images/chiffres-cles/featured-7out10-white.svg"
import { ReactComponent as CareerSvgWhite } from "./images/chiffres-cles/featured-career-white.svg"
import { ReactComponent as EndureSvgWhite } from "./images/chiffres-cles/featured-endure-white.svg"
import { ReactComponent as ImportanceSvgWhite } from "./images/chiffres-cles/featured-importance-white.svg"
import { ReactComponent as LessMoreSvgWhite } from "./images/chiffres-cles/featured-less_more-white.svg"
import { ReactComponent as LoveFranceSvgWhite } from "./images/chiffres-cles/featured-love_france-white.svg"
import { ReactComponent as OutsourcingSvgWhite } from "./images/chiffres-cles/featured-outsourcing-white.svg"
import { ReactComponent as ResponsibleSvgWhite } from "./images/chiffres-cles/featured-responsible-white.svg"
import { ReactComponent as SitWaitSvgWhite } from "./images/chiffres-cles/featured-sit_wait-white.svg"
import { ReactComponent as WorseSvgWhite } from "./images/chiffres-cles/featured-worse-white.svg"
import theme from './theme'

const enumValues = <T>(enumType: T) =>
    Object.keys(enumType).map(k => enumType[k as keyof typeof enumType])

export type KeyNumber = yup.InferType<typeof keyNumberSchema>

export enum Cohort {
    GRAND_PUBLIC = 'grand-public',
    CHOMEURS = 'chomeurs',
}

export const CohortDisplay = {
    [Cohort.GRAND_PUBLIC]: 'Ensemble des Français',
    [Cohort.CHOMEURS]: 'Demandeurs d\'emploi'
}

export enum RequestStatus {
    INIT,
    PROGRESS,
    DONE,
}

export interface RouterParams {
    questionId?: string
}

export const KeyNumbersPictos = {
    OneOutTwo: {
        [theme.colors.Black]: OneOutTwoSvgBlack,
        [theme.colors.White]: OneOutTwoSvgWhite,
    },
    SevenOutTen: {
        [theme.colors.Black]: SevenOutTenSvgBlack,
        [theme.colors.White]: SevenOutTenSvgWhite,
    },
    Career: {
        [theme.colors.Black]: CareerSvgBlack,
        [theme.colors.White]: CareerSvgWhite,
    },
    Endure: {
        [theme.colors.Black]: EndureSvgBlack,
        [theme.colors.White]: EndureSvgWhite,
    },
    Importance: {
        [theme.colors.Black]: ImportanceSvgBlack,
        [theme.colors.White]: ImportanceSvgWhite,
    },
    LessMore: {
        [theme.colors.Black]: LessMoreSvgBlack,
        [theme.colors.White]: LessMoreSvgWhite,
    },
    LoveFrance: {
        [theme.colors.Black]: LoveFranceSvgBlack,
        [theme.colors.White]: LoveFranceSvgWhite,
    },
    Outsourcing: {
        [theme.colors.Black]: OutsourcingSvgBlack,
        [theme.colors.White]: OutsourcingSvgWhite,
    },
    Responsible: {
        [theme.colors.Black]: ResponsibleSvgBlack,
        [theme.colors.White]: ResponsibleSvgWhite,
    },
    SitWait: {
        [theme.colors.Black]: SitWaitSvgBlack,
        [theme.colors.White]: SitWaitSvgWhite,
    },
    Worse: {
        [theme.colors.Black]: WorseSvgBlack,
        [theme.colors.White]: WorseSvgWhite,
    },
}

export enum Chapters {
    RAPPORT_TRAVAIL = 'Le rapport au travail',
    PERCEPTION_CHOMAGE = 'La perception du chômage',
    CONNAISSANCE_ASSURANCE = 'La connaissance de l\'Assurance chômage',
}

export enum QuestionType {
    CENT_PERSONNES = '100 personnes',
    CHOIX_UNIQUE = 'Choix unique',
    CHOIX_MULTIPLES = 'Choix multiples',
    ECHELLE_0_10 = 'Échelle 0 à 10',
    NUAGE_MOTS = 'Nuage de mots',
    REPONSES_MIRROIR = 'Réponses en miroir',
    CHOIX_BINAIRE = 'Choix binaire',
    ESTIMATION = 'Estimation',
}

export enum AnswerMirrorPositivity {
    POSITIVE = 'positif',
    NEGATIVE = 'négatif',
}

const stringUndefinedIfEmpty = (currentValue: any, originalValue: any) => 
    originalValue.length ? currentValue : undefined

// `.required()` needed https://github.com/jquense/yup/issues/946
export const questionSchema = yup.object({
    'id': yup.string().required(),
    'type': yup.string().required().oneOf(enumValues(QuestionType)),
    'chapter': yup.string().required().oneOf(enumValues(Chapters)),
    'text': yup.string().required(),
    'mirror-text': yup.string().notRequired(), // only for mirror question
}).required()

export type Question = yup.InferType<typeof questionSchema>

export const answerSchema = yup.object({
    'id': yup.string().required(),
    'question-id': yup.string().required(),
    'group': yup.string().notRequired(),
    'text': yup.string().required(),
    'mirror-text': yup.string().notRequired(), // only for mirror question
    'mirror-positivity': yup.string()
        .transform(stringUndefinedIfEmpty)
        .notRequired()
        .oneOf(enumValues(AnswerMirrorPositivity)), // only for mirror question
}).required()

export type Answer = yup.InferType<typeof answerSchema>

export const waveSchema = yup.object({
    'title': yup.string().required(),
    'date': yup.string().required(),
    'description': yup.string().required(),
    'methodologie': yup.string().required(),
}).required()

export type WaveGsheet = yup.InferType<typeof waveSchema>

// Cannot combine dynamically infered type with interface
export interface Wave {
    'title': string,
    'date': string,
    'description': string,
    'methodologie': string,
    'answer-values': Array<AnswerValue>
}

export const videoSchema = yup.object({
    'title': yup.string().required(),
    'description': yup.string().required(),
    'youtube-id': yup.string().required(),
    'show-on-home': yup.string()
        .transform(stringUndefinedIfEmpty)
        .notRequired()
}).required()

export type Video = yup.InferType<typeof videoSchema>

export const answerValueSchema = yup.object({
    'answer-id': yup.string().required(),
    'value-tous': yup.number().transform(stringUndefinedIfEmpty).notRequired(),
    'value-grand-public': yup.number().transform(stringUndefinedIfEmpty).notRequired(),
    'value-chomeurs': yup.number().transform(stringUndefinedIfEmpty).notRequired(),
    'nuage-mots': yup.string().notRequired(),
    'texte-cent-personnes': yup.string().notRequired(),
}).required()

export type AnswerValue = yup.InferType<typeof answerValueSchema>

export type CombinedAnswer = Answer & AnswerValue & { 
    'evolution-tous': number | null
    'evolution-grand-public': number | null
    'evolution-chomeurs': number | null
    'value': number
    'value-other': number | null
    'value-max': number
    'evolution': number | null
    'evolution-other': number | null
}

export const keyNumberSchema = yup.object({
    "cohort": yup.string()
        .transform(stringUndefinedIfEmpty)
        .required()
        .oneOf(enumValues(Cohort)),
    "title": yup.string().transform(stringUndefinedIfEmpty).required(),
    "body": yup.string().transform(stringUndefinedIfEmpty).required(),
    "link-question-id": yup.string().transform(stringUndefinedIfEmpty).notRequired(),
    "link-share-id": yup.string().transform(stringUndefinedIfEmpty).required(),
    "picto": yup.string()
        .transform(stringUndefinedIfEmpty)
        .required()
        .oneOf(Object.keys(KeyNumbersPictos)),
}).required()