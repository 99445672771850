import styled from 'styled-components/macro'
import theme from '../theme'

const {exitTransition, enterOrAppearTransition} = theme.transitions.buildPageTransitionMaker('&.PageContainerFooter')

export default styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: ${theme.dimensions.maxWidth}px;
    @media (min-width: ${theme.dimensions.maxWidth}px) {
        left: calc((100% - ${theme.dimensions.maxWidth}px) / 2);
    }

    @media (min-width: ${theme.dimensions.thresholdMobile}px) {
        ${enterOrAppearTransition(
            null,
            [['transform', 'translateY(100%)', 'translateY(0)']],
            0.7, 0.3
        )}

        ${exitTransition(
            null,
            [['transform', 'translateY(0)', 'translateY(100%)']],
            0, 0.3
        )}
    }
`