import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../../theme'
import { EvolutionHtml } from '../shared'
import {Colors} from './config-vizualisation'
import { AnswerMirrorPositivity } from '../../../models'

const BAR_SIZE_RATIO = 0.55
const EVOLUTION_SCALE = 0.9
const BAR_HEIGHT = '1em'
const BAR_HEIGHT_MOBILE = '1.3em'

export const Bar = styled.div<{ 
    positivity: AnswerMirrorPositivity, value: number, valueMax: number 
}>`
    display: inline-block;
    background-color: ${props => Colors[props.positivity]};
    border-radius: 0.3em;
    width: ${props => props.value * BAR_SIZE_RATIO * 100 / props.valueMax}%;
    height: ${BAR_HEIGHT};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        height: ${BAR_HEIGHT_MOBILE};
    }
`

export const Percentage = styled.span`
    font-family: ${theme.fonts.title};
    margin: 0 0.2em;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 125%;
    }
`

const InnerContainer = styled.div`
    width: 100%;
`

export const Text = styled.div`
    font-size: 85%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 110%;
    }
`

const BarContainer = styled.div<{alignment: 'left' | 'right'}>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.alignment === 'left' ? 'flex-end' : 'flex-start'};
    align-items: center;
`

export interface Props {
    className?: string
    alignment: 'left' | 'right'
    text: string
    positivity: AnswerMirrorPositivity
    value: number
    valueMax: number
    evolution: number | null
}

const Graph: React.FunctionComponent<Props> = ({ 
    className = '', evolution, value, valueMax, text, positivity, alignment
}) => {
    const content = [
        evolution !== null ? <EvolutionHtml key="evolution" value={evolution} scale={EVOLUTION_SCALE} /> : null,
        <Percentage key="percentage">{value}%</Percentage>,
        <Bar key="value" value={value} valueMax={valueMax} positivity={positivity} />
    ]
    if (alignment === "right") {
        content.reverse()
    }
    return (
        <div className={className}>
            <InnerContainer>
                <Text>{text}</Text>        
                <BarContainer alignment={alignment}>{content}</BarContainer>
            </InnerContainer>
        </div>
    )
}

export default styled(React.memo(Graph))`
    display: flex;
    flex-direction: row;
    ${props => props.alignment === 'left' ? `
        justify-content: flex-end;
        align-items: flex-end;
    `: `
        justify-content: flex-start;
        align-items: flex-start;
    `}
    margin-bottom: ${theme.spacings.vertical05};
`