import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import configPages, { RESULTATS_ID, METHODOLOGIE_ID, CHIFFRES_ID, ACCUEIL_ID } from '../config-pages'
import { Link } from 'react-router-dom'
import Video, { Container as VideoContainer } from './Video'
import logoUnedicSrc from '../images/homepage/logo-unedic.svg'
import logoWddSrc from '../images/homepage/logo-wdd.svg'
import theme from '../theme'
import ButtonMainLink, {Button as ButtonMainLinkContainer} from './ButtonMainLink'
import { selectRouteRendered, selectWindowDimensions, selectVideoHome } from '../store/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPage } from '../store/page-transitions'
import config from '../config'

const MIN_HEIGHT = 500
const THRESHOLD_SMALL_HEIGHT = 600
const VIDEO_MAX_WIDTH = 1000
const VIDEO_MAX_HEIGHT_PERCENTAGE = 0.625

interface TransitionProps {}

const Title = styled.h1<TransitionProps>`
    max-width: ${theme.dimensions.thresholdMobile}px;
    width: 90%;
    font-family: ${theme.fonts.title};
    text-align: center;
    color: ${theme.colors.White};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 170%;
    }
`

const ButtonSmall = styled(Link)`
    ${theme.mixins.buttonPurple}
    display: inline-block;
    font-size: 88%;
    transition-property: transform, background-color;
    transition-duration: ${theme.transitions.duration1};
    &:hover {
        transform: scale(1.05);
        background-color: ${theme.colors.White};
        color: ${theme.colors.Purple100};
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    max-width: 25em;
    min-width: 20em;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        width: 90%;
        max-width: 30em;
    }
    
    ${ButtonMainLinkContainer} {
        width: 100%;
    }

    >* {
        margin-bottom: ${theme.spacings.verticalRel05};
    }
`

const SmallButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${ButtonSmall} {
        width: 49%;
    }
`

const Footer = styled.div`
    position: relative;
    width: 100%;

    /* to allow clicking on buttons under the footer */
    pointer-events: none;

    img {
        position: absolute;

        :first-child {
            left: 0;
            bottom: 0;
        }

        :last-child {
            margin-right: ${theme.spacings.horizontal2};
            bottom: 1.7em;
            right: 0;
        }
    }

    @media (max-width: 42em) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        img {
            position: initial;
        }
    }
`

const VideoTitle = styled.div<{width: number}>`
    color: ${theme.colors.White};
    font-size: 110%;
    font-weight: bold;
    text-align: left;
    width: ${props => props.width}px;
`

const StyledButtonMainLink = styled(ButtonMainLink)`
    animation: bounce 3s infinite;
    &:hover {
        animation: none;
    }
    @keyframes bounce { 
        0% { transform: scale(1); }
        10% { transform: scale(0.95); } 
        20% { transform: scale(1.05); } 
        30% { transform: scale(1); } 
        100% { transform: scale(1); } 
    }
`

const {enterTransition, exitTransition, enterOrAppearTransition} = theme.transitions.buildPageTransitionMaker('&.PageAccueil')

const Container = styled.div<{
    isFirstPage: boolean
}>`
    position: absolute;
    width: 100%;
    max-width: ${theme.dimensions.maxWidth}px;

    @media (min-width: ${theme.dimensions.maxWidth}px) {
        margin: 0 calc((100% 
            - ${theme.dimensions.maxWidth}px) / 2);
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    background-color: ${theme.colors.Purple150};
    border-radius: 1em;
    overflow: auto;
    &.PageAccueil-enter, &.PageAccueil-enter {
        overflow: hidden;
    }

    ${Title}, ${VideoContainer} {
        margin-top: ${theme.spacings.vertical05};
    }

    ${VideoTitle} {
        margin-bottom: ${theme.spacings.vertical05};
    }

    @media (orientation: portrait) {
        ${Title}, ${VideoContainer} {
            margin-top: ${theme.spacings.vertical1};
        }

        ${VideoTitle} {
            margin-bottom: ${theme.spacings.vertical1};
        }
    }

    @media (max-height: ${THRESHOLD_SMALL_HEIGHT}px) {
        font-size: 12px;
    }

    height: 100%;
    @media (min-width: ${theme.dimensions.thresholdMobile}px) {

        ${props => props.isFirstPage ? 
            enterTransition(
                null,
                [
                    ['height', '5em', '100%'],
                    ['min-height', '5em', '100%'],
                    ['top', '50%', '50%'],
                    ['transform', 'translateY(-50%)', 'translateY(-50%)'],
                ],
                0, 0.5
            ) : 
            enterTransition(
                null, 
                [['transform', `translateY(calc(-100% + ${theme.dimensions.menuHeight}))`, 'translateY(0)']],
                0, 0.5
            )
        }

        ${exitTransition(
            null,
            [['transform', 'translateY(0)', `translateY(calc(-100% + ${theme.dimensions.menuHeight}))`]],
            0, 0.5
        )}

        ${exitTransition(
            ['>*'],
            [['opacity', '1', '0']],
            0, 0.5
        )}

        ${enterOrAppearTransition(
            [`${Title}`],
            [['transform', 'translateY(-2000%)', 'translateY(0)']],
            0.5, 0.5
        )}

        ${enterOrAppearTransition(
            [`${VideoContainer}`, `${VideoTitle}`],
            [['opacity', '0', '1']],
            0.5, 0.1
        )}

        ${enterOrAppearTransition(
            [`${ButtonMainLinkContainer}`],
            [['opacity', '0', '1']],
            0.6, 0.1
        )}

        ${enterOrAppearTransition(
            [`${ButtonSmall}`],
            [['opacity', '0', '1']],
            0.7, 0.1
        )}

        ${enterOrAppearTransition(
            [`${Footer}`],
            [['opacity', '0', '1']],
            0.8, 0.1
        )}
    }
`

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`

interface Props {}

const PageAccueil: React.FunctionComponent<Props> = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(ACCUEIL_ID))
    }, [dispatch])

    const videoHome = useSelector(selectVideoHome)

    // This page will be loaded as first page only when there has been
    // no other route rendered before. 
    const routeRendered = useSelector(selectRouteRendered)
    // We memoize the value we first had when loading the component
    const [isFirstPage] = useState(!routeRendered)

    let {width, height} = useSelector(selectWindowDimensions)
    height = Math.max(height, MIN_HEIGHT)
    const videoMaxHeight = height * VIDEO_MAX_HEIGHT_PERCENTAGE
    let videoWidth = Math.min(width, VIDEO_MAX_WIDTH)
    let videoHeight = videoWidth / config.VIDEO_ASPECT_RATIO
    if (videoHeight > videoMaxHeight) {
        videoWidth = videoMaxHeight * config.VIDEO_ASPECT_RATIO
    }

    return (
        <Container isFirstPage={isFirstPage}>
            <InnerContainer>
                <Title>
                    Quel regard les Français portent-ils{width > 500 ? <br/> : ' '}
                    sur le chômage et les chômeurs ?
                </Title>
                <Video width={videoWidth} youtubeId={videoHome["youtube-id"]} />
                <VideoTitle width={videoWidth}>{videoHome.title}</VideoTitle>
                <ButtonContainer>
                    <StyledButtonMainLink pageId={CHIFFRES_ID} />
                    <SmallButtonContainer>
                        <ButtonSmall to={configPages[RESULTATS_ID].href()}>{configPages[RESULTATS_ID].title}</ButtonSmall>
                        <ButtonSmall to={configPages[METHODOLOGIE_ID].href()}>{configPages[METHODOLOGIE_ID].title}</ButtonSmall>
                    </SmallButtonContainer>
                </ButtonContainer>
            </InnerContainer>
            <Footer>
                <img src={logoUnedicSrc} alt="logo Unédic" className="unedicLogo" />
                <img src={logoWddSrc} alt="logo WeDoData" className="wddLogo" />
            </Footer>
        </Container>
    )
}

export default PageAccueil