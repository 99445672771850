import React, { useEffect, useState } from "react"
import WebFont from 'webfontloader'
import { useDispatch } from "react-redux"
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { refreshWindowDimensions } from "../store/window-dimensions"
import styled from "styled-components/macro"
import theme from "../theme"
import { gsheetDataLoad } from "../store/gsheet-data"
import LoadingData from "./LoadingData"
import AppRoutes from "./AppRoutes"

const history = createBrowserHistory()

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    color: ${theme.colors.Black};
    font-size: ${theme.fontSizes.baseDesktop}px;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) and (min-width: ${theme.dimensions.thresholdMobileVerySmall}px) {
        font-size: ${theme.fontSizes.baseMobile}px;
    }
    @media (max-width: ${theme.dimensions.thresholdMobileVerySmall}px) {
        font-size: ${theme.fontSizes.baseSmallMobile}px;
    }
    font-family: ${theme.fonts.normal};
`

const App = () => {
    const dispatch = useDispatch()
    const [fontsLoaded, setFontsLoaded] = useState(false)

    useEffect(() => {
        const dispatchRefreshWindowDimensions = () =>
            dispatch(refreshWindowDimensions())
        window.addEventListener("resize", dispatchRefreshWindowDimensions)
        return () => {
            window.removeEventListener(
                "resize",
                dispatchRefreshWindowDimensions
            )
        }
    }, [dispatch])

    WebFont.load({
        active: () => setFontsLoaded(true),
        google: {
            families: ['Arvo:700', 'Dosis:700', 'Roboto']
        },
        timeout: 100,
        classes: false,
    })

    useEffect(() => {
        dispatch(gsheetDataLoad())
    }, [dispatch])

    return (
        <LoadingData fontsLoaded={fontsLoaded}>
            <Container>
                <Router history={history}>
                    <AppRoutes />
                </Router>
            </Container>
        </LoadingData>
    )
}

export default App
