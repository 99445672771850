import React from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
import enterSrc from '../images/homepage/button-enter.svg'
import theme from '../theme'
import configPages, { PageId } from '../config-pages'

export const Button = styled(Link)`
    ${theme.mixins.buttonWhite}
    font-weight: bold;
    img {
        height: 1em;
        vertical-align: middle;
        margin-left: 0.25em;
    }
    transition: transform ${theme.transitions.duration1};
    &:hover {
        transform: scale(1.05);
    }
`

interface Props {
    pageId: PageId
    className?: string
}

const ButtonMainLink: React.FunctionComponent<Props> = ({ pageId, className }) => {
    return (
        <Button 
            className={className}
            to={configPages[pageId].href()}
        >
            {configPages[pageId].title}  <img src={enterSrc} alt="" />
        </Button>
    )
}

export default styled(ButtonMainLink)``